import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app';
import { getMessaging, getToken, Messaging, onMessage } from "firebase/messaging";

export default class FcmService {

    private static _fcmConfig: FirebaseOptions = {
        apiKey: "AIzaSyCYhTMxsjgebhfbZbqEOAnMc-FC88wpm_U",
        authDomain: "meine-musik.firebaseapp.com",
        projectId: "meine-musik",
        storageBucket: "meine-musik.appspot.com",
        messagingSenderId: "385682572196",
        appId: "1:385682572196:web:a46c36fbf7fa279fb6b731",
        measurementId: "G-40XX90LZN9"
    };

    private static _fcmVapidKey: string = "BLF0M2QB6fM1VJRsmZBo4KiOsgAvhQchpXHRJuCXgGFuAULLgB-o70ZBFaZM4bk4sKJesWzlQQ7mwwv9h1s96XE";
    
    private static _instance: FcmService;
    private _fcmApp: FirebaseApp;
    private _fcmMessaging: Messaging;

    public static getInstance = (): FcmService | undefined => {
        if (('serviceWorker' in navigator) && ('Notification' in window)) {
            if (!FcmService._instance) {
                try {
                    FcmService._instance = new FcmService();
                } catch(e) {
                    console.log("Firebase-Initialisierung fehlgeschlagen", e);
                }
            }
            return FcmService._instance;
        } else {
            console.log("serviceWorker not supporterd");
        }
    }

    private constructor() {
        this._fcmApp = initializeApp(FcmService._fcmConfig);
        this._fcmMessaging = getMessaging(this._fcmApp);

        navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {

            onMessage(this.getFcmMessaging(), (payload: any) => {
                console.log("Benachrichtigung erhalten. ", payload);
                
                const {title, ...options} = payload.notification;
                
                return registration.showNotification(title, options);
            });
    
        }).catch(error => {
            console.log("Fehler bei ServiceWorker-Abfrage", error);
        });
    }

    public getFcmApp = (): FirebaseApp => {
        return this._fcmApp;
    }

    public getFcmMessaging = (): Messaging => {
        return this._fcmMessaging;
    }

    public getPermission = (): NotificationPermission => {
        return Notification.permission;
    }

    public requestPermission = async(): Promise<NotificationPermission> => {
        return Notification.requestPermission();
    }
    
    public getToken = async (): Promise<string> => {
        let registration: ServiceWorkerRegistration = await navigator.serviceWorker.ready;
        return await getToken(this.getFcmMessaging(), { vapidKey: FcmService._fcmVapidKey, serviceWorkerRegistration: registration });
    }

}