import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce, isUndefined } from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/icons-material/Menu';
import Edit from '@mui/icons-material/Edit';
import Person from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import TextDivider from '../../components/textDivider';
import Dropzone, { FileInput } from '../../components/dropzone';
import { userInfo } from 'os';
import FcmService from '../../components/fcmService';

interface Props {
  children?: React.ReactNode,
  open: boolean,
  onClose: (success: boolean) => void,
}

interface State {
  notifyMail: boolean,
  notifyPush: boolean,
  notifyPushDisabled: boolean,
  showNotifyDisabledDialog: boolean,
  fcmToken: string,
  hasValidMail: boolean,
}


class UserNotifyChange extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state: State = { 
    notifyMail: false,
    notifyPush: false,
    notifyPushDisabled: false,
    showNotifyDisabledDialog: false,
    fcmToken: "",
    hasValidMail: false,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps: WithHocs & Props): void {
      if (prevProps.open !== this.props.open) {
        this.setState({notifyMail: false, notifyPush: false, hasValidMail: false}, () => {
          if (this.props.open) {
            this.loadConfig();
          }
        });
      }
  }

  loadConfig = async () => {

    let fcmSvc = FcmService.getInstance();

    if (fcmSvc) {

      if (fcmSvc.getPermission() === 'granted') {

        fcmSvc.getToken().then(token => {

          console.log("fcm-token", token);
          this.loadNotifyConfig(token);

        }).catch(error => {
          console.log(error);
          this.loadNotifyConfig();
        });

      } else {
        this.loadNotifyConfig();
      }

    }

  }

  loadNotifyConfig = (token?: string) => {

      this.setState({fcmToken: (token || "")});

      this.context.setLoading(true);

      let notifyConfigData: any = { fcm_token: token };

      this.props.rest.post("settings/getNotifyConfig", notifyConfigData).then(data => {

        this.context.setLoading(false);

        if (data && data.status && data.status == "ok") {

          this.setState({
            notifyMail: ((data.user_union && data.user_union.notify_mail) ? true : false),
            notifyPush: ((data.user_union && data.user_union.fcm_tokens && (data.user_union.fcm_tokens.length > 0)) ? true : false),
            hasValidMail: ((data.user_union && data.user_union.user && data.user_union.user.email && data.user_union.user.email_verified_at) ? true : false)
          });
          
        } else {
          this.props.enqueueSnackbar("Benachrichtigungseinstellungen konnten nicht ausgelesen werden", { variant: 'error' });
        }

      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
        this.props.enqueueSnackbar("Benachrichtigungseinstellungen konnten nicht ausgelesen werden", { variant: 'error' });
      });

  }

  onChangePushClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: boolean = e.target.checked;
    if (!newValue) {
      this.setState({notifyPush: newValue});
    } else {
      this.setState({notifyPushDisabled: true});

      let fcmSvc = FcmService.getInstance();
      if (fcmSvc) {
        fcmSvc.requestPermission().then((decision: NotificationPermission) => {

          if (decision == 'granted') {

            fcmSvc?.getToken().then((token: string) => {
              console.log("fcm-token", token);

              if (token) {
                this.setState({notifyPush: true, fcmToken: token, notifyPushDisabled: false});
              } else {
                this.setState({notifyPushDisabled: false, notifyPush: false});
              }

            }).catch(error => {
              console.log(error);
              this.setState({notifyPushDisabled: false, notifyPush: false});
            });

          } else {
            this.setState({notifyPushDisabled: false, notifyPush: false, showNotifyDisabledDialog: true});
          }

        }).catch(error => {
          console.log(error);
          this.setState({notifyPushDisabled: false, notifyPush: false, showNotifyDisabledDialog: true});
        })
        
      } else {
        this.setState({notifyPushDisabled: false, notifyPush: false});
        this.props.enqueueSnackbar("Push-Benachrichtigungen werden auf diesem Gerät nicht unterstützt", { variant: 'error' });
      }
    }
  }

  onChangeMailClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { hasValidMail } = this.state;

    const newValue: boolean = e.target.checked;
    if (!newValue) {
      this.setState({notifyMail: newValue});
    } else {

      if (!hasValidMail) {

        this.props.enqueueSnackbar("Sie müssen zuvor eine gültige E-Mail-Adresse hinterlegen und diese bestätigen", { variant: 'error' });

      } else {
        this.setState({notifyMail: true});
      }

    }
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { notifyMail, notifyPush, fcmToken } = this.state;

    this.context.setLoading(true);

    let notifyData: any = { notify_mail: notifyMail, notify_push: notifyPush, fcm_token: fcmToken };

    this.props.rest.post("settings/notifyChange", notifyData).then(data => {

      this.context.setLoading(false);

      if (data && data.status && data.status == "ok") {
        this.props.enqueueSnackbar("Benachrichtigungseinstellungen wurden erfolgreich aktualisiert", { variant: 'success' });

        //update localStorage to update token on server on every new login --> menuWrapper
        let userUnionLocalStorageConfig = this.context.userUnionLocalStorageConfig || {};
        userUnionLocalStorageConfig.fcm_decision = notifyPush;
        this.context.setUserUnionLocalStorageConfig(userUnionLocalStorageConfig);

        this.props.onClose(true);
      } else {
        this.props.enqueueSnackbar("Benachrichtigungseinstellungen konnten nicht aktualisiert werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Benachrichtigungseinstellungen konnten nicht aktualisiert werden", { variant: 'error' });
    });

  }

  render() {
    const { open } = this.props;
    const { notifyMail, notifyPush, notifyPushDisabled, showNotifyDisabledDialog } = this.state;

    return (
      <React.Fragment>
        <Dialog
            open={open}
            onClose={() => { this.props.onClose(false); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='xs'
        >
      
            <DialogTitle>Benachrichtigung</DialogTitle>
            <form onSubmit={this.onSubmit}>
                <DialogContent>

                    <Typography variant="body1" gutterBottom>Du kannst dich hier entscheiden über welche Wege du über bevorstehende Termine und über Umfragen benachrichtigt werden möchtest.</Typography>
                    <Typography variant="body2" gutterBottom  sx={{ mb: 2 }}><strong>Achtung: </strong>Push-Benachrichtigungen erfodern einen aktuellen Browser und werden aktuell noch nicht unter iOS unterstützt.</Typography>
                
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch color="primary" checked={notifyMail} onChange={this.onChangeMailClicked} />}
                                label="E-Mail-Benachrichtigungen"
                                labelPlacement='start'
                                sx={{ ml: { md: 7 } }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch color="primary" checked={notifyPush} onChange={this.onChangePushClicked} />}
                                label="Push-Benachrichtigungen"
                                labelPlacement='start'
                                sx={{ ml: { md: 7 } }}
                                disabled={notifyPushDisabled}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.props.onClose(false); }}>Abbrechen</Button>
                    <Button type="submit">Ändern</Button>
                </DialogActions>

            </form>

        </Dialog>

        <Dialog
            open={showNotifyDisabledDialog}
            onClose={() => { this.setState({showNotifyDisabledDialog: false}); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='xs'
        >
      
          <DialogTitle>Berechtigung nicht erteilt</DialogTitle>
            <DialogContent>
            
              <DialogContentText>
                Die Berechtigung zum Zustellen von Benachrichtigungen wurde nicht erteilt, bitte aktivieren Sie diese selbständig in Ihrem Browser.
              </DialogContentText>

          </DialogContent>
          <DialogActions>
              <Button onClick={() => { this.setState({showNotifyDisabledDialog: false}); }}>Schließen</Button>
          </DialogActions>

        </Dialog>

      </React.Fragment>
    );
  }

}

export default withHocs(UserNotifyChange);