import React from 'react';

import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { deDE } from '@mui/material/locale';

import { AppContext } from './context';
import Util from './components/util';

interface Props {
    children?: React.ReactNode,
}

interface State {
}

class Theme extends React.Component<Props, State> {

  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state: State = {
  }

  constructor(props: any) {
    super(props);
  }

  render() {
    const { themeMode } = this.context;

    let darkMode: boolean = Util.isDarkMode(themeMode);

    const theme = createTheme(darkMode ? {
        palette: {
            mode: 'dark',
            primary: {
                main: '#bf360c',
            },
            secondary: {
                main: '#3f51b5',
            },
        },
    } : {
        palette: {
            mode: 'light',
            primary: {
                main: '#bf360c',
            },
            secondary: {
                main: '#3f51b5',
            },
            background: {
                paper: '#fcfcfc',
            },
        },
    },
    deDE,
    );

    return (
        <ThemeProvider theme={ theme }>
            { this.props.children }
        </ThemeProvider>
    );
    
  }

}

export default Theme;