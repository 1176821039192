import React from 'react';
import moment from 'moment-timezone';
import { debounce } from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Close from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';

import { AppContext } from '../../context';
import Role from '../../components/role';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import CalendarEventEditDialog from './calendarEventEditDialog';
import CalendarEventDelDialog from './calendarEventDelDialog';
import TabPanel from '../../components/tabPanel';


interface Props {
    id?: string,
    showOtherEvents?: boolean,
}

interface State {
    events: any[],
    eventId: string,
    comment: string,

    data: any,
    presenceTypes: any,
    parentInstruments: any,
    newPrePresences: any[],
    newPostPresences: any[],

    fabOpen: boolean,
    addOpen: boolean,
    addId: any,
    delOpen: boolean,
    delId: any,
    selectedTab: number,
}


class CalendarEvent extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        events: [] as any[],
        eventId: "",
        comment: "",

        data: undefined as any,
        presenceTypes: undefined as any,
        parentInstruments: undefined as any,
        newPrePresences: [] as any[],
        newPostPresences: [] as any[],

        fabOpen: false,
        addOpen: false,
        addId: undefined,
        delOpen: false,
        delId: undefined,
        selectedTab: 0,
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.reloadSite();
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
        if (this.props && this.props.id && ((prevProps && (this.props.id !== prevProps.id)) || !(prevProps && prevProps.id))) {
            this.reloadSite();
        }
    }

    reloadSite = () => {
        if (this.props.showOtherEvents) {
            if (this.props.id) {
                this.setState({eventId: this.props.id}, () => {
                    this.loadEvents();
                });
            } else {
                this.loadEvents();
            }
        } else if (this.props.id) {
            this.setState({eventId: this.props.id}, () => {
                this.searchEvent();
            });
        }
    }

    loadEvents = (e?: React.FormEvent) => {
        e?.preventDefault();

        const { eventId } = this.state;

        let from: moment.Moment = moment(moment.now()).clone().startOf('day');

        if (Role.isEventEditor())
        {
            from = from.subtract(14, 'days').startOf('day');
        } else {
            from = from.subtract(3, 'days').startOf('day');
        }

        this.setState({events: [], /*eventId: "",*/ comment: ""});

        this.context.setLoading(true);
        let eventData: any = { from: Util.dateToIsoString(from) };

        this.props.rest.post("calendar/searchPresences", eventData).then(data => {

          this.context.setLoading(false);

          if (data && data.events && data.status && data.status == "ok") {

            let nextEvtId: string = eventId;
            if (!nextEvtId) {
                let nextEvts: any[] = (data.events && data.events.length > 0) && data.events.filter((ne: any) => ne.begin_date && Util.isoStringToDate(ne.begin_date)?.isAfter());
                let nextEvt = ((nextEvts && nextEvts.length > 0) ? nextEvts[0] : ((data.events && data.events.length > 0) ? data.events[data.events.length - 1] : undefined));
                nextEvtId = nextEvt?.id;
            }

            this.setState({events: data.events, eventId: nextEvtId}, () => {
                this.searchEvent();
            });
            console.log(data);

          } else {
            this.props.enqueueSnackbar("Termine konnten nicht geladen werden", { variant: 'error' });
          }

        }).catch(err => {
          this.context.setLoading(false);
          console.log(err);
          this.props.enqueueSnackbar("Termine konnten nicht geladen werden", { variant: 'error' });
        });
    }

    searchEvent = () => {
        const { eventId } = this.state;
        this.setState({data: undefined, presenceTypes: undefined, parentInstruments: undefined, newPrePresences: [], newPostPresences: [], comment: "", selectedTab: 0});

        if (eventId) {
    
            this.context.setLoading(true);

            let eventData: any = { id: eventId };
        
            this.props.rest.post("calendar/get", eventData).then(data => {
        
                this.context.setLoading(false);
            
                if (data && data.event && data.status && data.status == "ok") {

                    let eventPresences: any = (data.event && data.event.event_presences && (data.event.event_presences.length > 0) && data.event.event_presences.filter((ep: any) => ep.dom_presence_type.pre));
                    let ep: any = (eventPresences && eventPresences.length > 0 && eventPresences[0]);
            
                    this.setState({presenceTypes: data.presence_types, data: data.event, parentInstruments: data.dom_instrument_parents, selectedTab: ((Role.isEventEditor() && (data.event && Util.isoStringToDate(data.event.begin_date)?.isBefore())) ? 1 : 0), comment: ((ep && ep.comment) || "")});
                    console.log(data);
            
                } else {
                    this.props.enqueueSnackbar("Termin konnten nicht gefunden werden", { variant: 'error' });
                }
        
            }).catch(err => {
                this.context.setLoading(false);
                console.log(err);
                this.props.enqueueSnackbar("Termine konnten nicht gefunden werden", { variant: 'error' });
            });

        }
    }

    onPresenceTypeClicked = (presenceTypeId: number) => {
        const { eventId, comment } = this.state;

        if (eventId) {
            this.context.setLoading(true);

            let presData: any = { event_id: eventId, dom_presence_type_id: presenceTypeId, comment: comment };
    
            this.props.rest.post('calendar/presence', presData).then(data => {
        
                this.context.setLoading(false);
        
                if (data && data.status && (data.status == 'ok')) {
                    this.props.enqueueSnackbar('Anwesenheit erfolgreich geändert', { variant: 'success' });
                    this.searchEvent();
                } else {
                    this.props.enqueueSnackbar('Anwesenheit konnte nicht geändert werden', { variant: 'error' });
                }
        
            }).catch(err => {
                this.context.setLoading(false);
                this.props.enqueueSnackbar('Anwesenheit konnte nicht geändert werden', { variant: 'error' });
                console.log(err);
            });
        }
    }

    onPresenceTypeChanged = (presenceTypeId: number, userId: number, pre: boolean, post: boolean) => {
        let newPresences = (pre ? this.state.newPrePresences : (post ? this.state.newPostPresences : []));

        newPresences = newPresences.filter((value: any, i: number) => value.user_id != userId);

        newPresences.push({user_id: userId, dom_presence_type_id: presenceTypeId});

        if (pre) {
            this.setState({newPrePresences: newPresences});
        } else if (post) {
            this.setState({newPostPresences: newPresences});
        }
    }

    onPresenceTypeSubmit = (e: React.FormEvent, pre: boolean, post: boolean) => {
        e.preventDefault();

        const { eventId, data } = this.state;

        let newPresences: any[] = (pre ? this.state.newPrePresences : (post ? this.state.newPostPresences : []));

        this.context.setLoading(true);

        let presData: any = {event_id: data.id, pre: pre, post: post, newPresences: newPresences};

        this.props.rest.post('calendar/allPresences', presData).then(data => {
    
            this.context.setLoading(false);
    
            if (data && data.status && (data.status == 'ok')) {
                this.props.enqueueSnackbar('Anwesenheiten erfolgreich geändert', { variant: 'success' });
                this.searchEvent();
            } else {
                this.props.enqueueSnackbar('Anwesenheiten konnten nicht geändert werden', { variant: 'error' });
            }
    
        }).catch(err => {
            this.context.setLoading(false);
            this.props.enqueueSnackbar('Anwesenheiten konnten nicht geändert werden', { variant: 'error' });
            console.log(err);
        });
    }


    render() {
        const { t } = this.props;
        const { eventId, data, presenceTypes, parentInstruments, fabOpen, addOpen, addId, delOpen, delId, selectedTab, newPrePresences, events, comment } = this.state;
        
        return (
            <React.Fragment>

                {!data && this.props.showOtherEvents && !this.context.loading && (
                    <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, textAlign: 'center' }}>
                        Keine Termine vorhanden
                    </Typography>
                )}

                {data && (
                    <Grid container spacing={2} sx={{ pb: 2 }}>

                        {!this.props.showOtherEvents ? (
                            <React.Fragment>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Typography variant="h5" color="textSecondary">Titel:</Typography>
                                </Grid>

                                <Grid item md={9} sm={6} xs={12}>
                                    <Typography variant="h4" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{data && data.title}</Typography>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid item sm={3} xs={12}>
                                    <Typography variant="h5" color="textSecondary">Termin:</Typography>
                                </Grid>

                                <Grid item sm={9} xs={12}>
                                    <Box sx={{ ml: { xs: 5, sm: 0 } }}>
                                        <FormControl fullWidth>
                                            <InputLabel>Termin</InputLabel>
                                            <Select value={eventId} onChange={e => { 
                                                this.setState({eventId: e.target.value}, () => {
                                                    this.searchEvent();
                                                }); 
                                            }} >
                                                {events && events.map((ev: any, i: number) => {
                                                    return (<MenuItem value={ev.id} key={ev.id}>{ev.title}</MenuItem>);
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </React.Fragment>
                        )}
                        
                        {data && data.begin_date && (
                            <React.Fragment>
                                <Grid item md={2} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary">Beginn:</Typography>
                                </Grid>

                                <Grid item md={4} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(data.begin_date, true)}</Typography>
                                </Grid>
                            </React.Fragment>
                        )}

                        {(data && data.end_date) && (
                            <React.Fragment>
                                <Grid item md={2} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary">Ende:</Typography>
                                </Grid>

                                <Grid item md={4} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(data.end_date, true)}</Typography>
                                </Grid>
                            </React.Fragment>
                        )}

                        {data && data.location && (
                            <React.Fragment>
                                <Grid item md={2} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary">Ort:</Typography>
                                </Grid>

                                <Grid item md={4} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{data && data.location}</Typography>
                                </Grid>
                            </React.Fragment>
                        )}

                        {data && data.dom_event_type && data.dom_event_type.name && (
                            <React.Fragment>
                                <Grid item md={2} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary">Kategorie:</Typography>
                                </Grid>

                                <Grid item md={4} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{data && data.dom_event_type && data.dom_event_type.name && t('event_type.' + data.dom_event_type.name)}</Typography>
                                </Grid>
                            </React.Fragment>
                        )}

                        {data && data.description && (
                            <React.Fragment>
                                <Grid item md={2} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary">Beschreibung:</Typography>
                                </Grid>

                                <Grid item md={4} sm={6} xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{data && data.description}</Typography>
                                </Grid>
                            </React.Fragment>
                        )}

                    </Grid>
                )}

                {data && data.begin_date && presenceTypes && (presenceTypes.length > 0) && (
                    <Grid container spacing={2} sx={{ mb: 3 }}>

                        <Grid item sm={3} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">Notiz:</Typography>
                        </Grid>

                        <Grid item sm={9} xs={12}>
                            <Box sx={{ ml: { xs: 5, sm: 0 } }}>
                                <TextField
                                    fullWidth
                                    label="Notiz"
                                    value={comment}
                                    onChange={event => { this.setState({comment: event.target.value}) } }
                                    inputProps={{ maxLength: 2500 }}
                                    multiline
                                    disabled={Util.isoStringToDate(data.begin_date)?.isBefore() ? true : false}
                                />
                            </Box>
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">Geplante Anwesenheit:</Typography>
                        </Grid>

                        <Grid item sm={9} xs={12}>

                            <Box sx={{ ml: { xs: 5, sm: 0 } }}>

                                <Grid container spacing={2} columns={presenceTypes.filter((pt: any) => pt.pre).length}>
                                    {presenceTypes.filter((pt: any) => pt.pre).map((pt: any, i2: number) => {
                                        let eventPresences: any = (data && data && data.event_presences && (data.event_presences.length > 0) && data.event_presences.filter((ep: any) => ep.dom_presence_type.pre));
                                        let ep: any = (eventPresences && eventPresences.length > 0 && eventPresences[0]);

                                        let isSelected: boolean = (pt.id && ep && ep.dom_presence_type && ep.dom_presence_type.id == pt.id);
                                        return (
                                            <Grid item sm={1} xs={12} key={i2}>
                                                <Button 
                                                    startIcon={(pt.id == 1) ? <Check /> : ((pt.id == 2) ? <QuestionMark /> : ((pt.id == 3) ? <Close /> : null))} 
                                                    color={isSelected ? "primary" :"secondary"} 
                                                    variant={isSelected ? "contained" : "outlined"}
                                                    fullWidth
                                                    onClick={() => { this.onPresenceTypeClicked(pt.id); }}
                                                    disabled={Util.isoStringToDate(data.begin_date)?.isBefore() ? true : false}
                                                >
                                                    {t('presence_type.' + pt.name)}
                                                </Button>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>

                        </Grid>

                    </Grid>
                )}

                {parentInstruments && parentInstruments.length > 0 && (
                    <Paper sx={{ mb: 2 }}>
                        <AppBar position="static" color="default">
                            <Tabs 
                                value={selectedTab} 
                                onChange={(event: React.SyntheticEvent, newValue: number) => { this.setState({selectedTab: newValue}); }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                            >
                                <Tab label="Geplante Anwesenheiten" />
                                {Role.isEventEditor() && (<Tab label="Tatsächlich Teilgenommen" />)}
                            </Tabs>
                        </AppBar>
                            <TabPanel value={selectedTab} index={0}>
                                <Box sx={{ m: 2 }}>
                                    
                                    {this.getAllPresencesTable(true, false)}

                                </Box>
                            </TabPanel>
                            {Role.isEventEditor() && (
                                <TabPanel value={selectedTab} index={1}>
                                    <Box sx={{ m: 2 }}>
                                        
                                        {this.getAllPresencesTable(false, true)}

                                    </Box>
                                </TabPanel>
                            )}
                    </Paper>
                )}



                <Backdrop open={fabOpen} />

                {Role.isEventEditor() && !this.props.showOtherEvents && (
                    <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 30, right: 30 }}>
              
                        <SpeedDial
                            ariaLabel="Termin bearbeiten"
                            sx={{ position: 'absolute', bottom: 0, right: 0 }}
                            icon={<SpeedDialIcon icon={<Edit />} openIcon={<Close />} />}
                            onClose={() => { this.setState({fabOpen: false}); }}
                            onOpen={() => { this.setState({fabOpen: true}); }}
                            open={fabOpen}
                            FabProps={{ color: "secondary" }}
                        >
                            <SpeedDialAction
                                icon={<Edit />}
                                tooltipTitle="Bearbeiten"
                                tooltipOpen
                                onClick={() => { this.setState({fabOpen: false, addId: data.id, addOpen: true}); }}
                            />
                            <SpeedDialAction
                                icon={<Delete />}
                                tooltipTitle="Löschen"
                                tooltipOpen
                                onClick={() => { this.setState({fabOpen: false, delId: data.id, delOpen: true}); }}
                            />
                        </SpeedDial>
                    </Box>
                )}

                <CalendarEventEditDialog 
                    addOpen={addOpen} 
                    addId={addId}
                    addBeginDate={moment(moment.now()).clone().startOf('day')} 
                    onClose={(id: any) => { this.setState({addOpen: false}, () => { if (id) { if (id == this.props.id) { this.searchEvent(); } else { this.props.navigate('/events/calendar/event/' + id, { replace: true }); } } }); }} 
                />

                <CalendarEventDelDialog 
                    delOpen={delOpen} 
                    delId={delId}
                    onClose={(success: boolean) => { this.setState({delOpen: false}, () => { if (success) { this.props.navigate('/events/calendar', { replace: true }); } }); }} 
                />
            </React.Fragment>
        );
    }

    getAllPresencesTable = (pre: boolean, post: boolean): JSX.Element => {
        const { t } = this.props;
        const { data, parentInstruments, presenceTypes, newPrePresences, newPostPresences } = this.state;

        const newPresences: any[] = (pre ? newPrePresences : (post ? newPostPresences : []));

        const actPresenceTypes: any[] = (presenceTypes && presenceTypes.length > 0) ? presenceTypes.filter((pt: any) => ((pre && pt.pre) || (post && pt.post))) : [];
        if (actPresenceTypes && actPresenceTypes.length > 0) {
            actPresenceTypes.forEach((pt: any, i: number) => {
                actPresenceTypes[i].count = [];
            });
        }

        let canEditAllPresences: boolean = (data && Role.isEventEditor() && ((pre && Util.isoStringToDate(data.begin_date)?.isAfter()) || (post && Util.isoStringToDate(data.begin_date)?.isBefore())));

        let hasAnyUser: boolean = false;

        let tableView: JSX.Element = (
            <React.Fragment>
                {parentInstruments && parentInstruments.map((parentInstr: any, i: number) => {
                    let exists: boolean = false;

                    parentInstr && parentInstr.dom_instruments && parentInstr.dom_instruments.forEach((instr: any) => {
                        if (instr.user_union_instruments && instr.user_union_instruments.length > 0) {
                            instr.user_union_instruments.forEach((userUnionInstr: any) => {
                                if (userUnionInstr.user_union && userUnionInstr.user_union.user){
                                    exists = true;
                                }
                            });
                        }
                    });

                    if (exists) {
                        return (
                            <React.Fragment key={i}>
                                <TableRow>
                                    <TableCell align="left" colSpan={pre ? 3 : 2} sx={{ fontWeight: 'bold' }}>{parentInstr.name ? t('instrument_parent.' + parentInstr.name) : ""}</TableCell>
                                </TableRow>
                                {parentInstr && parentInstr.dom_instruments && parentInstr.dom_instruments.map((instr: any, i2: number) => {
                                    if (instr.user_union_instruments && instr.user_union_instruments.length > 0) {
                                        return (
                                            <React.Fragment key={i2}>
                                                {instr.user_union_instruments.map((userUnionInstr: any, i3: number) => {
                                                    if (userUnionInstr.user_union && userUnionInstr.user_union.user) {
                                                        let userUnion: any = userUnionInstr.user_union;
                                                        let user: any = userUnion.user;
                                                        let userEventPresences: any[] = userUnion.event_presences && userUnion.event_presences.length > 0 && userUnion.event_presences.filter((ep: any) => ep.dom_presence_type && ((pre && ep.dom_presence_type.pre) || (post && ep.dom_presence_type.post)));
                                                        let userEp: any = (userEventPresences && userEventPresences.length > 0 && userEventPresences[0]);
                                                        hasAnyUser = true;
                                                        return (
                                                            <TableRow hover key={i3}>
                                                                <TableCell align="center" component="th" sx={{ whiteSpace: 'nowrap' }}>
                                                                    {user.fullname}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <ButtonGroup size="small">
                                                                        {actPresenceTypes && actPresenceTypes.length > 0 && actPresenceTypes.map((pt: any, i4: number) => {
                                                                            let isSelected: boolean = false;
                                                                            let wasChanged: boolean = false;
                                                                            newPresences.forEach((np: any) => { if (np.user_id == user.id) { wasChanged = true; isSelected = (np.dom_presence_type_id == pt.id); } });
                                                                            if (!wasChanged) {
                                                                                isSelected = (pt.id && userEp && userEp.dom_presence_type && (userEp.dom_presence_type.id == pt.id));
                                                                            }

                                                                            if (isSelected) {
                                                                                if (!actPresenceTypes[i4].count.includes(user.id)) {
                                                                                    actPresenceTypes[i4].count.push(user.id);
                                                                                }
                                                                            }

                                                                            return (
                                                                                <Button 
                                                                                    key={i4}
                                                                                    startIcon={(pt.id == 1 || pt.id == 4) ? <Check /> : ((pt.id == 2) ? <QuestionMark /> : ((pt.id == 3 || pt.id == 5) ? <Close /> : null))} 
                                                                                    color={isSelected ? "primary" :"secondary"} 
                                                                                    variant={isSelected ? "contained" : "outlined"}
                                                                                    disabled={(!canEditAllPresences) ? true : false}
                                                                                    onClick={() => { this.onPresenceTypeChanged(pt.id, user.id, pre, post) }}
                                                                                >
                                                                                    {t('presence_type.' + pt.name)}
                                                                                </Button>
                                                                            );
                                                                        })}
                                                                    </ButtonGroup>
                                                                </TableCell>
                                                                {pre && (
                                                                    <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>

                                                                        {
                                                                            userEp && userEp.comment
                                                                        }

                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                        );
                                                    }
                                                })}
                                            
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </React.Fragment>
                        );
                    }
                })}
            </React.Fragment>
        );

        if (hasAnyUser) {
            return (
                <form method="POST" onSubmit={(e: React.FormEvent) => { this.onPresenceTypeSubmit(e, pre, post); }}>
                    
                    <Box sx={{ textAlign: 'right' }}>
                        {actPresenceTypes && actPresenceTypes.length > 0 && actPresenceTypes.map((pt: any, i: number) => {
                            return (
                                <Box key={i} sx={{ display: 'inline', mx: 1 }}>
                                    <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'inline' }}>{t('presence_type.' + pt.name)}: </Typography>
                                    <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'inline', fontWeight: 'bold' }}>{pt.count.length}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                    
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                
                                {tableView}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    {canEditAllPresences && (
                        <Box sx={{ textAlign: 'center', mt: 3, pb: 3 }}>

                            <ButtonGroup variant="outlined">
                                <Button color="primary" type="submit" startIcon={<Save />}>Speichern</Button>
                            </ButtonGroup>

                        </Box>
                    )}

                </form>
            );
        } else if (!this.context.loading) {
            return (
                <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, pb: 3, textAlign: 'center' }}>
                    Keine Mitglieder vorhanden
                </Typography>
            );
        } else {
            return (<React.Fragment/>);
        }
    }

}

export default withHocs(CalendarEvent);