import React from 'react';
import { NavLink } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';

import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness2 from '@mui/icons-material/Brightness2';
import BrightnessAuto from '@mui/icons-material/BrightnessAuto';
import GetApp from '@mui/icons-material/GetApp';

import { AppContext } from './context';
import { withHocs, WithHocs } from './components/hocs';
import DialogTransition from './components/transition';
import Util from './components/util';
import TabPanel from './components/tabPanel';
import CookieBanner from './cookieBanner';
import PwaInstallDialog from './components/pwaInstallDialog';


//import '@pwabuilder/pwainstall';


interface State {
    user: string,
    pass: string,
    keep: boolean,
    msg: string,
    infoOpen: boolean,
    tabIndex: number,
    tabValue: any,

    unions: any[],
    selUnion: string,
    showUnionDialog: boolean,
    showUsageDialog: boolean,
    usageAccepted: boolean,
    showPwaInstallDialog: boolean,
  }

interface Props {
    children?: React.ReactNode,
    sharedFiles?: File[]
}

class Login extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    static counter = 0;
    fileUploaderInput: HTMLElement | null = null;

    state: State = { 
      user: "",
      pass: "",
      keep: false,
      msg: "",
      infoOpen: false,
      tabIndex: 0,
      tabValue: undefined,

      unions: [],
      selUnion: "",
      showUnionDialog: false,
      showUsageDialog: false,
      usageAccepted: false,
      showPwaInstallDialog: false,
    };

    pwaInstallRef: React.RefObject<HTMLElement>;
  
    constructor(props: any) {
      super(props);
      this.pwaInstallRef = React.createRef<HTMLElement>();
    }
    
    componentDidMount() {
        const { clientConfig } = this.context;

        var initState: any = {};

        // var tabIndex = 2;
        // try {
        //     if (Util.isAndroid())
        //         tabIndex = 0;
        //     else if (Util.isIOS())
        //         tabIndex = 1;
        // } catch (de) {
        //     console.log(de);
        // }
        // initState.tabIndex = tabIndex;
        // //this.setState({tabIndex: tabIndex});

        // if (this.pwaInstallRef && this.pwaInstallRef.current) {
        //     /*
        //     this.pwaInstallRef.current.explainer = "Durch die Installation der App wird eine Verknüpfung zur App-Übersicht bzw. zum Desktop hinzugefügt.";
        //     this.pwaInstallRef.current.featuresheader = "Funktionen";
        //     this.pwaInstallRef.current.descriptionheader = "Beschreibung";
        //     this.pwaInstallRef.current.installbuttontext = "Installieren";
        //     this.pwaInstallRef.current.cancelbuttontext = "Abbrechen";
        //     this.pwaInstallRef.current.iosinstallinfotext = "Tippe auf die \"Share\"-Schaltfläche und anschließend auf \"Zum Home-Bildschirm hinzufügen\"";
        //     */
        // }

        // try {
        //     if (typeof(Storage) !== "undefined" && localStorage.getItem("login_data")) {
        //         let userJson: string | null = localStorage.getItem("login_data");
                
        //         if (userJson) {
        //             let user: any = JSON.parse(userJson);
        //             initState.user = ((user && user.user) ? user.user : "");
        //             initState.pass = ((user && user.password) ? user.password : "");
        //             initState.keep = true;
        //         }
        //     }
        // } catch(ue) {
        //     console.log(ue);
        // }

        if (clientConfig && clientConfig.demo_user) {
            initState.user = clientConfig.demo_user;
        }
        if (clientConfig && clientConfig.demo_pwd) {
            initState.pass = clientConfig.demo_pwd;
        }

        this.setState(initState);
    }


    onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (this.state.showUsageDialog && !this.state.usageAccepted) {
            this.props.enqueueSnackbar("Bitte akzeptieren Sie die Nutzungsbedingungen.", { variant: 'error' });
        } else if (this.state.showUnionDialog && !this.state.selUnion) {
            this.props.enqueueSnackbar("Bitte wählen Sie einen Verein aus.", { variant: 'error' });
        } else {

            //this.setState({msg: ""});
            this.context.setLoading(true);
            this.context.setLoggedIn(false);

            var loginData: any = {username: this.state.user, password: this.state.pass, remember: this.state.keep, union: (this.state.selUnion ? parseInt(this.state.selUnion) : undefined), usage_accepted: this.state.usageAccepted};

            this.props.rest.post('login', loginData).then(data => {
                this.context.setLoading(false);
                this.setState({showUsageDialog: false, showUnionDialog: false});
                console.log(data);

                if (data && data.status) {
                    if (data.status == "ok") {
                        this.setState({showUsageDialog: false, showUnionDialog: false});
                        this.context.setPermission(data.roles);
                        this.context.setUser(data.user);
                        this.context.setUserUnion(data.user_union);
                        // this.context.setUnion(data.user_union.union);
                        
                        this.context.setLoggedIn(true);
                    } else if (data.status == "usage_not_accepted") {
                        this.setState({usageAccepted: false, showUsageDialog: true});
                    } else if (data.status == "multiple_unions") {
                        this.setState({selUnion: "", unions: data.user_unions.map((uu: any) => uu.union), showUnionDialog: true});
                    }
                } else {
                    this.setState({selUnion: "", unions: [], showUnionDialog: false, usageAccepted: false, showUsageDialog: false});
                    this.props.enqueueSnackbar("Fehler", { variant: 'error' });
                }
                
            }).catch(err => {
                console.log(err);
                let msg: string = "Fehler";
                if (err && err.errors) {
                    if (err.errors.user && err.errors.user.includes('not_found')) {
                        msg = "Ihr eingegebenes Passwort ist nicht korrekt.";
                    } else if (err.errors.user) {
                        msg = "Bitte geben Sie einen gültigen Benutzernamen ein.";
                    } else if (err.errors.password) {
                        msg = "Bitte geben Sie ein gültiges Passwort ein.";
                    }
                }
                this.setState({selUnion: "", unions: [], showUnionDialog: false, usageAccepted: false, showUsageDialog: false});
                this.props.enqueueSnackbar(msg, { variant: 'error' });
                this.context.setLoading(false);
            });
        
        }
        
    }
  
    render() {
      const { version, themeMode, beforeInstallPrompt } = this.context;
      const { infoOpen, tabIndex, tabValue, showUsageDialog, usageAccepted, showUnionDialog, unions, selUnion, showPwaInstallDialog } = this.state;

      return (
        <React.Fragment>
            <form noValidate onSubmit={this.onSubmit} style={{
                width: '100%',
                marginTop: 1,
            }}>
                { this.state.msg && 
                    <Alert elevation={6} style={{marginTop: 20, marginBottom: 20}} severity="error">{this.state.msg}</Alert>
                }
                <TextField
                    fullWidth
                    margin="normal"
                    label="Benutzername"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={this.state.user}
                    onChange={event => { this.setState({user: event.target.value}) } }
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="password"
                    label="Passwort"
                    type="password"
                    autoComplete="current-password"
                    value={this.state.pass}
                    onChange={event => { this.setState({pass: event.target.value}) } }
                />
                <Card sx={{ 
                    textAlign: 'center', 
                    mt: 1, 
                    mb: 6, 
                    bgcolor: 'inherit', 
                }}>
                    <CardActionArea onClick={evt => { this.setState({keep: !this.state.keep}) }}>
                        <CardContent style={{padding: 0}}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={this.state.keep}
                                        //onChange={evt => { this.setState({keep: evt.target.checked}) }}
                                        name="keep"
                                        color="primary"
                                    />
                                }
                                label="Angemeldet bleiben"
                                onClick={evt => { evt.preventDefault() }}
                            />
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Login
                </Button>

                {(beforeInstallPrompt || Util.isIOS()) && !Util.isPwaInstalled() && (
                    <Button
                        fullWidth
                        variant="outlined"
                        startIcon={ <GetApp /> }
                        color="secondary"
                        onClick={() => { this.setState({showPwaInstallDialog: true}) }}
                        sx={{ mt: 4 }}
                    >
                        Installieren
                    </Button>
                )}

                {/*version && (
                    <Box sx={{ 
                        textAlign: 'right',
                        width: '100%',
                        p: 2,
                    }}>
                        <Typography color="textSecondary" variant="subtitle2">{version}</Typography>
                    </Box>
                )*/}

                {/*!Util.isIE() && (
                    <Box sx={{ mt: 60 }}>
                        <pwa-install ref={this.pwaInstallRef}>
                            <Button
                                fullWidth
                                variant="contained"
                                //color="secondary"
                                startIcon={<GetAppOutlinedIcon />}
                            >
                                Installieren
                            </Button>
                        </pwa-install>
                    </Box>
                )*/}

            </form>





            <CookieBanner />

            <Dialog
                open={showUsageDialog}
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='sm'
            >
                <DialogTitle>Nutzungsbedingungen akzeptieren</DialogTitle>
                <form onSubmit={this.onSubmit}>
                    <DialogContent>

                        <Typography variant="body2" gutterBottom>Um das Online-Service "Meine Musik" verwenden zu können, bestätigen Sie bitte die <Link component={NavLink} to="/terms" target="_blank" underline="hover" color="primary">Nutzungsbedingungen</Link>.</Typography>

                        <FormControlLabel control={<Checkbox checked={usageAccepted} onChange={e => { this.setState({usageAccepted: e.target.checked}) }} />} label="Ich akzeptiere die Nutzungsbedingungen" sx={{ mt: 2 }} />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({showUsageDialog: false}); }}>Abbrechen</Button>
                        <Button type="submit">Login</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={showUnionDialog}
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='sm'
            >
                <DialogTitle>Vereins-Auswahl</DialogTitle>
                <DialogContent>

                    <Typography variant="body1">Mehrere Vereine haben dich als Mitglied angegeben, bitte wähle den Verein aus, bei dem du dich anmelden möchtest.</Typography>

                    <FormControl fullWidth sx={{ my: 3 }}>
                        <InputLabel>Verein</InputLabel>
                        
                        <Select
                            value={selUnion}
                            onChange={(e: any) => { this.setState({selUnion: e.target.value});  }}
                            //onInput={e => { this.setState({selUnion: e.currentTarget.nodeValue || "" }); }}
                            fullWidth
                        >
                            {/* <MenuItem value=""></MenuItem> */}
                            {unions && unions.map((union: any, i: number) => {
                                return (
                                    <MenuItem value={union.id} key={i}>{union.name}</MenuItem>
                                );
                            })}
                        </Select>

                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({unions: [], selUnion: "", showUnionDialog: false}); }}>Abbrechen</Button>
                    <Button onClick={() => { this.onSubmit(); }}>Login</Button>
                </DialogActions>
            </Dialog>

            <PwaInstallDialog open={showPwaInstallDialog} onClose={() => { this.setState({showPwaInstallDialog: false}) }} />

            {/*
            <Dialog
                open={infoOpen}
                onClose={() => { this.setState({infoOpen: false}) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle id="alert-dialog-title">{"Installations-Anleitung"}</DialogTitle>
                <DialogContent sx={{
                    p: 0,
                    textAlign: 'center',
                    justifyContent: 'center',
                }}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={tabIndex}
                            onChange={(event: React.ChangeEvent<{}>, index: number) => { this.setState({tabIndex: index}) }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                        <Tab label="Android" />
                        <Tab label="iOS" />
                        <Tab label="PC" />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabIndex} index={0}>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Play Store öffnen und Google Chrome Browser aktualisieren und öffnen</Typography>
                        <img src="images/install/android_appstore.jpg" style={{ width: '45%' }} />
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Intranet öffnen: <Link href="https://www.tk-bad-grosspertholz.at/intranet/" color="primary">https://www.tk-bad-grosspertholz.at/intranet/</Link></Typography>
                        <img src="images/install/android_a2h.jpg" style={{ width: '45%' }}/>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Zum Startbildschirm hinzufügen</Typography>
                        <img src="images/install/android_a2h_install.jpg" style={{ width: '45%' }}/>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>App öffnen</Typography>
                        <img src="images/install/android_app_uebersicht.jpg" style={{ width: '45%' }}/>
                        <img src="images/install/android_app_open.jpg" style={{ width: '45%' }}/>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Safari Browser öffnen</Typography>
                        <img src="images/install/ios_safari.jpg" style={{ width: '45%' }}/>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Intranet öffnen: <Link href="https://www.tk-bad-grosspertholz.at/intranet/" color="primary">https://www.tk-bad-grosspertholz.at/intranet/</Link></Typography>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Zum Startbildschirm hinzufügen</Typography>
                        <img src="images/install/ios_safari_bar.jpg" style={{ width: '45%' }}/>
                        <br/>
                        <img src="images/install/ios_a2h.jpg" style={{ width: '45%' }}/>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>App öffnen</Typography>
                        <img src="images/install/ios_app_uebersicht.jpg" style={{ width: '45%' }}/>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Google Chrome Browser installieren und öffnen</Typography>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Intranet öffnen: <Link href="https://www.tk-bad-grosspertholz.at/intranet/" color="primary">https://www.tk-bad-grosspertholz.at/intranet/</Link></Typography>
                        <img src="images/install/pc_webseite.jpg" style={{ width: '65%' }}/>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Web-App installieren</Typography>
                        <img src="images/install/pc_webseite_install.jpg" style={{ width: '65%' }}/>
                        <Typography variant="subtitle1" color="textPrimary" sx={{ fontWeight: 'bold', pt: 25, pb: 10 }}>Web-App vom Desktop öffnen</Typography>
                        <img src="images/install/pc_desktop.jpg" style={{ width: '65%' }}/>
                        <br/>
                        <img src="images/install/pc_app_open.jpg" style={{ width: '65%' }}/>
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => { this.setState({infoOpen: false}) }} color="primary" autoFocus>
                    Schließen
                </Button>
                </DialogActions>
            </Dialog>
            */}

        </React.Fragment>
      );
    }
  }

export default withHocs(Login);