import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import UploadFile from '@mui/icons-material/UploadFile';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Delete from '@mui/icons-material/Delete';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import TaskIcon from '@mui/icons-material/Task';
import DoneIcon from '@mui/icons-material/Done';

import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { withHocs, WithHocs } from '../components/hocs';


export interface FileInput {
    //id: string | undefined,
    name: string,
    id?: number,
    del?: boolean,
    exists?: boolean,
    file: File | undefined,
    mime?: string,
    previewUrl?: string | null,
    uploadFailed?: boolean,
}
  
interface Props {
    children?: React.ReactNode,
    disabled?: boolean,
    onFilesAdded: Function,
    multiple?: boolean,
    fileInputName: string,
    displayName?: string,
    files?: FileInput[],
    onlyPdf?: boolean,
}

interface State {
    highlight: boolean,
}

class Dropzone extends React.Component<WithHocs & Props, State> {

    state = { 
      highlight: false,
    }

    fileInputRef: React.RefObject<HTMLInputElement>;
    formRef: React.RefObject<HTMLFormElement>;

    previewMimeTypes: string[] = ["image/jpeg", "image/jpg", "image/png"];

    constructor(props: any) {
        super(props);
        this.fileInputRef = React.createRef<HTMLInputElement>();
        this.formRef = React.createRef<HTMLFormElement>();
    }

    

    openFileDialog = () => {
      if (this.props.disabled) return;
      this.fileInputRef.current?.click();
    }

    onFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (this.props.disabled) return;
      const files = evt.target.files;
      this.fileListToArray(files).then(array => {
        this.props.onFilesAdded(this.props.fileInputName, array);
      });
    }

    onDragOver = (evt: React.DragEvent) => {
      evt.preventDefault();

      if (this.props.disabled) return;

      this.setState({ highlight: true });
    }

    onDragLeave = (evt: React.DragEvent) => {
      this.setState({ highlight: false });
    }

    onDrop = (evt: React.DragEvent) => {
      evt.preventDefault();

      if (this.props.disabled) return;

      const files = evt.dataTransfer.files;
      this.fileListToArray(files).then(array => {
        this.props.onFilesAdded(this.props.fileInputName, array);
        this.setState({ highlight: false });
      });
    }

    fileListToArray = async (list: FileList | null | undefined): Promise<FileInput[]> => {
      const { onlyPdf } = this.props;
      
      let array: FileInput[] = this.props.files || [];
      if (list) {
          for (var i = 0; i < list.length; i++) {
              let file: File | null = list.item(i);
              if (file) {
                  if (!file.type || (file.type.indexOf("application/pdf") > -1) || !onlyPdf) {

                    let previewUrl: string | null | undefined = undefined;
                    if (file.type && this.previewMimeTypes.includes(file.type)) {
                      previewUrl = await new Promise((resolve, reject) => {
                        let fileReader = new FileReader();
                        fileReader.onload = (e) => resolve(fileReader.result as string);
                        fileReader.onerror = (e) => reject(e);
                        fileReader.readAsDataURL(file as File);
                      });
                    }


                    array.push({name: file.name, file: file, exists: false, del: false, previewUrl: previewUrl, mime: file.type, uploadFailed: false});
                  } else {
                    this.props.enqueueSnackbar("Die Datei '" + file.name + "' kann nicht hochgeladen werden da es kein PDF ist.", { variant: 'error' });
                  }
              }
          }
      }
      if (array.length > 1 && !this.props.multiple) {
        array = [array[array.length - 1]];
      }

      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
      if (this.formRef.current) {
        this.formRef.current.reset();
      }

      return array;
    }

    clearFiles = (evt: React.MouseEvent, i?: number) => {
      evt.preventDefault();
      evt.stopPropagation();

      let newFileArray: FileInput[] = this.props.files || [];

      if (this.props.multiple && i !== undefined) {
        if (newFileArray[i].exists) {
          newFileArray[i].del = true;
        } else {
          newFileArray.splice(i, 1);
        }
      } else {
        for (let x: number = 0; x < newFileArray.length; x++) {
          let file: FileInput = newFileArray[x];
          if (file.exists) {
            file.del = true;
          } else {
            newFileArray.splice(x, 1);
            x--;
          }
        }
      }

      this.props.onFilesAdded(this.props.fileInputName, newFileArray);
    }

    render() {
        const { multiple, displayName, disabled, fileInputName, files, onlyPdf } = this.props;
        const { highlight } = this.state;

        const iconWrapper: SxProps = {
            textAlign: 'center',
            mt: 1,
            mb: 1,
            position: 'relative'
        };

        const icon: SxProps = {
            opacity: 0.3,
            height: 64,
            width: 64,
        };

        const overlayIcon: SxProps = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0.4,
          height: 64,
          width: 64,
        };

        let showDelAll: boolean = false;
        if (files) {
          files.forEach((file: FileInput) => {
            if (!file.del) {
              showDelAll = true;
            }
          });
        }

        return (
          <form ref={this.formRef}>
            <Grid
                container
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}

                sx={{
                  height: '100%',
                  width: '100%',
                  border: '2px dashed rgb(187, 186, 186)',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: (highlight ? 'rgb(188, 185, 236)' : undefined),
                  cursor: (disabled ? 'default' : 'pointer'),
                }}
            >
              
              <input
                ref={this.fileInputRef}
                type="file"
                multiple={multiple}
                onChange={this.onFilesAdded}
                accept={onlyPdf ? "application/pdf" : undefined}
                name={fileInputName}
                style={{ display: 'none' }}
                />
                
                {showDelAll ? (
                  <React.Fragment>
                    {files && files.map((file: FileInput, i: number) => {
                      if (!file.del) {

                        return (
                          <Grid item xs={12} md={6} key={i} sx={iconWrapper}>
                            {(file.mime && this.previewMimeTypes.includes(file.mime) && file.previewUrl) ? (
                              <Box sx={{...icon, width: '100%', opacity: 0.8, backgroundSize: 'contain', position: 'relative'}}>
                                <img src={file.previewUrl} style={{ display: 'block', margin: '0 auto', maxHeight: '100%', maxWidth: '100%', verticalAlign: 'middle', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }} />
                                {file.exists 
                                  ? <DoneIcon sx={overlayIcon} />
                                  : (file.uploadFailed ? <DangerousOutlinedIcon sx={overlayIcon} /> : null)
                                }
                              </Box>
                            ) : (
                              <React.Fragment>
                                {file.exists ? <TaskIcon sx={icon} /> : <InsertDriveFile sx={icon} />}
                                {file.uploadFailed && <DangerousOutlinedIcon sx={overlayIcon} />}
                              </React.Fragment>
                            )}
                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>{file.name}</Typography>
                            {multiple && (<Button variant="text" startIcon={<Delete />} color="error" onClick={e => this.clearFiles(e, i)}>Löschen</Button>)}
                          </Grid>
                        );
                      }
                    })}

                    <Grid item xs={12} sx={iconWrapper}>
                      <Button variant="text" startIcon={<Delete />} color="error" onClick={this.clearFiles}>{multiple ? "Alle löschen" : "Löschen"}</Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid item xs={12} sx={iconWrapper}>
                    <UploadFile sx={icon} />
                    <Typography variant="body1">{displayName ? displayName : "Datei hochladen"}</Typography>
                  </Grid>
                )}
                
            </Grid>
          </form>
        );
    }
}

export default withHocs(Dropzone);