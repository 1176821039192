import React from 'react';
import moment from 'moment-timezone';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import ButtonBase from '@mui/material/ButtonBase';

import { MobileDatePicker } from '@mui/x-date-pickers';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Add from '@mui/icons-material/Add';

import { AppContext } from '../../context';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import CalendarEventEditDialog from './calendarEventEditDialog';


interface Props {
    children?: React.ReactNode,
}

interface State {
    dateContext: moment.Moment,
    data: any,
    dayData: any,

    addOpen: boolean,
    addId: any,
}


class CalendarMonth extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        dateContext: moment().startOf('month'),
        data: undefined as any,
        dayData: undefined as any,

        addOpen: false,
        addId: undefined,
    };

    constructor(props: any) {
        super(props);
    }
    
    weekdaysShort = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
    months = ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    componentDidMount() { 
        this.searchEvents();
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
        if (this.props && this.props.params && this.props.params.month && ((prevProps && prevProps.params && (this.props.params.month !== prevProps.params.month)) || !(prevProps && prevProps.params && prevProps.params.month))) {
            this.searchEvents();
        }
    }

    searchEvents = () => {
        const { month } = this.props.params;

        let dateContext: moment.Moment = moment().startOf('month');
        if (month) {
            dateContext = moment(month).startOf('month');
        }
        this.setState({dateContext: dateContext, data: undefined});

        let from: moment.Moment = dateContext.clone().startOf('month');
        let to: moment.Moment = dateContext.clone().endOf('month');
    
        this.context.setLoading(true);
        let eventData: any = { from: Util.dateToIsoString(from), to: Util.dateToIsoString(to) };
    
        this.props.rest.post("calendar/search", eventData).then(data => {
    
          this.context.setLoading(false);
    
          if (data && data.events && data.status && data.status == "ok") {
    
            this.setState({data: data.events});
            console.log(data);
    
          } else {
            this.props.enqueueSnackbar("Termine konnten nicht gesucht werden", { variant: 'error' });
          }
    
        }).catch(err => {
          this.context.setLoading(false);
          console.log(err);
          this.props.enqueueSnackbar("Termine konnten nicht gesucht werden", { variant: 'error' });
        });
    }

    year = (): number => {
        return this.state.dateContext.year();
    }
    month = (): number => {
        return this.state.dateContext.month() + 1;
    }
    daysInMonth = (): number => {
        return this.state.dateContext.daysInMonth();
    }
    isCurrentDay = (d: number): boolean => {
        const { dateContext } = this.state;
        let td: Date = new Date();
        if (td.getFullYear() == dateContext.year() && td.getMonth() == dateContext.month()) {
            return (td.getDate() == d);
        }
        return false;
    }

    firstDayOfMonth = (): number => {
        let firstDay: number = this.state.dateContext.clone().startOf('month').isoWeekday();    //1 (Montag) - 7 (Sonntag)
        return firstDay;
    }

    nextMonth = () => {
        const { dateContext } = this.state;
        this.props.navigate("/events/calendar/month/" + Util.dateToUrlString(dateContext.add(1, 'M').startOf('month'), false, false), { replace: true });
    }

    prevMonth = () => {
        const { dateContext } = this.state;
        this.props.navigate("/events/calendar/month/" + Util.dateToUrlString(dateContext.subtract(1, 'M').startOf('month'), false, false), { replace: true });
    }

    onDateSelected = (date: moment.Moment) => {
        this.props.navigate("/events/calendar/month/" + Util.dateToUrlString(date, false, false), { replace: true });
    }

    onDayClick = (day: moment.Moment) => {
        this.props.navigate("/events/calendar/day/" + Util.dateToUrlString(day, true, false));
    }

    render() {
        const { dateContext, data, addOpen, addId } = this.state;

        let blanks: JSX.Element[] = [];
        for (let i: number = 0; i < (this.firstDayOfMonth() - 1); i++) {
            blanks.push(<Grid item xs={1} key={i}>{" "}</Grid>);
        }

        let daysInMonth = [];
        for (let d: number = 0; d < this.daysInMonth(); d++) {
            let day: number = d + 1;
            let currentDay: moment.Moment = dateContext.clone().date(day).startOf('day');
            daysInMonth.push(
                <Grid item xs={1} key={blanks.length + d}>
                    <ButtonBase sx={{ height: '100%', width: '100%', minHeight: 110, position: 'relative', borderWidth: 1, borderStyle: 'solid', borderColor: 'grey.300', display: 'block', pt: 3 }} component='div' onClick={() => { this.onDayClick(currentDay); }}>

                        <Avatar sx={{ bgcolor: (moment(moment.now()).isSame(currentDay, 'day') ? 'primary.main' : 'inherit'), borderWidth: 0.3, borderStyle: 'solid', borderColor: 'text.secondary', width: { xs: 14, md: 18}, height: { xs: 14, md: 18 }, color: 'text.primary', fontSize: { xs: '6pt', md: '9pt' }, position: 'absolute', left: 3, top: 3  }}>
                            {d+1}
                        </Avatar>

                        {/*<CardActionArea sx={{ height: '100%' }} onClick={() => { this.onDayClick(currentDay); }}>
                            <CardContent sx={{ px: 0 }}>*/}

                                {data && data.map((evt: any, i: number) => {
                                    let cDate: moment.Moment = dateContext.clone().date(day).startOf('day');

                                    let evtBeginDate: moment.Moment | undefined = Util.isoStringToDate(evt.begin_date);
                                    let evtEndDate: moment.Moment | undefined = Util.isoStringToDate(evt.end_date);

                                    let show: boolean = false;
                                    if (evtBeginDate) {
                                        if (cDate.isSame(evtBeginDate, 'day')) {
                                            show = true;
                                        } else if (evtEndDate && cDate.isSame(evtEndDate, 'day')) {
                                            show = true;
                                        } else if (evtEndDate && cDate.isBetween(evtBeginDate, evtEndDate, 'day')) {
                                            show = true;
                                        }
                                    }

                                    if (show) {
                                        return (
                                            <Box sx={{ bgcolor: 'info.main', mb: 1, width: '100%' }} key={1000 + i}>
                                                <Typography variant="body2" sx={{ fontSize: { xs: '6pt', md: '9pt' }, wordBreak: 'break-word', textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '3em' }}>{evt.title}</Typography>
                                            </Box>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                            {/*</CardContent>
                        </CardActionArea>*/}
                    </ButtonBase>
                </Grid>
            );
        }

        var totalSlots = [...blanks, ...daysInMonth];
        let rows: JSX.Element[][] = [];
        let cells: JSX.Element[] = [];

        totalSlots.forEach((row: JSX.Element, i: number) => {
            if ((i % 7) !== 0) {
                cells.push(row);
            } else {
                let insertRow: JSX.Element[] = cells.slice();
                rows.push(insertRow);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                let insertRow: JSX.Element[] = cells.slice();
                rows.push(insertRow);
            }
        });

        if ((totalSlots.length % 7) != 0) {
            let rest: number = 7 - (totalSlots.length % 7);
            for (let i: number = 0; i < rest; i++) {
                totalSlots.push(<Grid item xs={1} key={blanks.length + daysInMonth.length + i}>{""}</Grid>)
            }
        }

        return (
            <Container maxWidth="xl" disableGutters sx={{ pb: 2, height: '100%', display: 'flex', flexDirection: 'column', px: { xs: 0, md: 2 } }}>

                <Grid container spacing={1} sx={{ flexGrow: 0, flexShrink: 0 }}>

                    <Grid item sm={6} xs={12} sx={{ display: { sm: 'flex', xs: 'none' }, justifyContent: 'center', alignItems: 'center' }}>
                        {dateContext && (
                            <Typography variant="h6" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                                {this.months[dateContext.month()] + " " + dateContext.year()}
                            </Typography>
                        )}
                    </Grid>
                    
                    <Grid item sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton onClick={() => { this.prevMonth() }}>
                            <ChevronLeft />
                        </IconButton>

                        
                        <MobileDatePicker
                            label="Monat"
                            views={['year', 'month']}
                            openTo="month"
                            inputFormat="MM.YYYY"
                            mask="__.____"
                            minDate={moment().year(1950).month(0).date(1).startOf('month')}
                            maxDate={moment().year(2999).month(11).date(1).startOf('month')}
                            renderInput={(params: any) => <TextField {...params} variant="standard" />}
                            value={dateContext}
                            onChange={(date: any, keyboardInputValue: any) => {
                                if (date && date.isValid()) {
                                    this.onDateSelected(date);
                                }
                            }}
                        />

                        <IconButton onClick={() => { this.nextMonth() }}>
                            <ChevronRight />
                        </IconButton>
                    </Grid>
                    
                </Grid>

            

                <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
                
                    <Grid container spacing={0} columns={7} sx={{ width: '100%' }}>
                        {this.weekdaysShort.map((day: string, i: number) => {
                            return (
                                <Grid item xs={1} key={(-1) * (i + 1)}>
                                    <Card sx={{ textAlign: 'center' }}>
                                        <Typography variant="body2" color="text.secondary">
                                            {day}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                
                </Box>

                <Grid container spacing={0} columns={7} sx={{ flexGrow: 1, flexShrink: 1, minHeight: 400, my: 1 }}>

                    {totalSlots}

                </Grid>

                {Role.isEventEditor() && (
                    <Fab color="secondary" onClick={() => { this.setState({addOpen: true, addId: undefined}) }} 
                        sx={{
                            position: 'fixed',
                            bottom: 30,
                            right: 30,
                        }}
                    >
                        <Add />
                    </Fab>
                )}

                <CalendarEventEditDialog 
                    addOpen={addOpen} 
                    addId={addId}
                    addBeginDate={moment(moment.now()).isSame(dateContext, 'month') ? moment(moment.now()).clone().startOf('day') : dateContext} 
                    onClose={(id: any) => { this.setState({addOpen: false}, () => { if (id) { this.props.navigate('/events/calendar/event/' + id); } }); }} 
                />
            
            </Container>
        );
    }

}

export default withHocs(CalendarMonth);