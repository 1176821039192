import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce, isUndefined } from 'lodash';
import LazyLoad from 'react-lazyload';    //change back to version in package.json when react 18 support: https://github.com/twobin/react-lazyload/issues/380

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/icons-material/Menu';
import Edit from '@mui/icons-material/Edit';
import Person from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import TextDivider from '../../components/textDivider';
import Dropzone, { FileInput } from '../../components/dropzone';
import { userInfo } from 'os';

interface Props {
  children?: React.ReactNode,
}

interface State {
  config: any,
  data: any,
  imgCacheId: string,

  name: string,
  searchName: any[],
  func: string,
  searchFunction: any[],
  instr: number[],
  roles: number[],

  panelExpanded: boolean,

  showUser: any,
  showImgLoaded: boolean,

  addOpen: boolean,
  addId: number | undefined,
  addUsername: string,
  addFirstname: string,
  addSurname: string,
  addBirthday: moment.Moment | undefined | null,
  addMail: string,
  addFunction: string,
  addSearchFunction: any[],
  addPassword: string,
  addPasswordConfirm: string,
  addRoles: number[],
  addInstr: number[],
  fileUploads: FileInput[],

  sameUsers: any[],
  notSameUsers: any[],
  sameMail: boolean,

  fabOpen: boolean,
  delOpen: boolean,
}


class UserOverview extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state: State = { 
    config: undefined as any,
    data: undefined as any,
    imgCacheId: "",

    name: "",
    searchName: [],
    func: "",
    searchFunction: [],
    instr: [],
    roles: [],

    panelExpanded: false,

    showUser: undefined,
    showImgLoaded: false,

    addOpen: false,
    addId: undefined,
    addUsername: "",
    addFirstname: "",
    addSurname: "",
    addBirthday: null,
    addMail: "",
    addFunction: "",
    addSearchFunction: [],
    addPassword: "",
    addPasswordConfirm: "",
    addRoles: [],
    addInstr: [],
    fileUploads: [],

    sameUsers: [],
    notSameUsers: [],
    sameMail: false,

    fabOpen: false,
    delOpen: false,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadConfigs();
  }

  loadConfigs = () => {
    this.context.setLoading(true);

    this.setState({config: undefined, showUser: undefined});

    this.props.rest.post('user/getConfig').then(data => {

        this.context.setLoading(false);
        if (data && data.status && data.status == "ok") {
            this.setState({config: data}, () => {
              this.searchUsers();
            });
            console.log(data);
    
        } else {
            this.props.enqueueSnackbar("Benutzer konnten nicht gesucht werden", { variant: 'error' });
        }

    }).catch(err => {
        this.context.setLoading(false);
        this.props.enqueueSnackbar("Benutzer konnten nicht gesucht werden", { variant: 'error' });
        console.log(err);
    });
  }

  searchUsers = (e?: React.FormEvent) => {
    e?.preventDefault();

    const { data, name, func, instr, roles, panelExpanded } = this.state;

    this.setState({data: undefined, showUser: undefined});

    this.context.setLoading(true);
    let searchData: any = { name: name, function: func, dom_instrument_parents: instr, dom_roles: roles };

    this.props.rest.post("user/search", searchData).then(data => {

      this.context.setLoading(false);

      if (data && data.dom_instrument_parents && data.status && data.status == "ok") {

        let imgCacheId: string = moment().valueOf().toString();

        this.setState({data: data.dom_instrument_parents, panelExpanded: ((data.users && data.users.length > 0) ? false : panelExpanded), imgCacheId: imgCacheId});
        console.log(data);

      } else {
        this.props.enqueueSnackbar("Benutzer konnten nicht gesucht werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Benutzer konnten nicht gesucht werden", { variant: 'error' });
    });

  }

  onAddClicked = (e?: React.FormEvent<HTMLFormElement>, sameUserId?: number) => {
    e?.preventDefault();
    const { addId, addUsername, addFirstname, addSurname, addBirthday, addMail, addFunction, addPassword, addPasswordConfirm, addRoles, addInstr, fileUploads, notSameUsers } = this.state;

    if (!addUsername || !addUsername.trim()) {
      this.props.enqueueSnackbar("Ein Benutzername muss angegeben werden", { variant: 'error' });
      return ;
    }

    if (!addFirstname || !addFirstname.trim()) {
      this.props.enqueueSnackbar("Ein Vorname muss angegeben werden", { variant: 'error' });
      return ;
    }

    if (!addSurname || !addSurname.trim()) {
      this.props.enqueueSnackbar("Ein Nachname muss angegeben werden", { variant: 'error' });
      return ;
    }

    if (!addBirthday) {
      this.props.enqueueSnackbar("Ein Geburtsdatum muss angegeben werden", { variant: 'error' });
      return ;
    }

    if (!addId) {

      if ((!addMail || !addMail.trim()) && (!addPassword || !addPassword.trim())) {
        this.props.enqueueSnackbar("Eine E-Mail-Adresse oder ein Passwort muss angegeben werden", { variant: 'error' });
        return ;
      }

    }

    if ((addPassword && addPassword.trim()) && (!addPasswordConfirm || !addPasswordConfirm.trim() || (addPassword !== addPasswordConfirm))) {
      this.props.enqueueSnackbar("Das Passwort muss korrekt wiederholt werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);

    let userData: FormData = new FormData();
    if (addId) {
      userData.append('id', addId + '');
    }

    userData.append('username', addUsername);
    userData.append('firstname', addFirstname);
    userData.append('surname', addSurname);
    if (addBirthday) {
      userData.append('birthday', Util.dateToIsoString(addBirthday) || "");
    }

    if (addPassword) {
      userData.append('password', addPassword);
    }

    if (addMail) {
      userData.append('email', addMail);
    }

    if (addFunction) {
      userData.append('function', addFunction);
    }

    if (addRoles) {
      addRoles.forEach((addRole: number, i: number) => {
        userData.append('dom_roles[]', addRole + '');
      });
    }

    if (addInstr) {
      addInstr.forEach((addInst: number, i: number) => {
        userData.append('dom_instruments[]', addInst + '');
      });
    }

    if (fileUploads && fileUploads && fileUploads.length > 0) {
      fileUploads.forEach((file: FileInput) => {
        if (file && file.exists && file.del && file.id) {
          userData.append('del_img_user_union', '1');
        } else if (file && file.file) {
          userData.append('add_img_user_union', file.file);
        }
      });
    }

    if (sameUserId) {
      userData.append('is_same_user', '1');
      userData.append('same_user_id', sameUserId + '');
    } else if (notSameUsers && (notSameUsers.length > 0)) {
      userData.append('is_same_user', '0');
    }

    this.props.rest.postForm("user/upload", userData).then(data => {

      this.context.setLoading(false);

      if (data && data.status && data.status == "same_users" && data.users) {

        let sameMail: boolean = false;
        if (addMail) {
          data.users.forEach((user: any) => {
            if (user.email && (user.email.toLowerCase() === addMail.toLowerCase())) {
              sameMail = true;
            }
          });
        }
        this.setState({sameUsers: data.users, notSameUsers: [], sameMail: sameMail});

      } else if (data && data.status && data.status == "ok") {

        this.props.enqueueSnackbar("Benutzer erfolgreich " + (addId ? "bearbeitet" : "erstellt"), { variant: 'success' });

        this.setState({addOpen: false, addId: undefined, addUsername: "", addMail: "", addPassword: "", addPasswordConfirm: "", addRoles: [], addInstr: [], addFirstname: "", addSurname: "", addBirthday: null}, () => {
          this.searchUsers();
        });

      } else {
        this.props.enqueueSnackbar("Benutzer konnten nicht " + (addId ? "bearbeitet" : "erstellt") + " werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Benutzer konnten nicht " + (addId ? "bearbeitet" : "erstellt") + " werden", { variant: 'error' });
    });

  }

  async search(uri: string, data: any) {
    return this.props.rest.post(uri, data);
  }

  searchName = debounce(async () => {
    const { name } = this.state; 
    if (name) {
      this.setState({searchName: 
        await this.search('user/searchName', {name: name})
      });
    }
  }, 450);

  searchFunction = debounce(async () => { 
    const { func } = this.state;
    if (func) {
        this.setState({searchFunction: 
            await this.search('user/searchFunction', {function: func})
        });
    }
  }, 450);

  addSearchFunction = debounce(async () => { 
    const { addFunction } = this.state;
    if (addFunction) {
        this.setState({addSearchFunction: 
            await this.search('user/searchFunction', {function: addFunction})
        });
    }
  }, 450);

  concatDomCboIdToNames = (domEntries: any[], selItems: number[], tPrefix?: string): string => {
    const { t } = this.props;

    let names: string = "";
    let iNames: number = 0;
    domEntries.forEach((domEntry: any, i: number) => {
      if (selItems.indexOf(domEntry.id) > -1) {
        names = names + (tPrefix ? t(tPrefix + '.' + domEntry.name) : domEntry.name);
        if (iNames < (selItems.length - 1)) {
          names = names + ", ";
        }
        iNames++;
      }
    });
    return names;
  }

  setUploadFiles = (fileInputName: string, files: FileInput[]): void => {
    this.setState({fileUploads: files});
  }

  editUser = (user: any) => {
    const { imgCacheId } = this.state;

    let userUnion: any = undefined;
    let roles: number[] = [];
    let instrs: number[] = [];
    if (user && user.user_unions && user.user_unions.length > 0) {
      userUnion = user.user_unions[0];
      if (userUnion.permissions && userUnion.permissions.length > 0) {
        roles = userUnion.permissions.map((perm: any) => perm.dom_role.id);
      }

      if (userUnion.user_union_instruments && userUnion.user_union_instruments.length > 0) {
        instrs = userUnion.user_union_instruments.map((instr: any) => instr.dom_instrument.id);
      }
    }

    let newFileUploads: FileInput[] = [];
    if (userUnion && userUnion.img_mime) {
      newFileUploads.push({ id: userUnion.id, name: 'Profilbild', del: false, exists: true, file: undefined, previewUrl: ("api/user/download?user_union=" + userUnion.id  + (imgCacheId ? "&img_cache_id=" + imgCacheId : "")), mime: userUnion.img_mime, uploadFailed: false });  
    }

    this.setState({ showUser: undefined, addId: user.id, addFirstname: user.firstname || "", addSurname: user.surname || "", addBirthday: (user.birthday ? Util.isoStringToDate(user.birthday) : null), addPassword: "", addPasswordConfirm: "", addRoles: roles, addInstr: instrs, addMail: user.email || "", addFunction: userUnion?.function || "", addSearchFunction: [], addUsername: user.username || "", addOpen: true, fileUploads: newFileUploads }); 
  }

  onDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { showUser } = this.state;
    if (showUser && showUser.id) {
      this.context.setLoading(true);

      let delData: any = { id: showUser.id };

      this.props.rest.post('user/del', delData).then(data => {

        this.context.setLoading(false);

        if (data && data.status && (data.status == 'ok')) {
          this.props.enqueueSnackbar('Benutzer "' + showUser.fullname + '" erfolgreich gelöscht', { variant: 'success' });
          this.setState({showUser: undefined, delOpen: false}, () => { this.searchUsers(); });
        } else {
          this.props.enqueueSnackbar('Benutzer konnte nicht gelöscht werden', { variant: 'error' });
        }
  
      }).catch(err => {
        this.context.setLoading(false);
        this.props.enqueueSnackbar('Benutzer konnte nicht gelöscht werden', { variant: 'error' });
        console.log(err);
      });

    }
  }

  render() {
    const { t } = this.props;
    const { config, data, name, searchName, func, searchFunction, instr, roles, panelExpanded, showUser, showImgLoaded, addOpen, addId, addUsername, addFirstname, addSurname, addBirthday, addMail, addFunction, addSearchFunction, addPassword, addPasswordConfirm, addRoles, addInstr, fileUploads, sameUsers, notSameUsers, sameMail, fabOpen, delOpen, imgCacheId } = this.state;

    let showUserUnion: any = undefined;
    let showUserName: string = "";
    if (showUser && showUser.user_unions && showUser.user_unions.length > 0) {
      showUserUnion = showUser.user_unions[0];
    }
    if (showUser) {
      showUserName = showUser.fullname;
    }

    let sameUser: any = undefined;
    if (sameUsers && (sameUsers.length > 0)) {
      sameUser = sameUsers[0];
    }

    let hasAnyUser: boolean = false;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Mitglieder</Typography>

          
          <form action="" method="POST" onSubmit={e => { this.searchUsers(e); }}>

            <Accordion expanded={panelExpanded} disableGutters>
              <AccordionSummary
                expandIcon={null}
                onClick={e => { e.preventDefault(); }}
              >

                <Paper
                  sx={{ p: 0, display: 'flex', alignItems: 'center', width: '100%' }}
                >
                  <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={() => { this.setState({panelExpanded: !panelExpanded}); }}>
                    <Menu />
                  </IconButton>

                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={searchName.map((option: any) => option.name)}
                    autoComplete
                    inputValue={name}
                    onInputChange={(event, value) => {
                      this.setState({name: value, searchName: []}, this.searchName); 
                    }}
                    fullWidth
                    renderInput={(params) => {
                      const {InputLabelProps,InputProps,...rest} = params;
                      return (
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Name suchen"
                          {...params.InputProps} {...rest}
                        />
                      );
                    }}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton color="primary" sx={{ p: '10px' }} aria-label="search" onClick={() => { this.searchUsers(); }}>
                    <Search />
                  </IconButton>
                </Paper>

              </AccordionSummary>
              <AccordionDetails>

                <Grid container spacing={2}>

                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={searchName.map((option: any) => option.name)}
                            autoComplete
                            inputValue={name}
                            onInputChange={(event, value) => { 
                                this.setState({name: value, searchName: []}, this.searchName); 
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField label="Name" {...params} />
                            )}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={searchFunction.map((option: any) => option.function)}
                            autoComplete
                            inputValue={func}
                            onInputChange={(event, value) => { 
                                this.setState({func: value, searchFunction: []}, this.searchFunction); 
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField label="Funktion" {...params} />
                            )}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>

                        <FormControl fullWidth>
                            <InputLabel>Instrumentation</InputLabel>
                            <Select 
                                multiple 
                                value={instr} 
                                onChange={e => { this.setState({instr: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                                renderValue={(selected) => this.concatDomCboIdToNames(config.dom_instrument_parents, selected, 'instrument_parent')}
                            >
                            {config && config.dom_instrument_parents && config.dom_instrument_parents.map((icat: any, i: number) => {
                                return (
                                    <MenuItem value={icat.id} key={icat.id}>
                                        <Checkbox checked={instr.indexOf(icat.id) > -1} />
                                        <ListItemText primary={t('instrument_parent.' + icat.name)} />
                                    </MenuItem>
                                );
                            })}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item md={6} xs={12}>

                        <FormControl fullWidth>
                            <InputLabel>Berechtigungen</InputLabel>
                            <Select 
                                multiple 
                                value={roles} 
                                onChange={e => { this.setState({roles: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                                renderValue={(selected) => this.concatDomCboIdToNames(config.dom_roles, selected, 'role')}
                            >
                            {config && config.dom_roles && config.dom_roles.map((icat: any, i: number) => {
                                return (
                                    <MenuItem value={icat.id} key={icat.id}>
                                        <Checkbox checked={roles.indexOf(icat.id) > -1} />
                                        <ListItemText primary={t('role.' + icat.name)} />
                                    </MenuItem>
                                );
                            })}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} textAlign="center">
                      <Button type="submit" variant="contained" startIcon={<Search />} size="large">Suchen</Button>
                    </Grid>

                  </Grid>
              
              </AccordionDetails>
            </Accordion>

        </form>




        <Grid container spacing={2} sx={{ my: 3 }}>
          {data && data.map((parentInstr: any, i: number) => {
            let exists: boolean = false;

            parentInstr && parentInstr.dom_instruments && parentInstr.dom_instruments.forEach((instr: any) => {
              if (instr.user_union_instruments && instr.user_union_instruments.length > 0) {
                  instr.user_union_instruments.forEach((userUnionInstr: any) => {
                      if (userUnionInstr.user_union && userUnionInstr.user_union.user) {
                          exists = true;
                      }
                  });
              }
            });

            if (exists) {
                return (
                    <React.Fragment key={i}>
                        <Grid item xs={12}>
                          {parentInstr.name ?
                            (<TextDivider textAlign="left"><Typography variant="h5" color="textSecondary">{t('instrument_parent.' + parentInstr.name)}</Typography></TextDivider>)
                            : (<Divider />)
                          }
                        </Grid>

                        {parentInstr && parentInstr.dom_instruments && parentInstr.dom_instruments.map((instr: any, i2: number) => {
                            if (instr.user_union_instruments && instr.user_union_instruments.length > 0) {
                                hasAnyUser = true;
                                return (
                                    <React.Fragment key={i2}>
                                        {instr.user_union_instruments.map((userUnionInstr: any, i3: number) => {
                                          if (userUnionInstr.user_union && userUnionInstr.user_union.user) {
                                            let user: any = userUnionInstr.user_union.user;
                                            let userUnion: any = (user.user_unions && user.user_unions.length > 0) ? user.user_unions[0] : undefined;
                                            let name: string = (user.fullname);
                                            return (
                                              <Grid item xs={6} lg={4} key={i3} >
                                                <Card>
                                                  <CardActionArea onClick={() => { this.setState({showUser: user, showImgLoaded: false}); /*if (member.img) { this.showLightbox(iLightBoxImage2); }*/ }}>
                                                    {userUnion.img_thumb_mime ? (
                                                      <LazyLoad height={300} once={true} offset={100} scroll={true} resize={true}>
                                                        <CardMedia
                                                          style={{height: 300, backgroundSize: 'contain'}}
                                                          image={userUnion.img_thumb_mime ? ("api/user/download?user_union=" + userUnion.id + "&thumb=1" + (imgCacheId ? "&img_cache_id=" + imgCacheId : "")) : undefined}
                                                          title={name}
                                                        >
                                                          <Box></Box>
                                                        </CardMedia>
                                                      </LazyLoad>
                                                    ) : (
                                                      <CardMedia
                                                          style={{height: 300, backgroundSize: 'contain'}}
                                                          image=""
                                                          title={name}
                                                        >
                                                          <Person sx={{ width: '100%', height: '100%', opacity: 0.35 }} />
                                                        </CardMedia>
                                                    )}
                                                    <CardContent>
                                                      <Typography gutterBottom variant="h5" component="h2">
                                                        {name}
                                                      </Typography>
                                                      <Typography variant="body2" color="textSecondary" component="p">
                                                        {instr && instr.name && t('instrument.' + instr.name)}
                                                      </Typography>
                                                      <Typography variant="body2" color="textSecondary" component="p">
                                                        {userUnion.function}
                                                      </Typography>
                                                    </CardContent>
                                                  </CardActionArea>
                                                </Card>
                                              </Grid>
                                            );
                                          }
                                        })}
                                    </React.Fragment>
                                );
                            }
                        })}
                    </React.Fragment>
                );
            }
          })}
        </Grid>


        {!hasAnyUser && !this.context.loading && (
          <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, textAlign: 'center' }}>
            Keine Mitglieder mit dem aktuellen Suchfilter gefunden
          </Typography>
        )}

        {Role.isUserEditor() && !addOpen && !showUser && (
          <Fab color="secondary" 
            onClick={() => {
              this.setState({ showUser: undefined, addId: undefined, addFirstname: "", addSurname: "", addBirthday: null, addPassword: "", addPasswordConfirm: "", addRoles: [], addInstr: [], addMail: "", addFunction: "", addSearchFunction: [], addUsername: "", addOpen: true, fileUploads: [] }); 
            } }
            sx={{
              position: 'fixed',
              bottom: 30,
              right: 30,
            }}
          >
            <Add />
          </Fab>
        )}

        <Backdrop open={fabOpen} />

        {Role.isUserEditor() && !addOpen && showUser && (
          <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 30, right: 30, zIndex: 1351 }}>
                        
            <SpeedDial
              ariaLabel="Benutzer bearbeiten"
              sx={{ position: 'absolute', bottom: 0, right: 0 }}
              icon={<SpeedDialIcon icon={<Edit />} openIcon={<Close />} />}
              onClose={() => { setTimeout(() => { this.setState({fabOpen: false}); }, 500); }}
              onOpen={() => { this.setState({fabOpen: true}); }}
              open={fabOpen}
              FabProps={{ color: "secondary" }}
            >
                <SpeedDialAction
                  icon={<Edit />}
                  tooltipTitle="Bearbeiten"
                  tooltipOpen
                  onClick={() => { this.setState({fabOpen: false}, () => { this.editUser(showUser); }); }}
                />
                <SpeedDialAction
                  icon={<Delete />}
                  tooltipTitle="Löschen"
                  tooltipOpen
                  onClick={() => { this.setState({fabOpen: false, delOpen: true}); }}
                />
            </SpeedDial>

          </Box>
        )}

          
        <Dialog
            open={showUser ? true : false}
            onClose={() => { this.setState({showUser: undefined}); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle>{showUserName}</DialogTitle>
            <DialogContent>
                
                {showUser && (
                  <Grid container spacing={2} sx={{mt: 1}}>

                    <Grid item md={4} sm={6} xs={12}>

                      {showUserUnion.img_mime ? (
                        <ButtonBase sx={{ height: 250, width: '100%', backgroundSize: 'contain', position: 'relative' }} component='div' onClick={() => { /*this.onDayClick(currentDay);*/ }}>
                          <img 
                            src={"api/user/download?user_union=" + showUserUnion.id + (imgCacheId ? "&img_cache_id=" + imgCacheId : "")} 
                            onLoad={() => { this.setState({showImgLoaded: true}); }}
                            style={{ display: 'block', margin: '0 auto', maxHeight: '100%', maxWidth: '100%', verticalAlign: 'middle', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }} 
                          />
                          {!showImgLoaded && (
                            <Skeleton sx={{margin: "0 auto"}} variant="rectangular" width="100%" height="100%" />
                          )}
                        </ButtonBase>
                      ) : (
                        <Box sx={{ height: {xs: 0, sm: 250}, width: '100%', backgroundSize: 'contain' }}>
                          <Person sx={{ width: '100%', height: '100%', opacity: 0.35 }} />
                        </Box>
                      )}
                    
                    </Grid>

                    <Grid item md={8} sm={6} xs={12}>

                      <Grid container spacing={2}>
                    
                          {showUser.firstname && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Vorname:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{showUser.firstname}</Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {showUser.surname && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Nachname:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{showUser.surname}</Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {showUser.birthday && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Geburtsdatum:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(showUser.birthday, false)}</Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {showUser.email && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">E-Mail:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{showUser.email}</Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {showUser.username && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Benutzername:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{showUser.username}</Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {showUserUnion.function && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Funktion:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{showUserUnion.function}</Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {(showUserUnion.user_union_instruments && showUserUnion.user_union_instruments.length > 0) && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Instrumente:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>
                                    {showUserUnion.user_union_instruments.map((instr: any, i: number) => { 
                                      return (
                                        <Box key={i}>{instr && instr.dom_instrument && instr.dom_instrument.name && t('instrument.' + instr.dom_instrument.name)}</Box>
                                      ); 
                                    })}
                                  </Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {showUserUnion && (showUserUnion.permissions.length > 0) && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Berechtigungen:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>
                                    {showUserUnion.permissions && showUserUnion.permissions.map((perm: any, i: number) => {
                                      return (
                                        <Box key={i}>{perm.dom_role && t('role.' + perm.dom_role.name)}</Box>
                                      ); 
                                    })}
                                  </Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                          {(showUser.unions && showUser.unions.length > 0) && (
                            <React.Fragment>
                              <Grid item md={2} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary">Vereine:</Typography>
                              </Grid>

                              <Grid item md={4} sm={6} xs={12}>
                                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>
                                    {showUser.unions.map((union: any, i: number) => { 
                                      return (
                                        <Box key={i}>{union.name}</Box>
                                      ); 
                                    })}
                                  </Typography>
                              </Grid>
                            </React.Fragment>
                          )}

                        </Grid>

                    </Grid>

                  </Grid>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={() => { this.setState({showUser: undefined}); }}>Schließen</Button>
            </DialogActions>

        </Dialog>


        <Dialog
            open={addOpen}
            onClose={() => { this.setState({addOpen: false, addId: undefined, addUsername: "", addMail: "", addFunction: "", addSearchFunction: [], addPassword: "", addPasswordConfirm: "", addRoles: [], addInstr: [], addFirstname: "", addSurname: "", addBirthday: null}); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='lg'
        >
      
          <DialogTitle>Benutzer {addId ? "bearbeiten" : "anlegen"}</DialogTitle>
          <DialogContent>
        
            <Grid container spacing={2} sx={{mt: 1}}>

              <Grid item md={4} sm={6} xs={12}>

                <Dropzone fileInputName="user_img" displayName="Profilbild hochladen" files={fileUploads} onFilesAdded={this.setUploadFiles} />

              </Grid>

              <Grid item md={8} sm={6} xs={12}>

                <form onSubmit={e => { this.onAddClicked(e); }}>

                  <Grid container spacing={2}>

                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Vorname"
                            value={addFirstname}
                            onChange={event => { this.setState({addFirstname: event.target.value}) } }
                            inputProps={{ maxLength: 255 }}
                            autoFocus
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Nachname"
                            value={addSurname}
                            onChange={event => { this.setState({addSurname: event.target.value}) } }
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <DatePicker
                        label="Geburtsdatum"
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        renderInput={(params: any) => <TextField {...params} fullWidth />}
                        value={addBirthday}
                        onChange={(date: any, keyboardInputValue: any) => { this.setState({addBirthday: date}); }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="E-Mail"
                            type="email"
                            value={addMail}
                            onChange={event => { this.setState({addMail: event.target.value}) } }
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Benutzername"
                            value={addUsername}
                            onChange={event => { this.setState({addUsername: event.target.value}) } }
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={addSearchFunction.map((option: any) => option.function)}
                            autoComplete
                            inputValue={addFunction}
                            onInputChange={(event, value) => { 
                                this.setState({addFunction: value, addSearchFunction: []}, this.addSearchFunction); 
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField label="Funktion" {...params} />
                            )}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label={"Passwort" + (addId ? " (leer lassen um nicht zu ändern)" : "")}
                            value={addPassword}
                            onChange={event => { this.setState({addPassword: event.target.value}) } }
                            inputProps={{ maxLength: 255 }}
                            type="password"
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label={"Passwort wiederholen" + (addId ? " (leer lassen um nicht zu ändern)" : "")}
                            value={addPasswordConfirm}
                            onChange={event => { this.setState({addPasswordConfirm: event.target.value}) } }
                            inputProps={{ maxLength: 255 }}
                            type="password"
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                          <InputLabel>Instrumente</InputLabel>
                          <Select 
                              multiple 
                              value={addInstr} 
                              onChange={e => { this.setState({addInstr: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                              renderValue={(selected) => this.concatDomCboIdToNames(config.dom_instruments, selected, 'instrument')}
                          >
                          {config && config.dom_instruments && config.dom_instruments.map((icat: any, i: number) => {
                              return (
                                  <MenuItem value={icat.id} key={icat.id}>
                                      <Checkbox checked={addInstr.indexOf(icat.id) > -1} />
                                      <ListItemText primary={t('instrument.' + icat.name)} />
                                  </MenuItem>
                              );
                          })}
                          </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Berechtigungen</InputLabel>
                        <Select 
                            multiple 
                            value={addRoles} 
                            onChange={e => { this.setState({addRoles: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                            renderValue={(selected) => this.concatDomCboIdToNames(config.dom_roles, selected, 'role')}
                        >
                        {config && config.dom_roles && config.dom_roles.map((icat: any, i: number) => {
                            return (
                                <MenuItem value={icat.id} key={icat.id}>
                                    <Checkbox checked={addRoles.indexOf(icat.id) > -1} />
                                    <ListItemText primary={t('role.' + icat.name)} />
                                </MenuItem>
                            );
                        })}
                        </Select>
                    </FormControl>
                    </Grid>

                  </Grid>

                  <Box sx={{ display: 'none' }}>
                    <Button type="submit"/>
                  </Box>

                </form>

              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions>
              <Button onClick={() => { this.setState({addOpen: false, addId: undefined, addUsername: "", addMail: "", addPassword: "", addPasswordConfirm: "", addRoles: [], addInstr: [], addFirstname: "", addSurname: "", addBirthday: null}); }}>Abbrechen</Button>
              <Button onClick={() => { this.onAddClicked(); }}>{addId ? "Bearbeiten" : "Anlegen"}</Button>
          </DialogActions>

        </Dialog>

        <Dialog
            open={sameUser ? true : false}
            //onClose={() => { this.setState({sameUsers: sameUsers.slice(1)}); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle>Benutzer {sameUser && sameUser.fullname} bereits vorhanden</DialogTitle>
            <DialogContent>
              {sameUser && (
                <React.Fragment>
                  <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">Ein ähnlicher Benutzer existiert bereits bei einem anderen Verein, handelt es sich hierbei um die gleiche Person?</Typography>
                  </Grid>

                    {sameUser.firstname && (
                      <React.Fragment>
                        <Grid item md={2} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">Vorname:</Typography>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{sameUser.firstname}</Typography>
                        </Grid>
                      </React.Fragment>
                    )}

                    {sameUser.surname && (
                      <React.Fragment>
                        <Grid item md={2} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">Nachname:</Typography>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{sameUser.surname}</Typography>
                        </Grid>
                      </React.Fragment>
                    )}

                    {sameUser.birthday && (
                      <React.Fragment>
                        <Grid item md={2} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">Geburtsdatum:</Typography>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(sameUser.birthday, false)}</Typography>
                        </Grid>
                      </React.Fragment>
                    )}

                    {sameUser.email && (
                      <React.Fragment>
                        <Grid item md={2} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">E-Mail:</Typography>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{sameUser.email}</Typography>
                        </Grid>
                      </React.Fragment>
                    )}

                    {sameUser.username && (
                      <React.Fragment>
                        <Grid item md={2} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">Benutzername:</Typography>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{sameUser.username}</Typography>
                        </Grid>
                      </React.Fragment>
                    )}

                    {(sameUser.unions && sameUser.unions.length > 0) && (
                      <React.Fragment>
                        <Grid item md={2} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary">Vereine:</Typography>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>
                              {sameUser.unions.map((union: any, i: number) => { 
                                return (
                                  <Box key={i}>{union.name}</Box>
                                ); 
                              })}
                            </Typography>
                        </Grid>
                      </React.Fragment>
                    )}


                  </Grid>
                </React.Fragment>
              )}
            </DialogContent>

            <DialogActions>
              <Button onClick={() => {
                let newSameUsers: any[] = sameUsers;
                let newNotSameUsers: any[] = notSameUsers;

                newNotSameUsers.push(newSameUsers.splice(0, 1));
                this.setState({sameUsers: newSameUsers, notSameUsers: newNotSameUsers}, () => { 
                  if (!newSameUsers || newSameUsers.length == 0) {
                    if (sameMail) {
                      this.props.enqueueSnackbar("Bitte wählen Sie eine andere E-Mail-Adresse", { variant: 'error' }); 
                    } else {
                      this.onAddClicked();
                    }
                  }
                }); }}>Nein</Button>
              <Button onClick={() => { this.setState({sameUsers: [], notSameUsers: [], sameMail: false}, () => { this.onAddClicked(undefined, sameUser.id); }); }}>Ja</Button>
            </DialogActions>

        </Dialog>

        <Dialog
            open={(delOpen && showUser) ? true : false}
            onClose={() => { this.setState({delOpen: false}); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='sm'
          >
            <form onSubmit={this.onDelete}>
              <DialogTitle>Benutzer löschen</DialogTitle>
              <DialogContent>
                <Typography variant="subtitle1">Benutzer "{showUser && showUser.fullname}" wirklich löschen?</Typography>
              </DialogContent>
              <DialogActions>
                  <Button onClick={() => { this.setState({delOpen: false}); }}>Abbrechen</Button>
                  <Button type="submit">Löschen</Button>
              </DialogActions>
            </form>
        </Dialog>
        
      </Container>
    );
  }

}

export default withHocs(UserOverview);