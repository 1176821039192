import React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';


import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import FormatIndentDecrease from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncrease from '@mui/icons-material/FormatIndentIncrease';
import Save from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';


import { DndContext, DragEndEvent, DragStartEvent, DragOverlay, closestCorners } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import DragListItem from '../../components/dragListItem';
import DropList from '../../components/dropList';
import TrackOverview from '../tracks/trackOverview';
import { withHocs, WithHocs } from '../../components/hocs';

//import Cookies from 'js-cookie';


interface Props {
  children?: React.ReactNode,
}

interface State {
  data: any,
  dragging: string | number,

  choosenFolder: any,
  choosenTracks: any[],
}


class DirTracksEdit extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = {
    data: undefined as any,
    dragging: "",

    choosenFolder: undefined as any,
    choosenTracks: [] as any[],
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadDirTracks();
  }

  loadDirTracks = () => {
    const { id } = this.props.params;
    if (id) {
      this.context.setLoading(true);

      let dirData: any = { id: parseInt(id) };
      
      this.props.rest.post('dir/getFolderTracks', dirData).then(data => {
        
        this.context.setLoading(false);
        if (data) {
          this.setState({data: data.folders});
        }
        

      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
      });

    }

  }

  onAddDialogOpen = (folder: any) => {
    if (folder) {
      this.setState({choosenFolder: folder, choosenTracks: []});
    }
  }

  addTracks = (e?: React.FormEvent<HTMLFormElement>, empty?: boolean, choosenFolder?: any) => {
    e?.preventDefault();
    const { choosenTracks, data } = this.state;
    
    if (!empty) {
      choosenFolder = this.state.choosenFolder;
    }

    if (choosenFolder && (choosenFolder.id !== undefined) && (empty || (choosenTracks && (choosenTracks.length > 0)))) {
      let lastId: number = 0;
      let iFolder: number | undefined = undefined;
      data.forEach((folder: any, i: number) => {
        
        if (folder && (folder.id == choosenFolder.id)) {
          iFolder = i;
        }

        if (folder.directory_tracks) {
          folder.directory_tracks.forEach((dirTrack: any, i2: number) => {
            if (dirTrack && dirTrack.id && (dirTrack.id > lastId)) {
              lastId = dirTrack.id;
            }
          });
        }
        
      });

      if (iFolder !== undefined) {
        let lastPos: number = 0;
        if (!data[iFolder].directory_tracks) {
          data[iFolder].directory_tracks = [];
        }
        data[iFolder].directory_tracks.forEach((dirTrack: any, i2: number) => {
          lastPos = dirTrack.position;
        });

        let newPos: number = (lastPos + 1);
        let newId: number = (lastId + 1);
        if (empty) {
          data[iFolder].directory_tracks.push({id: newId, new: true, position: newPos, sub_position: undefined});
        } else {
          choosenTracks.forEach((ct: any, i2: number) => {
            if (iFolder !== undefined) {
              data[iFolder].directory_tracks.push({id: newId, new: true, position: newPos, sub_position: undefined, track: { id: ct.id, title: ct.title }});
              newId++;
              newPos++;
            }
          });
        }

        data[iFolder] = this.reorderFolder(data[iFolder]);
      }

    }

    this.setState({data: data, choosenFolder: undefined, choosenTracks: []});
  }

  onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {

    const { data } = this.state;
    const { id } = this.props.params;

    console.log(data);


    this.context.setLoading(true);
    let dirTrackData: any = { directory_id: id, folders: data };

    this.props.rest.post("dir/changeFolderTracks", dirTrackData).then(res => {

      this.context.setLoading(false);

      if (res && res.status && res.status == "ok") {
        this.props.enqueueSnackbar("Stücke erfolgreich bearbeitet", { variant: 'success' });
        this.loadDirTracks();
      } else {
        this.props.enqueueSnackbar("Stücke konnten nicht bearbeitet werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Stücke konnte nicht bearbeitet werden", { variant: 'error' });
    });

  }

  render() {
    const { data, dragging, choosenFolder, choosenTracks } = this.state;

    if (!data)
      return null;

    let draggingElement: JSX.Element | undefined = undefined;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Inhaltsverzeichnis</Typography>


          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>

            
          <DndContext collisionDetection={closestCorners} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}
            autoScroll={{
              threshold: {
                x: 0,
                y: 0.02,
              },
            }}
          >
              
            {data.map((folder: any, i: number) => {

              let dirTracks: any[] = [];
              if (folder && folder.directory_tracks) {
                dirTracks = folder.directory_tracks.filter((dirTrack: any) => {
                  return !dirTrack.del;
                });
              }

              return (
                    
                  <Paper sx={{minWidth: 420, m: 3, flexGrow: 1 }} key={i}>
                    <Typography variant="subtitle1" color="textSecondary" sx={{ m: 2, fontWeight: 'bold' }}>{folder.name}</Typography>

                    <SortableContext 
                      id={'folder-' + folder.id}
                      items={
                          dirTracks.map((dirTrack: any, i2: number) => {
                            return dirTrack.id.toString();
                          })
                      }>

                      <DropList id={'folder-' + folder.id}>
                    
                        {folder && folder.directory_tracks && folder.directory_tracks.map((dirTrack: any, i2: number) => {
                          if (dirTrack.del) {
                            return null;
                          }
                          let li: JSX.Element = (
                            <DragListItem key={dirTrack.id} id={dirTrack.id.toString()}
                              listItemProps={{
                                secondaryAction: 
                                  (<IconButton edge="end" onClick={e => { this.delDirTrack(i, i2 /*, dirTrack.id.toString()*/); }}>
                                    <Delete />
                                  </IconButton>),
                                sx: {paddingLeft: (dirTrack.sub_position ? 7 : undefined)}
                              }}
                            >
                              <ListItemIcon onClick={() => { this.shiftDirTrack(i, i2 /*, dirTrack.id.toString()*/); }}>
                                {dirTrack.sub_position ? <FormatIndentDecrease sx={{ cursor: 'pointer' }} /> : <FormatIndentIncrease sx={{ cursor: 'pointer' }} />}
                              </ListItemIcon>

                              <ListItemText
                                primary={(dirTrack.track && dirTrack.track.title) ? dirTrack.track.title : '(leer)'}
                                secondary={dirTrack.position + (dirTrack.sub_position ? ('.' + dirTrack.sub_position) : '')}
                              />
                            </DragListItem>
                          );
                          if (dragging == dirTrack.id.toString()) {
                            draggingElement = li;
                          }
                          return (li);

                        })}
                    
                      </DropList>

                    </SortableContext>

                    <List sx={{ mt: 3 }}>

                      <ListItemButton onClick={() => { this.addTracks(undefined, true, folder); }}>
                        <ListItemIcon>
                          <Add />
                        </ListItemIcon>
                        <ListItemText primary="Leer-Seite hinzufügen" />
                      </ListItemButton>

                      <ListItemButton onClick={() => { this.onAddDialogOpen(folder); }}>
                        <ListItemIcon>
                          <Add />
                        </ListItemIcon>
                        <ListItemText primary="Stück hinzufügen" />
                      </ListItemButton>

                    </List>
                      

                  </Paper>
                  );
                })}

            <DragOverlay>
              {draggingElement && React.cloneElement(draggingElement)}
            </DragOverlay>

          </DndContext>
            
          </Box>

          <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

            <ButtonGroup variant="outlined">
              <Button color="primary" startIcon={<Save />} onClick={this.onSubmit}>Stücke speichern</Button>
            </ButtonGroup>

          </Box>



          <Dialog
              fullScreen
              open={choosenFolder ? true : false}
              onClose={() => { this.setState({choosenFolder: undefined}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
          >

            <AppBar position="fixed" color="secondary">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => { this.setState({choosenFolder: undefined, choosenTracks: []}); }}
                >
                  <Close />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Stücke zu "{choosenFolder ? choosenFolder.name : ""}" hinzufügen
                </Typography>
                <IconButton 
                  autoFocus 
                  edge="start"
                  color="inherit"
                  onClick={() => { this.addTracks(); }}
                >
                  <Check />
                </IconButton>
              </Toolbar>
            </AppBar>
            
            <Box sx={{ mt: 6 }}>
              {choosenFolder && (<TrackOverview choose choosenTracks={choosenTracks} chooseFunction={(ct: any[]) => { this.setState({choosenTracks: ct}) }} />)}
            </Box>

          </Dialog>

        
        </Container>
    );
  }

  handleDragStart = (event: DragStartEvent) => {
    const { data } = this.state;
    
    this.setState({dragging: event.active.id});
  }

  handleDragEnd = (event: DragEndEvent) => {
    const { data } = this.state;

    this.setState({dragging: ""});
    
    const {active, over} = event;

    data.forEach((folder: any, i: number) => {

      if (folder.directory_tracks && (folder.directory_tracks.length > 0)) {

        let oldIndex: number = this.getIndexById(folder.directory_tracks, active.id) || 0;
        let newIndex: number = over ? (this.getIndexById(folder.directory_tracks, over.id) || 0) : data.length;

        if (oldIndex !== newIndex) {
          folder.directory_tracks = arrayMove(folder.directory_tracks, oldIndex, newIndex);

          folder.directory_tracks[newIndex].sub_position = undefined;

          folder = this.reorderFolder(folder);

        }

      }

    });
    
    this.setState({data: data});
  }

  getIndexById = (dirTracks: any, id: string | number): number | undefined => {
    const { data } = this.state;
    let res: number | undefined = undefined;

    if (dirTracks && id) {
      dirTracks.forEach((d: any, i: number) => {
        if (d.id.toString() === id) {
            res = i;
        }
      });
    }

    return res;
  }

  delDirTrack = (folderIndex: number, trackIndex: number /*,id: string*/) => {
    const { data } = this.state;

    /*data.forEach((folder: any, i: number) => {

      if (folder.directory_tracks && (folder.directory_tracks.length > 0)) {
        let reorder: boolean = false;
        let delIndex: number | undefined = this.getIndexById(folder.directory_tracks, id);

        if (delIndex !== undefined) {
          folder.directory_tracks[delIndex].del = true;
          if (folder.directory_tracks[delIndex].new) {
            folder.directory_tracks.splice(delIndex, 1);
          }

          folder = this.reorderFolder(folder);
        }

      }

    });*/

    data[folderIndex].directory_tracks[trackIndex].del = true;
    if (data[folderIndex].directory_tracks[trackIndex].new) {
      data[folderIndex].directory_tracks.splice(trackIndex, 1);
    }
    data[folderIndex] = this.reorderFolder(data[folderIndex]);

    this.setState({data: data});
  }

  shiftDirTrack = (folderIndex: number, trackIndex: number /*,id: string*/) => {
    const { data } = this.state;

    /*data.forEach((folder: any, i: number) => {

      if (folder.directory_tracks && (folder.directory_tracks.length > 0)) {
        let reorder: boolean = false;
        folder.directory_tracks.forEach((d: any, i2: number) => {

          if (d.id.toString() == id) {
            d.sub_position = (d.sub_position ? undefined : 999);
            reorder = true;
          }

        });

        if (reorder) {
          folder = this.reorderFolder(folder);
        }

      }

    });*/

    data[folderIndex].directory_tracks[trackIndex].sub_position = (data[folderIndex].directory_tracks[trackIndex].sub_position ? undefined : 999);

    data[folderIndex] = this.reorderFolder(data[folderIndex]);

    this.setState({data: data});
  }

  reorderFolder = (folder: any): any => {
    let pos: number = 1;
    let subpos: number = 1;
    folder.directory_tracks.forEach((d: any, i2: number) => {
      if (!d.del) {

        d.position = pos;
        
        if (d.sub_position) {
          d.sub_position = subpos;
          subpos++;
        }

        //if (!d.sub_position || ((i2 < (folder.directory_tracks.length-1)) && !folder.directory_tracks[i2+1].sub_position) /*|| ((i2 < (folder.directory_tracks.length-1)) && (folder.directory_tracks[i2+1].sub_position >= d.sub_position))*/) {
        //  pos++;
        //  subpos = 1;
        //} 

        if (!d.sub_position) {
          pos++;
          subpos = 1;
        } else {

          let i3 = i2 + 1;
          for (; i3 < folder.directory_tracks.length; i3++) {
            if (!folder.directory_tracks[i3].del) {

              if (!folder.directory_tracks[i3].sub_position) {
                pos++;
                subpos = 1;
              }

              break;
            }
          }

        }

      }
      
    });
    return folder;
  }

}

export default withHocs(DirTracksEdit);