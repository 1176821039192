import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const styles = {
    backdrop: {
      zIndex: 10000,
      color: '#fff',
    },
  };

const storageThemeMode = (typeof(Storage) !== "undefined" ? localStorage.getItem("theme") : undefined);

interface Props {
  children?: React.ReactNode,
}
export interface State {
  loading: boolean
  setLoading: (loading: boolean) => void,
  loggedIn: boolean,
  setLoggedIn: (loggedIn: boolean) => void,
  user: any,
  setUser: (user: any) => void,
  clientConfig: any,
  setClientConfig: (clientConfig: any) => void,
  userUnion: any,
  setUserUnion: (userUnion: any) => void,
  // union: any,
  // setUnion: (union: any) => void,
  permission: any,
  setPermission: (permission: any) => void,
  themeMode: string,
  switchThemeMode: () => void,
  version: string,
  setVersion: (version: string) => void,
  pwdChangeOpen: boolean,
  setPwdChangeOpen: (open: boolean) => void,
  mailChangeOpen: boolean,
  setMailChangeOpen: (open: boolean) => void,
  notifyChangeOpen: boolean,
  setNotifyChangeOpen: (open: boolean) => void,
  userUnionLocalStorageConfig: any,
  setUserUnionLocalStorageConfig: (userUnionLocalStorageConfig: any) => void,
  beforeInstallPrompt: any,
  setBeforeInstallPrompt: (beforeInstallPrompt: any) => void,

  /*
  sharedFiles: File[],
  setSharedFiles: (sharedFiles: File[]) => void,
  optionsMenu: any,
  setOptionsMenu: (optionsMenu: any) => void,
  optionsMenuElements: JSX.Element[],
  setOptionsMenuElements: (optionsMenuElements: JSX.Element[]) => void,
  headerTitle: string,
  setHeaderTitle: (headerTitle: string) => void,
  vorname?: string,
  setVorname: (vorname: string) => void,
  nachname?: string,
  setNachname: (nachname: string) => void,
  userId?: string,
  setUserId: (userId: string) => void,
  vorstand?: string,
  setVorstand: (vorstand: string) => void,
  version: string,
  setVersion: (version: string) => void,
  */
}


export const AppContext = React.createContext<State>({} as State);

export class AppProvider extends React.Component<Props, State> {

    state: State = {
        loading: false,
        setLoading: (loading: boolean): void => { this.setState({loading: loading}) },
        loggedIn: false,
        setLoggedIn: (loggedIn: boolean): void => { this.setState({loggedIn: loggedIn}); if (!loggedIn) { this.setState({user: undefined, userUnion: undefined, permission: undefined, userUnionLocalStorageConfig: undefined, pwdChangeOpen: false, mailChangeOpen: false, notifyChangeOpen: false}); } },
        user: undefined,
        setUser: (user: any) => { this.setState({user: user}) },
        clientConfig: undefined,
        setClientConfig: (clientConfig: any) => { this.setState({clientConfig: clientConfig}) },
        userUnion: undefined,
        setUserUnion: (userUnion: any) => { 
          this.setState({userUnion: userUnion});
          if (typeof(Storage) !== "undefined" && userUnion && userUnion.id) {
            const configJsonStr: string | null = localStorage.getItem("config_" + userUnion.id);
            if (configJsonStr) {
              this.setState({userUnionLocalStorageConfig: JSON.parse(configJsonStr)});
            }
          }
        },
        // union: undefined,
        // setUnion: (union: any) => { this.setState({union: union}) },
        permission: undefined,
        setPermission: (permission: any) => { this.setState({permission: permission}) },
        themeMode: (storageThemeMode ? storageThemeMode : 'auto' /*(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')*/),
        switchThemeMode: () => { 
          let themeMode: string = this.state.themeMode;

          if (themeMode.indexOf('auto') > -1) {
            themeMode = 'light';
          } else if (themeMode.indexOf('light') > -1) {
            themeMode = 'dark';
          } else if (themeMode.indexOf('dark') > -1) {
            themeMode = 'auto';
          }

          this.setState({themeMode: themeMode}); 
          if (typeof(Storage) !== "undefined") { 
            localStorage.setItem("theme", themeMode); 
          } 
        },
        version: "",
        setVersion: (version: string) => { this.setState({version: version}) },
        pwdChangeOpen: false,
        setPwdChangeOpen: (open: boolean) => { this.setState({pwdChangeOpen: open}) },
        mailChangeOpen: false,
        setMailChangeOpen: (open: boolean) => { this.setState({mailChangeOpen: open}) },
        notifyChangeOpen: false,
        setNotifyChangeOpen: (open: boolean) => { this.setState({notifyChangeOpen: open}) },
        userUnionLocalStorageConfig: undefined,
        setUserUnionLocalStorageConfig: (userUnionLocalStorageConfig: any) => { 
          this.setState({userUnionLocalStorageConfig: userUnionLocalStorageConfig}); 
          if (typeof(Storage) !== "undefined" && this.state.userUnion && this.state.userUnion.id) {
            if (userUnionLocalStorageConfig) {
              localStorage.setItem("config_" + this.state.userUnion.id, JSON.stringify(userUnionLocalStorageConfig)); 
            } else {
              localStorage.removeItem("config_" + this.state.userUnion.id);
            }
          } 
        },
        beforeInstallPrompt: undefined,
        setBeforeInstallPrompt: (beforeInstallPrompt: any) => { this.setState({beforeInstallPrompt: beforeInstallPrompt}) },

        /*
        sharedFiles: [],
        setSharedFiles: (sharedFiles: File[]) => { this.setState({sharedFiles: sharedFiles}) },
        optionsMenu: null,
        setOptionsMenu: (optionsMenu: any) => { this.setState({optionsMenu: optionsMenu}) },
        optionsMenuElements: [],
        setOptionsMenuElements: (optionsMenuElements: JSX.Element[]) => { this.setState({optionsMenuElements: optionsMenuElements}) },
        headerTitle: "",
        setHeaderTitle: (headerTitle: string) => { this.setState({headerTitle: headerTitle}) },
        vorname: "",
        setVorname: (vorname: string) => { this.setState({vorname: vorname}) },
        nachname: "",
        setNachname: (nachname: string) => { this.setState({nachname: nachname}) },
        userId: "",
        setUserId: (userId: string) => { this.setState({userId: userId}) },
        vorstand: "",
        setVorstand: (vorstand: string) => { this.setState({vorstand: vorstand}) },
        version: "",
        setVersion: (version: string) => { this.setState({version: version}) },
        */
    }

    constructor(props: any) {
        super(props);
    }

    render() {
      return(<AppContext.Provider value={ this.state } >
              {this.props.children}


              <Backdrop style={styles.backdrop} open={this.state.loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </AppContext.Provider>);
    }
}