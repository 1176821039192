import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

interface Props { 
    children?: React.ReactNode | string,
    textAlign?: string,
}

interface State { }

class TextDivider extends React.Component<Props, State> {

    state = { };

    constructor(props: any) {
        super(props);
    }

    render() {
        const { children, textAlign } = this.props;

        return (
            <Grid container alignItems='center'>
                <Grid item xs={textAlign === 'left' ? 2 : true}>
                    <Divider />
                </Grid>
                <Grid item>
                    <Box mx={2}>
                        {children}
                    </Box>
                </Grid>
                <Grid item xs={textAlign === 'right' ? 2 : true}>
                    <Divider />
                </Grid>
            </Grid>
        );
    }

}

export default TextDivider;