import React from 'react';
import { NavLink } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import YouTube from '@mui/icons-material/YouTube';

import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { AppContext } from '../../context';
import Role from '../../components/role';
import TextDivider from '../../components/textDivider';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';

//import Cookies from 'js-cookie';


interface Props {
  children?: React.ReactNode,
}

interface State {
  fabOpen: boolean,
  delOpen: boolean,
  data: any,
}


class Track extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = {
    fabOpen: false,
    delOpen: false,
    data: undefined as any,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadTrackConfigs();
  }

  loadTrackConfigs = () => {
    const { id } = this.props.params;
    if (id) {
      this.context.setLoading(true);

      let getData: any = { id: parseInt(id) };
      
      this.props.rest.post('track/get', getData).then(data => {

        this.setState({data: data});
        this.context.setLoading(false);
        console.log(data);

      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
      });

    }

  }

  onDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { data } = this.state;
    if (data && data.track && data.track.id) {
      let title: string | undefined = data.track.title;
      this.context.setLoading(true);

      let delData: any = { id: data.track.id };

      this.props.rest.post('track/del', delData).then(data => {

        this.context.setLoading(false);

        if (data && data.status && (data.status == 'ok')) {
          this.props.enqueueSnackbar('Stück "' + title + '" erfolgreich gelöscht', { variant: 'success' });
          this.setState({delOpen: false}, () => {
            this.props.navigate('/archive/tracks');
          });
        } else {
          this.props.enqueueSnackbar('Stück konnte nicht gelöscht werden', { variant: 'error' });
        }
  
      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
      });

    }
  }

  changeAllInstrCount = (plus: boolean) => {
    const { data } = this.state;

    if (data) {
      if (data.instr) {
        data.instr.forEach((instr: any, i: number) => {
          let count: number = instr.count || 0;
          let trackInstrId: any = (instr.track_instruments && instr.track_instruments.length > 0 && instr.track_instruments[0].id);
          if (trackInstrId) {
            if (plus) {
              count++;
            } else {
              count--;
            }
            if (count < 0) {
              count = 0;
            }
            data.instr[i].count = count;
          }
        });
      }

      if (data.add_files) {
        data.add_files.forEach((file: any, i: number) => {
          let count: number = file.count || 0;
          let trackInstrId: any = file.id;
          let isPdf: boolean = (trackInstrId && file.mime && file.mime.includes('pdf'));
          if (isPdf && trackInstrId) {
            if (plus) {
              count++;
            } else {
              count--;
            }
            if (count < 0) {
              count = 0;
            }
            file.count = count;
          }
        });
      }

      this.setState({data: data});
    }
  }


  render() {
    const { t } = this.props;
    const { fabOpen, delOpen, data } = this.state;
    const { userUnion } = this.context;
    const { id } = this.props.params;

    if (!data || !data.track)
      return null;

    let track: any = data.track;

    let ibreak: number = 0;
    if ((data.instr.length % 2) == 0) {
      ibreak = data.instr.length / 2;
    } else {
      ibreak = (data.instr.length - 1) / 2;
    }


    let genPdfGetParam: string = "";

    let genPdf: boolean = true;

    let linkStyle: SxProps = { wordBreak: 'break-word', textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '3.5em' };

    /* let csrf: string | undefined = Cookies.get('XSRF-TOKEN'); */

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Stücke</Typography>

          <Grid container spacing={3} sx={{ mb: 9 }}>

            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="h5" color="textSecondary">Titel:</Typography>
            </Grid>

            <Grid item md={9} sm={6} xs={12}>
              <Typography variant="h4" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{track.title}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Notenarchiv:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{(userUnion && userUnion.union) ? userUnion.union.name : ""}</Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Datum Digitalisierung:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(track.created_at, true)}</Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Archiv-Nr.:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{track.track_unions && track.track_unions.length > 0 && track.track_unions[0].register_number}</Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">AKM-ID:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{track.akm_id}</Typography>
            </Grid>



            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>



            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Komponist:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{track.composer}</Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Arrangement / Bearbeitung:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{track.arrangement}</Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Verlag:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{track.publisher}</Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Anmerkungen:</Typography>
            </Grid>

            <Grid item container md={4} sm={6} xs={12}>
              {track.track_comments && track.track_comments.map((comment: any, i2: number) => {
                return (
                  <Grid item xs={12} sx={{ textAlign: 'center' }} key={i2}>
                    <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{(comment && comment.user_union && comment.user_union.user && comment.user_union.user.fullname) ? (comment.user_union.user.fullname + ": ") : ""}</Typography>
                    <Typography variant="subtitle1" color="textSecondary" sx={{ textAlign: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>{comment.comment}</Typography>
                  </Grid>
                );
              })}
            </Grid>



            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>



            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Art / Kategorie:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                <FormGroup sx={{ fontWeight: 'bold', alignItems: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>
                  {track.dom_categories && track.dom_categories.map((cat: any, i2: number) => {
                    return (
                      <FormControlLabel control={<Checkbox disabled checked />} label={t('category.' + cat.name)} key={i2} />
                    );
                  })}
                </FormGroup>
              </Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Orchestergröße:</Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                <FormGroup sx={{ fontWeight: 'bold', alignItems: { xs: 'left', md: 'center' }, ml: { xs: 5, sm: 0 } }}>
                  {track.dom_orchestra_sizes && track.dom_orchestra_sizes.map((orch: any, i2: number) => {
                    return (
                      <FormControlLabel control={<Checkbox disabled checked />} label={t('orchestra_size.' + orch.name)} key={i2} />
                    );
                  })}
                </FormGroup>
              </Typography>
            </Grid>



            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>

            { /* Stimmen --------------------- */ }
          
            

            <Grid item xs={12}>
            
              {/*<form action="#/pdf/api/track/gen" method="GET" target="_blank">*/}
                
                {/* <input type="hidden" name="_token" value={csrf} /> */}
                <input type="hidden" name="track" value={track.id} />
                <input type="hidden" name="format" value="all" />

                <Grid container spacing={2} sx={{ width: '100%' }}>

                  {genPdf && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="textSecondary" sx={{ display: 'inline' }}>Alle Instrumentationen: </Typography>
                      <IconButton color="secondary" onClick={() => this.changeAllInstrCount(true)}>
                        <Add />
                      </IconButton>
                      <IconButton color="primary" onClick={() => this.changeAllInstrCount(false)}>
                        <Remove />
                      </IconButton>
                    </Grid>
                  )}

                  <Grid item container xs={12} md={6} spacing={2}>

                    {data && data.instr && data.instr.map((instr: any, i: number) => {
                      if (i < ibreak) {
                        let trackInstrId: any = (instr.track_instruments && instr.track_instruments.length > 0 && instr.track_instruments[0].id);
                        if (trackInstrId && instr.count > 0) {
                          if (genPdfGetParam) { genPdfGetParam += "&"; }
                          genPdfGetParam += "instr_" + trackInstrId + "=" + instr.count;
                        }
                        return (
                          <Grid item xs={12} container spacing={2} key={i + 10000}>

                            <Grid item xs={genPdf ? 4 : false} sm={genPdf ? 3 : false} sx={{ display: (genPdf ? 'block' : 'none') }}>
                              <NumericFormat
                                fullWidth
                                label="Anzahl"
                                value={trackInstrId ? instr.count : 0}
                                customInput={TextField}
                                onValueChange={value => { data.instr[i].count = value.floatValue; this.setState({data: data}); }}
                                disabled={!trackInstrId}
                                name={trackInstrId ? ("instr_" + trackInstrId) : undefined}
                                thousandSeparator={false}
                                allowNegative={false}
                                decimalScale={0}
                                decimalSeparator=","
                              />
                            </Grid>
                            <Grid item xs={genPdf ? 8 : 12} sm={genPdf ? 9 : 12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                              {trackInstrId ? (
                                <Link component={NavLink} to={"/pdf/" + encodeURIComponent("api/track/download?track=" + id + "&instr=" +  trackInstrId)} underline="hover" variant="subtitle1" color="primary" sx={linkStyle}>{t('instrument.' + instr.name)}</Link>
                              ) : (
                                <Typography variant="subtitle2" color="textSecondary" sx={linkStyle}>{t('instrument.' + instr.name)}</Typography>
                              )}
                            </Grid>

                          </Grid>
                        );
                      }
                    })}

                  </Grid>

                  <Grid item container xs={12} md={6} spacing={2}>

                    {data && data.instr && data.instr.map((instr: any, i: number) => {
                      if (i >= ibreak) {
                        let trackInstrId: any = (instr.track_instruments && instr.track_instruments.length > 0 && instr.track_instruments[0].id);
                        if (trackInstrId && instr.count > 0) {
                          if (genPdfGetParam) { genPdfGetParam += "&"; }
                          genPdfGetParam += "instr_" + trackInstrId + "=" + instr.count;
                        }
                        return (
                          <Grid item xs={12} container spacing={2} key={i + 10000}>

                            <Grid item xs={genPdf ? 4 : false} sm={genPdf ? 3 : false} sx={{ display: (genPdf ? 'block' : 'none') }}>
                              <NumericFormat
                                fullWidth
                                label="Anzahl"
                                value={trackInstrId ? instr.count : 0}
                                customInput={TextField}
                                onValueChange={value => { data.instr[i].count = value.floatValue; this.setState({data: data}); }}
                                disabled={!trackInstrId}
                                name={trackInstrId ? ("instr_" + trackInstrId) : undefined}
                                thousandSeparator={false}
                                allowNegative={false}
                                decimalScale={0}
                                decimalSeparator=","
                              />
                            </Grid>
                            <Grid item xs={genPdf ? 8 : 12} sm={genPdf ? 9 : 12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                              {trackInstrId ? (
                                <Link component={NavLink} to={"/pdf/" + encodeURIComponent("api/track/download?track=" + id + "&instr=" +  trackInstrId)} underline="hover" variant="subtitle1" color="primary" sx={linkStyle}>{t('instrument.' + instr.name)}</Link>
                              ) : (
                                <Typography variant="subtitle2" color="textSecondary" sx={linkStyle}>{t('instrument.' + instr.name)}</Typography>
                              )}
                            </Grid>

                          </Grid>
                        );
                      }
                    })}

                  </Grid>

                  {data && data.add_files && data.add_files.length > 0 && (
                    <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                      <TextDivider>Zusätzliche Uploads</TextDivider>
                    </Grid>
                  )}

                  {data && data.add_files && data.add_files.map((file: any, i: number) => {
                    let trackInstrId: any = file.id;
                    let isPdf: boolean = (trackInstrId && file.mime && file.mime.includes('pdf'));
                    if (isPdf && trackInstrId && file.count > 0) {
                      if (genPdfGetParam) { genPdfGetParam += "&"; }
                      genPdfGetParam += "instr_" + trackInstrId + "=" + file.count;
                    }
                    return (
                      <Grid item xs={12} md={6} container spacing={2} key={i + 30000}>

                        <Grid item xs={genPdf ? 4 : false} sm={genPdf ? 3 : false} sx={{ display: (genPdf ? 'block' : 'none') }}>
                          <NumericFormat
                            fullWidth
                            label="Anzahl"
                            value={file.count}
                            customInput={TextField}
                            onValueChange={value => { data.add_files[i].count = value.floatValue; this.setState({data: data}); }}
                            disabled={!isPdf}
                            name={trackInstrId ? ("instr_" + trackInstrId) : undefined}
                            thousandSeparator={false}
                            allowNegative={false}
                            decimalScale={0}
                            decimalSeparator=","
                          />
                        </Grid>
                        <Grid item xs={genPdf ? 8 : 12} sm={genPdf ? 9 : 12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          {isPdf ? (
                            <Link component={NavLink} to={"/pdf/" + encodeURIComponent("api/track/download?track=" + id + "&instr=" + trackInstrId)} underline="hover" variant="subtitle1" color="primary" sx={linkStyle}>{file.orig_filename}</Link>
                          ) : (
                            <Link href={"api/track/download?track=" + id + "&instr=" + trackInstrId} target="_blank" underline="hover" variant="subtitle1" color="primary" sx={linkStyle}>{file.orig_filename}</Link>
                          )}
                        </Grid>

                      </Grid>
                    );
                  })}

                </Grid>
                { /* Stimmen Ende --------------------- */ }

                <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>

                  <ButtonGroup variant="outlined">
                    {genPdf && (<Button component={NavLink} color="primary" startIcon={<LibraryBooks />} disabled={!genPdfGetParam} to={"/pdf/" + encodeURIComponent((genPdfGetParam && track && track.id) ? ("api/track/gen?track=" + track.id + "&format=both&" + genPdfGetParam) : "")}>Druckbares PDF generieren</Button>)}
                    <Button component={Link} color="secondary" startIcon={<YouTube />} href={"https://www.youtube.com/results?search_query=" + encodeURIComponent(data.track.title)} target="_blank">YouTube öffnen</Button>
                  </ButtonGroup>

                </Grid>

              {/*</form>*/}

            </Grid>

          </Grid>


          <Backdrop open={fabOpen} />

          {Role.isTrackEditor() && (

            <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 30, right: 30 }}>
              
              <SpeedDial
                ariaLabel="Stück bearbeiten"
                sx={{ position: 'absolute', bottom: 0, right: 0 }}
                icon={<SpeedDialIcon icon={<Edit />} openIcon={<Close />} />}
                onClose={() => { this.setState({fabOpen: false}); }}
                onOpen={() => { this.setState({fabOpen: true}); }}
                open={fabOpen}
                FabProps={{ color: "secondary" }}
              >
                  <SpeedDialAction
                    icon={<Edit />}
                    tooltipTitle="Bearbeiten"
                    tooltipOpen
                    onClick={() => { this.setState({fabOpen: false}, () => { this.props.navigate('/archive/tracks/edit/' + data.track.id) }); }}
                  />
                  <SpeedDialAction
                    icon={<Delete />}
                    tooltipTitle="Löschen"
                    tooltipOpen
                    onClick={() => { this.setState({fabOpen: false, delOpen: true}); }}
                  />
              </SpeedDial>
            </Box>

          )}

          <Dialog
              open={delOpen}
              onClose={() => { this.setState({delOpen: false}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='sm'
            >
              <form onSubmit={this.onDelete}>
                <DialogTitle>Stück löschen</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle1">Stück "{track.title}" wirklich löschen?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({delOpen: false}); }}>Abbrechen</Button>
                    <Button type="submit">Löschen</Button>
                </DialogActions>
              </form>
          </Dialog>
        
        </Container>
    );
  }

}

export default withHocs(Track);