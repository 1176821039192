import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Close from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { AppContext } from '../../context';
import Role from '../../components/role';
import Util from '../../components/util';
import { withHocs, WithHocs } from '../../components/hocs';
import QuestionEdit from './questionEdit';
import DialogTransition from '../../components/transition';


interface Props {
}

interface State {
    data: any,
    uuCount: number,
    resData: any,

    fabOpen: boolean,
    showEditDialog: boolean,
    showDelDialog: boolean,
    expanded: number | undefined,
}


class Question extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        data: undefined as any,
        uuCount: 0,
        resData: undefined as any,

        fabOpen: false,
        showEditDialog: false,
        showDelDialog: false,
        expanded: undefined,
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { 
        this.loadQuestion();
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
        if (this.props && this.props.params && prevProps && prevProps.params && (this.props.params.id !== prevProps.params.id)) {
            this.loadQuestion();
        }
    }

    loadQuestion = () => {
        const { id } = this.props.params;
        this.setState({data: undefined, uuCount: 0, resData: undefined, expanded: undefined});

        if (id) {
          this.context.setLoading(true);
    
          let questionData: any = { id: parseInt(id) };
          
          this.props.rest.post('question/get', questionData).then(data => {

            this.context.setLoading(false);

            if (data && data.question && data.status && data.status == "ok") {
    
                this.setState({data: data.question, uuCount: (data.uu_count ? data.uu_count : 0)});
                console.log(data);

            }
    
          }).catch(err => {
            this.context.setLoading(false);
            console.log(err);
          });
    
        }
    
    }

    onDelete = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { data } = this.state;
        if (data && data.id) {
          this.context.setLoading(true);
    
          let delData: any = { id: data.id };
    
          this.props.rest.post('question/del', delData).then(data => {
    
            this.context.setLoading(false);
    
            if (data && data.status && (data.status == 'ok')) {
              this.props.enqueueSnackbar('Umfrage erfolgreich gelöscht', { variant: 'success' });
              this.setState({showDelDialog: false}, () => {
                this.props.navigate('/members/questions', { replace: true });
              });
            } else {
              this.props.enqueueSnackbar('Umfrage konnte nicht gelöscht werden', { variant: 'error' });
            }
      
          }).catch(err => {
            this.context.setLoading(false);
            this.props.enqueueSnackbar('Umfrage konnte nicht gelöscht werden', { variant: 'error' });
            console.log(err);
          });
    
        }
    }

    onQuestionOptionClicked = (questionOptionId: number) => {
        const questionId = this.state.data && this.state.data.id;

        if (questionId) {
            this.context.setLoading(true);

            let qoData: any = { question_id: questionId, question_option_id: questionOptionId };
    
            this.props.rest.post('question/optionChange', qoData).then(data => {
        
                this.context.setLoading(false);
        
                if (data && data.status && (data.status == 'ok')) {
                    this.props.enqueueSnackbar('Umfrage-Option erfolgreich geändert', { variant: 'success' });
                    this.loadQuestion();
                } else {
                    this.props.enqueueSnackbar('Umfrage-Option konnte nicht geändert werden', { variant: 'error' });
                }
        
            }).catch(err => {
                this.context.setLoading(false);
                this.props.enqueueSnackbar('Umfrage-Option konnte nicht geändert werden', { variant: 'error' });
                console.log(err);
            });
        }
    }

    search = (e?: React.FormEvent) => {
        e?.preventDefault();

        const questionId = this.state.data && this.state.data.id;
        const { expanded } = this.state;

        if (!expanded) {
            return;
        }

        this.setState({resData: undefined});

        let statData: any = { id: questionId };

        let url: string = "";
        if (expanded == 1) {
            url = "question/searchOptions";
        } else if (expanded == 2) {
            url = "question/searchUsers";
        }

        this.context.setLoading(true);

        this.props.rest.post(url, statData).then(data => {
    
            this.context.setLoading(false);
            if (data && data.statistic && data.status && data.status == "ok") {
                this.setState({resData: data.statistic});
            } else {
                this.props.enqueueSnackbar("Umfrage-Ergebnisse konnten nicht ausgelesen werden", { variant: 'error' });
            }
    
        }).catch(err => {
            this.context.setLoading(false);
            this.props.enqueueSnackbar("Umfrage-Ergebnisse konnten nicht ausgelesen werden", { variant: 'error' });
            console.log(err);
        });
    }

    onExpandedClicked = (i: number): void => {
        const { expanded } = this.state;
        if (expanded == i) {
            this.setState({resData: undefined, expanded: undefined});
        } else {
            this.setState({resData: undefined, expanded: i}, () => {
                this.search();
            });
        }
    }

    render() {
        const { id } = this.props.params;
        const { data, resData, fabOpen, showEditDialog, showDelDialog, expanded, uuCount } = this.state;

        let qoCount: number = 0;

        if (data && data.question_options) {
            data.question_options.forEach((qo: any, i: number) => {
                if (qo && qo.count) {
                    qoCount = qoCount + (qo.count);
                }
            });
        }
        
        return (
            <Container maxWidth="lg" sx={{ pb: 1 }}>

                <Typography variant="h4" color="textSecondary" marginY={3}>Umfrage</Typography>


                <Grid container spacing={2} sx={{ pb: 2 }}>

                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="h5" color="textSecondary">Titel:</Typography>
                    </Grid>

                    <Grid item md={9} sm={6} xs={12}>
                        <Typography variant="h4" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{data && data.title}</Typography>
                    </Grid>

                    {data && (
                        <React.Fragment>
                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle1" color="textSecondary">Erstellt am:</Typography>
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(data.created_at, true)}</Typography>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
                        </React.Fragment>
                    )}

                    {data && (
                        <React.Fragment>
                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle1" color="textSecondary">Abgegebene Stimmen:</Typography>
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                                <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{qoCount + " / " + uuCount}</Typography>
                            </Grid>
                        </React.Fragment>
                    )}

                    {data && (
                        <React.Fragment>
                            <Grid item md={3} sm={6} xs={6}>
                                <Typography variant="subtitle1" color="textSecondary">Anonyme Umfrage:</Typography>
                            </Grid>

                            <Grid item md={3} sm={6} xs={6}>
                                <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 0, sm: 0 } }}>
                                    <Checkbox 
                                        checked={data.anonymous ? true : false}
                                        disabled
                                        color="primary"
                                    />
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    )}

                    {data && data.question_options && data.question_options.length > 0 && (

                        <React.Fragment>

                            <Grid item sm={3} xs={12}>
                                <Typography variant="subtitle1" color="textSecondary">Optionen:</Typography>
                            </Grid>

                            <Grid item sm={9} xs={12}>

                                <Box sx={{ ml: { xs: 5, sm: 0 } }}>

                                    <Grid container spacing={2} columns={data.question_options.length > 3 ? 3 : data.question_options.length}>
                                        {data.question_options.map((qo: any, i2: number) => {
                                            let isSelected: boolean = false;
                                            if (qo && qo.user_union_question_options && qo.user_union_question_options.length > 0) {
                                                qo.user_union_question_options.forEach((uuqo: any, i3: number) => {
                                                    if (uuqo.user_union) {
                                                        isSelected = true;
                                                    }
                                                });
                                            }
                                            return (
                                                <Grid item sm={1} xs={12} key={i2}>
                                                    <Button 
                                                        color={isSelected ? "primary" :"secondary"} 
                                                        variant={isSelected ? "contained" : "outlined"}
                                                        fullWidth
                                                        onClick={() => { this.onQuestionOptionClicked(qo.id); }}
                                                    >
                                                        {qo.name}
                                                    </Button>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>

                            </Grid>

                        </React.Fragment>

                    )}



                </Grid>

                {data && (
                    <Box sx={{ mt: 5, mb: 8 }}>

                        <Accordion expanded={expanded == 1} onChange={() => { this.onExpandedClicked(1); }}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography>Abgegebene Stimmen je Option</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                {(expanded == 1) && resData && (
                                    <Box sx={{ /*overflowY: 'scroll'*/ }}>

                                        { this.getHorizontalBarChart("Stimmen") }

                                    </Box>
                                )}

                            </AccordionDetails>
                        </Accordion>
                        {!data.anonymous && (
                            <Accordion expanded={expanded == 2} onChange={() => { this.onExpandedClicked(2); }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography>Abgegebene Stimme je Mitglied</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    {(expanded == 2) && resData && (
                                        <Box sx={{ /*overflowY: 'scroll'*/ }}>

                                            { this.getSelectedOptionsTable() }

                                        </Box>
                                    )}

                                </AccordionDetails>
                            </Accordion>
                        )}

                    </Box>
                )}


                <Backdrop open={fabOpen} />

                {Role.isEventEditor() && (
                    <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 30, right: 30 }}>
              
                        <SpeedDial
                            ariaLabel="Umfrage bearbeiten"
                            sx={{ position: 'absolute', bottom: 0, right: 0 }}
                            icon={<SpeedDialIcon icon={<Edit />} openIcon={<Close />} />}
                            onClose={() => { this.setState({fabOpen: false}); }}
                            onOpen={() => { this.setState({fabOpen: true}); }}
                            open={fabOpen}
                            FabProps={{ color: "secondary" }}
                        >
                            <SpeedDialAction
                                icon={<Edit />}
                                tooltipTitle="Bearbeiten"
                                tooltipOpen
                                onClick={() => { this.setState({fabOpen: false, showEditDialog: true}); }}
                            />
                            <SpeedDialAction
                                icon={<Delete />}
                                tooltipTitle="Löschen"
                                tooltipOpen
                                onClick={() => { this.setState({fabOpen: false, showDelDialog: true}); }}
                            />
                        </SpeedDial>
                    </Box>
                )}


                <QuestionEdit 
                    open={showEditDialog} 
                    onClose={(id: any) => { this.setState({showEditDialog: false}, () => { this.loadQuestion(); }); }} 
                    id={data && data.id}
                />

                <Dialog
                    open={showDelDialog}
                    onClose={() => { this.setState({showDelDialog: false}); }}
                    TransitionComponent={DialogTransition}
                    fullWidth={true}
                    maxWidth='sm'
                >
                    <form onSubmit={this.onDelete}>
                        <DialogTitle>Umfrage löschen</DialogTitle>
                        <DialogContent>
                        <Typography variant="subtitle1">Umfrage "{data && data.title}" wirklich löschen?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { this.setState({showDelDialog: false}); }}>Abbrechen</Button>
                            <Button type="submit">Löschen</Button>
                        </DialogActions>
                    </form>
                </Dialog>

            </Container>
        );
    }

    getHorizontalBarChart = (label: string): JSX.Element => {
        const data = this.state.resData;
    
        let qoData: number[] = [];
        let qoNames: string[] = [];
        let qoColors: string[] = [];
        let qoChartHeight: number = 60;
        
        if (data && data.length > 0) {
    
          let qoThirdStep: number = (data[0].anzahl / 3);
          
    
          data.forEach((qo: any, i: number) => {
              let count: number = (qo.count) || 0;
              qoNames = [...qoNames, (qo.name)];
              qoData = [...qoData, count];
              if (count > (2 * qoThirdStep)) {
                qoColors = [...qoColors, 'rgba(23, 245, 20, 0.2)'];
              } else if (count > qoThirdStep) {
                qoColors = [...qoColors, 'rgba(247, 189, 47, 0.2)'];
              } else {
                qoColors = [...qoColors, 'rgba(255, 99, 132, 0.2)'];
              }
          });
    
          qoChartHeight = qoChartHeight + (qoNames.length * 35);
        }
    
    
        const barData = {
          labels: qoNames,
          datasets: [
            {
              label: label,
              data: qoData,
              backgroundColor: qoColors,
              borderWidth: 0,
            },
          ],
        };
        
        const options = {
          indexAxis: 'y' as const,
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              ticks: {
                autoSkip: false,
              }
            },
            y: {
              ticks: {
                autoSkip: false,
              }
            },
          },
          // Elements options apply to all of the options unless overridden in a dataset
          // In this case, we are setting the border of each horizontal bar to be 2px wide
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: label,
            },
          },
          animation: {
            duration: 0,
          },
        };
    
        return (
          <Box sx={{width: '100%', height: qoChartHeight, maxHeight: qoChartHeight}}>
            <Bar data={barData} options={options} />
          </Box>
        );
    }

    getSelectedOptionsTable = (): JSX.Element => {
        const { data, resData } = this.state;
        const { t } = this.props;
        return (
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            {data && data.question_options && data.question_options.map((qo: any, i: number) => {
                                return (
                                    <TableCell align="center" key={i}>{qo.name}</TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {resData && resData.map((parentInstr: any, i: number) => {
                            let exists: boolean = false;

                            parentInstr && parentInstr.dom_instruments && parentInstr.dom_instruments.forEach((instr: any) => {
                                if (instr.user_union_instruments && instr.user_union_instruments.length > 0) {
                                    instr.user_union_instruments.forEach((userUnionInstr: any) => {
                                        if (userUnionInstr.user_union && userUnionInstr.user_union.user){
                                            exists = true;
                                        }
                                    });
                                }
                            });

                            if (exists) {
                                return (
                                    <React.Fragment key={i}>
                                        <TableRow>
                                            <TableCell align="left" colSpan={data.question_options.length + 1} sx={{ fontWeight: 'bold' }}>{parentInstr.name ? t('instrument_parent.' + parentInstr.name) : ""}</TableCell>
                                        </TableRow>
                                        {parentInstr && parentInstr.dom_instruments && parentInstr.dom_instruments.map((instr: any, i2: number) => {
                                            if (instr.user_union_instruments && instr.user_union_instruments.length > 0) {
                                                return (
                                                    <React.Fragment key={i2}>
                                                        {instr.user_union_instruments.map((userUnionInstr: any, i3: number) => {
                                                            if (userUnionInstr.user_union && userUnionInstr.user_union.user) {
                                                                let userUnion: any = userUnionInstr.user_union;
                                                                let user: any = userUnion.user;
                                                                return (
                                                                    <TableRow hover key={i3}>
                                                                        <TableCell align="center" component="th" sx={{ whiteSpace: 'nowrap' }}>
                                                                            {user.fullname}
                                                                        </TableCell>
                                                                        {data && data.question_options && data.question_options.map((qo: any, i: number) => {
                                                                            return (
                                                                                <TableCell align="center" key={i}>{(userUnion.user_union_question_options && (userUnion.user_union_question_options.length > 0) && (userUnion.user_union_question_options[0].question_option_id == qo.id)) ? "X" : ""}</TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            }
                                                        })}
                                                    
                                                    </React.Fragment>
                                                );
                                            }
                                        })}
                                    </React.Fragment>
                                );
                            }
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        );

    }

}

export default withHocs(Question);