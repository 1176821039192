import React from 'react';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { AppContext } from './context';
import { withHocs, WithHocs } from './components/hocs';


interface Props {
    children?: React.ReactNode,
}

interface State {
}


class LegalNotice extends React.Component<WithHocs & Props, State> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
    };

    constructor(props: any) {
        super(props);
    }


    render() {

        return (
            <React.Fragment>

                {/*<Typography variant="subtitle1" gutterBottom>
                    Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.
                </Typography>
                <br/>*/}

                <Typography variant="subtitle1" gutterBottom>
                    <strong>Betreiber des Services:</strong><br/>
                    Ing. Georg Strondl{/*<br/>
                    Zwettler Straße 245/16<br/>
                    3970 Weitra<br/>
                    Österreich*/}
                </Typography>
                <br/>
                <Typography variant="subtitle1" gutterBottom>
                    <strong>Kontaktdaten:</strong><br/>
                    Tel.: <Link href="tel:+436643545959" underline="hover" color="textSecondary">+43 664 / 35 45 959</Link><br/>
                    E-Mail: <Link href="mailto:gstrondl@gmx.at" underline="hover" color="textSecondary">gstrondl[at]gmx.at</Link>
                </Typography>
                <br/>
                {/*<Typography variant="subtitle1" gutterBottom>
                    <strong>Unternehmensgegenstand:</strong>&nbsp;Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik<br/>
                    <strong>UID-Nummer:</strong>&nbsp;ATU...<br/>
                    <strong>GLN:</strong>&nbsp;...<br/>
                    <strong>GISA:</strong>&nbsp;...<br/>
                    <strong>Firmenbuchnummer:</strong>&nbsp;...<br/>
                    <strong>Firmenbuchgericht:</strong>&nbsp;...<br/>
                    <strong>Firmensitz:</strong>&nbsp;Zwettler Straße 245/16, 3970 Weira<br/>
                    <strong>Mitglied bei:</strong>&nbsp;WKÖ, WKNÖ<br/>
                    <strong>Berufsrecht:</strong>&nbsp;Gewerbeordnung: ris.bka.gv.at<br/>
                    <strong>Aufsichtsbehörde/Gewerbebehörde:</strong>&nbsp;ECG (E-Commerce Gesetz): Bezirkshauptmannschaft<br/>
                    <strong>Berufsbezeichnung:</strong>&nbsp;Software Developer<br/>
                    <strong>Verleihungsstaat:</strong>&nbsp;Österreich<br/>
                    <strong>Geschäftsführer:</strong>&nbsp;Ing. Georg Strondl
                </Typography>
                <br/>*/}


                <Typography variant="subtitle1" gutterBottom>
                    <strong>Datenschutz:</strong>&nbsp;Eine vollständige Datenschutzerklärung finden Sie <Link component={NavLink} to="/dataprivacy" underline="hover" color="primary">hier</Link>.<br/>
                    <strong>Nutzungsbedingungen:</strong>&nbsp;Alle Nutzungsbedingungen sowie Informationen zum Urheberrecht finden Sie <Link component={NavLink} to="/terms" underline="hover" color="primary">hier</Link>.
                </Typography>

            </React.Fragment>
        );
    }

}

export default withHocs(LegalNotice);