import React from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

import FileDownload from '@mui/icons-material/FileDownload';

//import IframeWrapper from './iframeWrapper';
import { withHocs, WithHocs } from '../components/hocs';
import Util from '../components/util';
import { AppContext } from '../context';

interface WebViewerType {
    title: string,
    url: string,
    ref: React.RefObject<HTMLDivElement>,
    ready: boolean,
    instance: any,
}

interface Props {
    children?: React.ReactNode,
}

interface State {
    tabIndex: number,
    viewers: Array<WebViewerType>,
    darkMode: boolean,
}

class PdfViewer extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = { 
        tabIndex: 0,
        viewers: [],
        darkMode: false,
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setState({darkMode: Util.isDarkMode(this.context.themeMode)});
        this.initWebViewer();
    }

    componentDidUpdate(prevProps: Readonly<WithHocs & Props>, prevState: Readonly<State>, snapshot?: any): void {
        const darkMode: boolean = Util.isDarkMode(this.context.themeMode);
        
        if (this.state.darkMode !== darkMode) {
            this.setState({darkMode: darkMode}, () => {
                this.state.viewers.forEach((viewer: WebViewerType, i: number) => {
                    viewer.instance?.UI.setTheme(darkMode ? viewer.instance.UI.Theme.DARK : viewer.instance.UI.Theme.LIGHT);
                });
            });
        }
    }

    componentWillUnmount() {
    }

    initWebViewer = () => {
        const { clientConfig } = this.context;
        const { url } = this.props.params;

        // this.context.setLoading(true);
        // this.props.rest.post('track/getPdfJsExpressViewerKey').then(keyData => {
        //     this.context.setLoading(false);
        //     if (keyData && keyData.status && keyData.status == "ok") {
        //         let key: string = keyData.key;

        if (clientConfig && clientConfig.pdfjs_express_viewer_key) {
            let key: string = clientConfig.pdfjs_express_viewer_key;
            let uriArr: any[] = [];

            if (url) {
                if (url.indexOf('format=both') > -1) {
                    let a4: string = url.replace('format=both', 'format=a4');
                    let a3: string = url.replace('format=both', 'format=a3');
                    uriArr.push({title: "A4", url: a4});
                    uriArr.push({title: "A3", url: a3});
                } else {
                    uriArr.push({title: "", url: url});
                }
            }

            if (uriArr.length > 0) {
                uriArr.forEach((uri: any) => {
                    let viewerRef: React.RefObject<HTMLDivElement> = React.createRef();
                    let viewers: WebViewerType[] = this.state.viewers;
                    let index: number = (viewers.push({title: uri.title, ref: viewerRef, url: uri.url, instance: undefined, ready: false}) - 1);
                    this.setState({viewers: viewers}, () => {

                        //Print-Bug sollte mit Version 8.7.0.2 gefixed werden: https://pdfjs.community/t/express-viewer-pan-does-not-work-undefined-reading-queryselector-pagewidgetcontainer/2019/8
                        WebViewer(
                            {
                            path: 'pdfjs-express',
                            initialDoc: uri.url,
                            // licenseKey: 'W6KQO9igphbgRNWEfXis', // "*"
                            // licenseKey: '21yJR6ADH3Bgz4ZDXvCs',   // " "
                            // licenseKey: 'sd8Dh5xemq9wZpYsaSs0',   // "at"
                            // licenseKey: 'IvlFH6TbcoaV2omu7Gmp',    // "tk-bad-grosspertholz.at"
                            // licenseKey: '3guoM7VMPFQQs0yBH8f5',   // "strudi.synology.me"
                            // licenseKey: 'vR7pF5fb6cNFNOlM9TI2',  // "meine-musik.at"
                            licenseKey: key,
                            disabledElements: [       // https://pdfjs.express/documentation/ui-customization/hiding-elements
                                'ribbons',
                                'toolsHeader',
                                'contextMenuPopup',
                                'panToolButton',
                                'selectToolButton',
                                'searchButton',
                                'toggleNotesButton',
                                'languageButton',
                                'downloadButton',
                                'themeChangeButton',
                                'menuButton',
                                'moreButton',
                                'textSelectButton'
                            ],
                            //   enableAnnotations: false,
                            //   enableAzureWorkaround: false,
                            //   enableFilePicker: false,
                            //   enableOfflineMode: false,
                            //   enableRedaction: false,
                            //   enableMeasurement: false,
                            //   isReadOnly: true,
                            },
                            viewerRef.current,
                        ).then((instance: any) => {
                            const { 
                                documentViewer, 
                                annotationManager, 
                                Annotations 
                            } = instance.Core;

                            // https://pdfjs.express/documentation/ui-customization/languages-and-internationalization
                            instance.UI.setLanguage('de');

                            // https://pdfjs.express/api/UI.html#.setTheme__anchor
                            const darkMode: boolean = Util.isDarkMode(this.context.themeMode);
                            instance.UI.setTheme(darkMode ? instance.UI.Theme.DARK : instance.UI.Theme.LIGHT);

                            documentViewer.setMargin(100);

                            instance.UI.disableFeatures([
                                instance.UI.Feature.Measurement,
                                instance.UI.Feature.Ribbons,
                                instance.UI.Feature.Annotations,
                                // instance.UI.Feature.Download,
                                instance.UI.Feature.FilePicker,
                                // instance.UI.Feature.LocalStorage,
                                instance.UI.Feature.NotesPanel,
                                instance.UI.Feature.Redaction,
                                instance.UI.Feature.TextSelection,
                                instance.UI.Feature.Copy,
                                instance.UI.Feature.MultipleViewerMerging,
                                instance.UI.Feature.ThumbnailMerging,
                                instance.UI.Feature.ThumbnailReordering,
                                instance.UI.Feature.Search,
                                instance.UI.Feature.MatchSymbols,
                                instance.UI.Feature.OutlineEditing,
                                instance.UI.Feature.NotesPanelVirtualizedList,
                                instance.UI.Feature.NotesPanelLastUpdatedDate,
                                instance.UI.Feature.MultiTab,
                            ]);

                            instance.UI.setHeaderItems((header: any) => {
                                header.getHeader('default').push(
                                    {
                                        img: "icon-header-full-screen",        // https://github.com/PDFTron/webviewer-ui/tree/7.3/assets/icons
                                        type: "actionButton",
                                        element: 'fullscreenButton',
                                        title: 'Vollbildansicht',
                                        onClick: () => {
                                            // https://pdfjs.express/api/UI.html#.setHeaderItems__anchor
                                            instance.UI.toggleFullScreen();
                                        }
                                    },
                                    {
                                        img: "icon-header-download",        // https://github.com/PDFTron/webviewer-ui/tree/7.3/assets/icons
                                        type: "actionButton",
                                        element: 'downloadButton',
                                        title: 'Herunterladen',
                                        onClick: () => {
                                            // https://pdfjs.express/api/UI.html#.setHeaderItems__anchor
                                            instance.UI.downloadPdf();
                                        }
                                    },
                                    {
                                        img: "icon-header-print-line",      // https://github.com/PDFTron/webviewer-ui/tree/7.3/assets/icons
                                        type: "actionButton",
                                        element: 'printButton',
                                        title: 'Drucken',
                                        onClick: () => {
                                            // https://pdfjs.express/api/UI.html#.setHeaderItems__anchor
                                            instance.UI.print();
                                        }
                                    }
                                );
                            });

                            documentViewer.addEventListener('documentLoaded', () => {
                                console.log("documentLoaded");
                                instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous);
                                // instance.UI.setSwipeOrientation('horizontal');
                                // instance.setSwipeOrientation('horizontal');
                            });

                            instance.UI.iframeWindow.addEventListener('loaderror', (err: any) => {
                                console.error(err);
                                this.props.enqueueSnackbar("Dokument konnte nicht geladen werden", { variant: 'error' });
                            });

                            documentViewer.addEventListener('pageComplete', (pageNumber: any, canvas: any) => {
                                console.log('pageComplete');
                            });

                            let viewers: WebViewerType[] = this.state.viewers;
                            viewers[index].ready = true;
                            viewers[index].instance = instance;
                            this.setState({viewers: viewers});
                        });
                    });

                });
            }


        } else {
            this.props.enqueueSnackbar("PDF-Viewer-Konfiguration konnte nicht ausgelesen werden", { variant: 'error' });
        }
        
    }

    render() {
        const { tabIndex, viewers } = this.state;

        

        if (viewers.length > 0) {

            if (viewers.length > 1) {
                return (
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 0, flexShrink: 1 }}>
                            <Tabs 
                                value={tabIndex} 
                                onChange={
                                    (event: React.SyntheticEvent<Element, Event>, value: number) => { 
                                        this.setState({tabIndex: value}, () => {
                                            let viewer: WebViewerType = viewers[value];
                                            if (viewer.instance) {
                                                viewer.instance.UI.setLayoutMode(viewer.instance.UI.LayoutMode.Continuous);
                                            }
                                        });
                                    }} 
                                indicatorColor='primary'
                                textColor='primary'
                                variant='fullWidth'
                            >
                                {viewers.map((viewer: WebViewerType, i: number) => {
                                    return (
                                        <Tab label={viewer.title} key={i} />
                                    );
                                })}
                            </Tabs>
                        </Box>
                        {viewers.map((viewer: WebViewerType, i: number) => {
                            return (
                                <React.Fragment key={i}>
                                    <Box role='tabpanel' hidden={tabIndex != i} sx={{ flexGrow: 1, flexShrink: 1 }}>
                                        <Box ref={viewer.ref} sx={{ height: '100%', display: (viewer.ready ? 'block' : 'none') }}></Box>
                                    </Box>
                                    <Zoom in={tabIndex == i} unmountOnExit>
                                        <Fab color="secondary" href={viewer.url} target="_blank" size="medium" sx={{
                                            position: 'fixed',
                                            bottom: 20,
                                            right: 20,
                                        }}>
                                            <FileDownload />
                                        </Fab>
                                    </Zoom>
                                </React.Fragment>
                            );
                        })}
                    </Box>
                );
            } else {
                const viewer: WebViewerType = viewers[0];
                return (
                    <React.Fragment>
                        <Box ref={viewer.ref} sx={{ height: '100%', display: (viewer.ready ? 'block' : 'none') }}></Box>
                        <Fab color="secondary" href={viewer.url} target="_blank" size="medium" sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 20,
                        }}>
                            <FileDownload />
                        </Fab>
                    </React.Fragment>
                );
            }

        } else {
            return null;
        }
    }
}

export default withHocs(PdfViewer);