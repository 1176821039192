import React from 'react';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Search from '@mui/icons-material/Search';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Close from '@mui/icons-material/Close';

import { AppContext } from '../../context';
import Role from '../../components/role';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


interface Props {
    children?: React.ReactNode,
}

interface State {
    from: moment.Moment,
    to: moment.Moment,
    data: any,
    statConfig: any,
    eventTypes: number[],
    instr: number[],
    presenceType: string,
    expanded: number | undefined,
}


class StatisticOverview extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        from: moment().startOf('year'),
        to: moment().add(1, 'y').startOf('year'),
        data: undefined as any,
        statConfig: undefined as any,
        eventTypes: [] as number[],
        instr: [] as number[],
        presenceType: "",
        expanded: undefined,
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { 
        this.loadConfigs();
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
    }

    loadConfigs = () => {
        this.context.setLoading(true);

        this.setState({statConfig: undefined, expanded: undefined, eventTypes: [], instr: [], presenceType: ""});
    
        this.props.rest.post('statistic/getConfig').then(data => {
    
            this.context.setLoading(false);
            if (data && data.status && data.status == "ok") {

                let eventTypes: number[] = [];
                /*(data && data.event_types && data.event_types.length > 0 && data.event_types.forEach((et: any, i: number) => {
                    eventTypes.push(et.id);
                }));*/
                let instr: number[] = [];
                /*(data && data.dom_instrument_parents && data.dom_instrument_parents.length > 0 && data.dom_instrument_parents.forEach((dip: any, i: number) => {
                    instr.push(dip.id);
                }));*/
                let presenceType: string = ((data && data.presence_types && data.presence_types.length >=4) ? "4" : "");

                this.setState({statConfig: data, eventTypes: eventTypes, instr: instr, presenceType: presenceType});
                console.log(data);
        
            } else {
                this.props.enqueueSnackbar("Statistiken konnten nicht ausgelesen werden", { variant: 'error' });
            }
    
        }).catch(err => {
            this.context.setLoading(false);
            this.props.enqueueSnackbar("Statistiken konnten nicht ausgelesen werden", { variant: 'error' });
            console.log(err);
        });
    }

    search = (e?: React.FormEvent) => {
        e?.preventDefault();

        const { eventTypes, instr, expanded } = this.state;

        if (!expanded) {
            return;
        }

        this.setState({data: undefined});

        let from: moment.Moment = this.state.from.clone().startOf('day');
        let to: moment.Moment = this.state.to.clone().endOf('day');
        let presenceType: number | undefined = (this.state.presenceType ? parseInt(this.state.presenceType) : undefined);

        let statData: any = { from: Util.dateToIsoString(from), to: Util.dateToIsoString(to), event_types: eventTypes, presence_type: presenceType, dom_instrument_parents: instr };

        let url: string = "";
        if (expanded == 1) {
            url = "statistic/searchUsers";
        } else if (expanded == 2) {
            url = "statistic/searchEvents";
        } else if (expanded == 3) {
            url = "statistic/searchEventTypes";
        }

        this.context.setLoading(true);

        this.props.rest.post(url, statData).then(data => {
    
            this.context.setLoading(false);
            if (data && data.statistic && data.status && data.status == "ok") {
                this.setState({data: data.statistic});
            } else {
                this.props.enqueueSnackbar("Statistiken konnten nicht ausgelesen werden", { variant: 'error' });
            }
    
        }).catch(err => {
            this.context.setLoading(false);
            this.props.enqueueSnackbar("Statistiken konnten nicht ausgelesen werden", { variant: 'error' });
            console.log(err);
        });
    }

    concatDomCboIdToNames = (domEntries: any[], selItems: number[], tPrefix?: string): string => {
        const { t } = this.props;

        let names: string = "";
        let iNames: number = 0;
        domEntries.forEach((domEntry: any, i: number) => {
          if (selItems.indexOf(domEntry.id) > -1) {
            names = names + (tPrefix ? t(tPrefix + '.' + domEntry.name) : domEntry.name);
            if (iNames < (selItems.length - 1)) {
              names = names + ", ";
            }
            iNames++;
          }
        });
        return names;
    }

    onExpandedClicked = (i: number): void => {
        const { expanded } = this.state;
        if (expanded == i) {
            this.setState({data: undefined, expanded: undefined});
        } else {
            this.setState({data: undefined, expanded: i}, () => {
                this.search();
            });
        }
    }


    render() {
        const { t } = this.props;
        const { from, to, data, statConfig, eventTypes, instr, presenceType, expanded } = this.state;
        
        return (
            <Container maxWidth="lg" sx={{ pb: 1 }}>

                <Typography variant="h4" color="textSecondary" marginY={3}>Statistiken</Typography>

                <Paper sx={{ p: 2 }}>

                    <form onSubmit={this.search}>

                        <Grid container spacing={2}>

                            <Grid item md={6} xs={12}>

                                <DatePicker
                                    label="Von Beginn-Datum"
                                    inputFormat="DD.MM.YYYY"
                                    mask="__.__.____"
                                    minDate={moment().year(1950).month(0).date(1).startOf('month')}
                                    maxDate={moment().year(2999).month(11).date(1).startOf('month')}
                                    renderInput={(params: any) => <TextField {...params} variant="standard" fullWidth />}
                                    value={from}
                                    onChange={(date: any, keyboardInputValue: any) => {
                                        if (date && date.isValid()) {
                                            this.setState({from: date}, () => {
                                                this.search();
                                            });
                                        }
                                    }}
                                    
                                />

                            </Grid>
                            
                            <Grid item md={6} xs={12}>

                                <DatePicker
                                    label="Bis Ende-Datum"
                                    inputFormat="DD.MM.YYYY"
                                    mask="__.__.____"
                                    minDate={moment().year(1950).month(0).date(1).startOf('month')}
                                    maxDate={moment().year(2999).month(11).date(1).startOf('month')}
                                    renderInput={(params: any) => <TextField {...params} variant="standard" fullWidth />}
                                    value={to}
                                    onChange={(date: any, keyboardInputValue: any) => {
                                        if (date && date.isValid()) {
                                            this.setState({to: date}, () => {
                                                this.search();
                                            });
                                        }
                                    }}
                                />

                            </Grid>

                            <Grid item md={6} xs={12}>

                                <FormControl fullWidth>
                                    <InputLabel>Kategorie</InputLabel>
                                    <Select 
                                        multiple 
                                        value={eventTypes} 
                                        onChange={e => { this.setState({eventTypes: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                                        renderValue={(selected) => this.concatDomCboIdToNames(statConfig.event_types, selected, 'event_type')} 
                                        onClose={() => { this.search(); }}
                                    >
                                    {statConfig && statConfig.event_types && statConfig.event_types.map((icat: any, i: number) => {
                                        return (
                                            <MenuItem value={icat.id} key={icat.id}>
                                                <Checkbox checked={eventTypes.indexOf(icat.id) > -1} />
                                                <ListItemText primary={t('event_type.' + icat.name)} />
                                            </MenuItem>
                                        );
                                    })}
                                    </Select>
                                </FormControl>

                            </Grid>

                            <Grid item md={6} xs={12}>

                                <FormControl fullWidth>
                                    <InputLabel>Instrumentation</InputLabel>
                                    <Select 
                                        multiple 
                                        value={instr} 
                                        onChange={e => { this.setState({instr: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                                        renderValue={(selected) => this.concatDomCboIdToNames(statConfig.dom_instrument_parents, selected, 'instrument_parent')} 
                                        onClose={() => { this.search(); }}
                                    >
                                    {statConfig && statConfig.dom_instrument_parents && statConfig.dom_instrument_parents.map((icat: any, i: number) => {
                                        return (
                                            <MenuItem value={icat.id} key={icat.id}>
                                                <Checkbox checked={instr.indexOf(icat.id) > -1} />
                                                <ListItemText primary={t('instrument_parent.' + icat.name)} />
                                            </MenuItem>
                                        );
                                    })}
                                    </Select>
                                </FormControl>

                            </Grid>

                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Anwesenheit</InputLabel>
                                    <Select 
                                        value={presenceType} 
                                        onChange={e => { this.setState({presenceType: e.target.value}); }} 
                                        onClose={() => { this.search(); }}
                                    >
                                        {statConfig && statConfig.presence_types && statConfig.presence_types.map((pt: any, i: number) => {
                                            return (<MenuItem value={pt.id} key={pt.id}>{pt.pre ? "Geplant: " : (pt.post ? "Tatsächlich: " : "")} {t('presence_type.' + pt.name)}</MenuItem>);
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/*<Grid item xs={12} textAlign="center">
                                <Button type="submit" variant="contained" startIcon={<Search />} size="large">Suchen</Button>
                            </Grid>*/}
                            
                        </Grid>

                    </form>

                </Paper>

                <Box sx={{ mt: 5, mb: 5 }}>

                    <Accordion expanded={expanded == 1} onChange={() => { this.onExpandedClicked(1); }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Mitglieder</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {(expanded == 1) && data && (
                                <Box sx={{ overflowX: 'scroll' }}>

                                    { this.getVerticalBarChart("Anwesenheit") }

                                </Box>
                            )}

                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded == 2} onChange={() => { this.onExpandedClicked(2); }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Mitglieder je Veranstaltungen</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {(expanded == 2) && data && (
                                <Box sx={{ overflowX: 'scroll' }}>

                                    { this.getLineChart("Anwesenheit") }

                                </Box>
                            )}

                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded == 3} onChange={() => { this.onExpandedClicked(3); }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Veranstaltungen je Kategorie</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {(expanded == 3) && data && (
                                <Box>

                                    { this.getStatisticsTable() }

                                </Box>
                            )}
                            
                        </AccordionDetails>
                    </Accordion>

                </Box>

            </Container>
        );
    }

    getStatisticsTable = (): JSX.Element => {
        const { t } = this.props;
        const { data } = this.state;

        return (
          <TableContainer>
            <Table>
              <TableBody>
                {data && data.map((statEvent: any, i: number) => {
                  return (
                    <TableRow hover tabIndex={-1} key={i}>
                      <TableCell>{t('event_type.' + statEvent.name)}</TableCell>
                      <TableCell align="right">{statEvent.count}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
    }

    getLineChart = (label: string): JSX.Element => {
        const { data } = this.state;

        let anwEventsData: number[] = [];
        let anwEventsName: string[] = [];
        let anwEventsColor: string[] = [];
        let anwEventsChartWidth: number = 200; 
        
        if (data && data.length > 0) {
    
          let anwEventsThirdStep: number = (data[0].anzahl / 3);
          
    
          data.forEach((anwUser: any, i: number) => {
              anwEventsName = [...anwEventsName, anwUser.title];
              anwEventsData = [...anwEventsData, anwUser.count];
              if (anwUser.count > (2 * anwEventsThirdStep)) {
                anwEventsColor = [...anwEventsColor, 'rgba(23, 245, 20, 0.2)'];
              } else if (anwUser.count > anwEventsThirdStep) {
                anwEventsColor = [...anwEventsColor, 'rgba(247, 189, 47, 0.2)'];
              } else {
                anwEventsColor = [...anwEventsColor, 'rgba(255, 99, 132, 0.2)'];
              }
          });
    
          anwEventsChartWidth = anwEventsChartWidth + (anwEventsName.length * 70);
        }
    
    
        const lineData = {
          //labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          labels: anwEventsName,
          datasets: [
            {
              label: label,
              data: anwEventsData,
              fill: false,
              //backgroundColor: anwEventsColor,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgba(255, 99, 132, 0.2)',
            },
          ],
        };
        
        const options = {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              ticks: {
                autoSkip: false,
              },
            },
            y: {
              ticks: {
                autoSkip: false,
              }
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: label,
            },
          },
          animation: {
            duration: 0,
          },
        };
    
        return (
          <Box sx={{height: 450, width: anwEventsChartWidth, maxWidth: anwEventsChartWidth}}>
            <Line data={lineData} options={options} />
          </Box>
        );
    }
    
    getVerticalBarChart = (label: string): JSX.Element => {
        const { data } = this.state;
    
        let anwUsersData: number[] = [];
        let anwUsersName: string[] = [];
        let anwUsersColor: string[] = [];
        let anwUsersChartHeight: number = 60;
        
        if (data && data.length > 0) {
    
          let anwUsersThirdStep: number = (data[0].anzahl / 3);
          
    
          data.forEach((anwUserUnion: any, i: number) => {
              let anwUser: any = anwUserUnion.user;
              let count: number = (anwUserUnion.count) || 0;
              anwUsersName = [...anwUsersName, (anwUser.fullname)];
              anwUsersData = [...anwUsersData, count];
              if (count > (2 * anwUsersThirdStep)) {
                anwUsersColor = [...anwUsersColor, 'rgba(23, 245, 20, 0.2)'];
              } else if (count > anwUsersThirdStep) {
                anwUsersColor = [...anwUsersColor, 'rgba(247, 189, 47, 0.2)'];
              } else {
                anwUsersColor = [...anwUsersColor, 'rgba(255, 99, 132, 0.2)'];
              }
          });
    
          anwUsersChartHeight = anwUsersChartHeight + (anwUsersName.length * 35);
        }
    
    
        const barData = {
          labels: anwUsersName,
          datasets: [
            {
              label: label,
              data: anwUsersData,
              backgroundColor: anwUsersColor,
              borderWidth: 0,
            },
          ],
        };
        
        const options = {
          //indexAxis: 'x',
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              ticks: {
                autoSkip: false,
              }
            },
            y: {
              ticks: {
                autoSkip: false,
              }
            },
          },
          // Elements options apply to all of the options unless overridden in a dataset
          // In this case, we are setting the border of each horizontal bar to be 2px wide
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: label,
            },
          },
          animation: {
            duration: 0,
          },
        };
    
        return (
          <Box sx={{height: 450, width: anwUsersChartHeight, maxWidth: anwUsersChartHeight}}>
            <Bar data={barData} options={options} />
          </Box>
        );
    }

}

export default withHocs(StatisticOverview);