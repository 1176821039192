import React from 'react';
import { debounce } from 'lodash';
import { NumericFormat } from 'react-number-format';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import ChevronRight from '@mui/icons-material/ChevronRight';

import { AppContext } from '../../context';
import Dropzone, { FileInput } from '../../components/dropzone';
import { withHocs, WithHocs } from '../../components/hocs';


interface Props {
  children?: React.ReactNode,
}

interface State {
  step: number,

  config: any,

  edit: boolean,
  id: string,

  title: string,
  searchTitle: any[],
  archivNr: string,
  composer: string,
  searchComposer: any[],
  arrangement: string,
  searchArrangement: any[],
  publisher: string,
  searchPublisher: any[],
  comment: string,
  akmId: string,
  //length: string,

  //date: string  | undefined | null,
  
  folder: string,
  dir: string,
  folderNr: string,

  cat: number[],
  orchSize: number[],

  fileUploads: Map<string, FileInput[]>,
}


class TrackNew extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  public static MAX_LEFT_INSTR_COUNT: number = 35;

  state = { 
    step: 0,

    config: undefined as any,

    edit: false,
    id: "",

    title: "",
    searchTitle: [],
    archivNr: "",
    composer: "",
    searchComposer: [],
    arrangement: "",
    searchArrangement: [],
    publisher: "",
    searchPublisher: [],
    comment: "",
    //length: "",
    akmId: "",

    //date: null,
    
    folder: "",
    dir: "",
    folderNr: "",

    cat: [] as number[],
    orchSize: [] as number[],

    fileUploads: new Map<string, FileInput[]>(),
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadTrackConfigs();
  }

  loadTrackConfigs = () => {
    const { id } = this.props.params;
    const { t } = this.props;

    this.context.setLoading(true);

    this.props.rest.post('track/getConfig').then(config => {

      if (id) {

        let getData: any = { id: parseInt(id) };
      
        this.props.rest.post('track/get', getData).then(data => {

          let fileUploads: Map<string, FileInput[]> = new Map<string, FileInput[]>();

          if (data.instr) {
            data.instr.forEach((instr: any, i: number) => {
              let trackInstrId: any = (instr.track_instruments && instr.track_instruments.length > 0 && instr.track_instruments[0].id);
              if (trackInstrId) {
                let fileInput: FileInput = { id: trackInstrId, name: this.getDisplayName(instr), del: false, exists: true, file: undefined, previewUrl: ("api/track/download?track=" + id + "&instr=" +  trackInstrId), mime: instr.track_instruments[0].mime, uploadFailed: false };
                fileUploads.set('instr_' + instr.id, [fileInput]);
              }
            });
          }

          if (data.add_files) {
            let fileInputs: FileInput[] = [];
            data.add_files.forEach((file: any, i: number) => {
              let fileInput: FileInput = { id: file.id, name: file.orig_filename, del: false, exists: true, file: undefined, previewUrl: ("api/track/download?track=" + id + "&instr=" +  file.id), mime: file.mime, uploadFailed: false };
              fileInputs.push(fileInput);
            });
            fileUploads.set('add_files[]', fileInputs);
          }

          this.setState({
            config: config,
            id: data.track.id,
            edit: true,

            title: data.track.title || '',
            archivNr: (data.track.track_unions && data.track.track_unions.length > 0 && data.track.track_unions[0].register_number) || '',
            composer: data.track.composer || '',
            arrangement: data.track.arrangement || '',
            publisher: data.track.publisher || '',
            //comment: data.track.comment,
            akmId: data.track.akm_id || '',
            /*folder: string,
            dir: string,
            folderNr: string,*/

            cat: data.track.dom_categories && data.track.dom_categories.map((cat: any) => { return cat.id; }),
            orchSize: data.track.dom_orchestra_sizes && data.track.dom_orchestra_sizes.map((orch: any) => { return orch.id; }),

            fileUploads: fileUploads,
          });
          this.context.setLoading(false);
          console.log(data);

        }).catch(err => {
          this.context.setLoading(false);
          console.log(err);
        });

      } else {
        this.context.setLoading(false);
        this.setState({config: config});
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
    });
  }

  createTrack = (e: React.FormEvent) => {
    e.preventDefault();

    const { edit, id, title, archivNr, composer, arrangement, publisher, comment, akmId, folder, dir, folderNr, cat, orchSize } = this.state;
    
    if (!title || !title.trim()) {
      this.props.enqueueSnackbar("Ein Titel muss angegeben werden", { variant: 'error' });
      return ;
    }
    // if (!archivNr) {
    //   this.props.enqueueSnackbar("Die Archiv-Nummer muss angegeben werden", { variant: 'error' });
    //   return ;
    // }

    if (!dir && folder) {
      this.props.enqueueSnackbar("Eine Mappe kann nur in Verbindung mit einem Inhaltsverzeichnis angegeben werden", { variant: 'error' });
      return ;
    }
    if (!folder && folderNr) {
      this.props.enqueueSnackbar("Eine Mappe-Nummer kann nur in Verbindung mit einer Mappe angegeben werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);
    let trackData: any = { id: (edit ? id : undefined), title: title, register_number: archivNr, composer: composer, arrangement: arrangement, publisher: publisher, comment: comment, akm_id: akmId, dir: dir, folder: folder,  folder_position: folderNr, cat: cat, orchSize: orchSize };

    this.props.rest.post("track/new", trackData).then(data => {

      this.context.setLoading(false);

      if (data && data.id && data.status && data.status == "ok") {

        this.setState({id: data.id, step: 1});
        this.props.enqueueSnackbar("Stück erfolgreich " + (edit ? "bearbeitet" : "angelegt"), { variant: 'success' });

      } else {
        this.props.enqueueSnackbar("Stück konnte nicht " + (edit ? "bearbeitet" : "angelegt") + " werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Stück konnte nicht " + (edit ? "bearbeitet" : "angelegt") + " werden", { variant: 'error' });
    });
    
  }

  /*uploadFiles = (e: React.FormEvent) => {
    e.preventDefault();

    const { fileUploads, id } = this.state;

    if (!id) {
      this.props.enqueueSnackbar("Die Stück-ID konnte nicht gefunden werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);

    let trackData: FormData = new FormData();
    trackData.append("id", id);

    fileUploads.forEach((files: FileInput[], name: string, map: Map<string, FileInput[]>) => {
      if (name && files && files.length > 0) {
        files.forEach((file: FileInput) => {
          if (file && file.exists && file.del && file.id) {
            trackData.append('del_track_instruments[]', '' + file.id);
          } else if (file && file.file) {
            trackData.append(name, file.file);
          }
        });
      }
    });

    this.props.rest.postForm("track/upload", trackData).then(data => {

      this.context.setLoading(false);

      if (data && data.status && data.status == "ok") {

        this.props.navigate("/archive/tracks/" + id);
        this.props.enqueueSnackbar("Stimmen erfolgreich hochgeladen", { variant: 'success' });

      } else {
        this.props.enqueueSnackbar("Stimmen konnten nicht hochgeladen werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Stimmen konnten nicht hochgeladen werden", { variant: 'error' });
    }); 

  }*/

  uploadFiles = async (e: React.FormEvent) => {
    e.preventDefault();

    const { fileUploads, id } = this.state;

    if (!id) {
      this.props.enqueueSnackbar("Die Stück-ID konnte nicht gefunden werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);

    let delIds: number[] = [];
    
    let failure: boolean = false;
    for (let name of Array.from(fileUploads.keys())) {
      let files = fileUploads.get(name);
      if (files && files.length > 0) {
        for (let file of files) {
          if (file && file.exists && file.del && file.id) {
            delIds.push(file.id);
          }
        }
      } 
    }

    //Stimmen anhand der IDs loeschen
    if (delIds && delIds.length > 0) {
      let trackData: any = { track_id: id, del_track_instruments: delIds };
      try {
        let data: any = await this.props.rest.post("track/delInstr", trackData);
        if (!(data && data.status && data.status == "ok")) {
          throw new Error("Stimmen konnten nicht gelöscht werden");
        }
      } catch(err) {
        console.log(err);
        failure = true;
      }
    }


    if (failure) {
      this.context.setLoading(false);
      this.props.enqueueSnackbar("Stimmen konnten nicht gelöscht werden", { variant: 'error' });
      return ;
    }

    let pdfVersionFailure: boolean = false;
        
    for (let name of Array.from(fileUploads.keys())) {
      let files = fileUploads.get(name);
      if (files && files.length > 0) {
        for (let file of files) {
          if (file && file.file && !(file && file.exists && file.del && file.id)) {

            let trackData: FormData = new FormData();
            trackData.append("track_id", id);
            if (name && name.includes("instr_")) {
              trackData.append("dom_instrument_id", name.slice("instr_".length));
            }
            trackData.append("file", file.file);

            try {
              let data: any = await this.props.rest.postForm("track/upload", trackData);
              if (data && data.status && data.status == "ok") {

                file.exists = true;
                file.uploadFailed = false;
        
              } else if (data && data.status && data.status == "wrong_pdf_version") {
                pdfVersionFailure = true;
                throw new Error("Nur Dokumente mit einer PDF-Version <= 1.4 werden unterstützt");
              } else {
                throw new Error("Stimme konnte nicht hochgeladen werden");
              }
            } catch(err) {
              console.log(err);
              failure = true;
              file.uploadFailed = true;
            }
          }
        }
        fileUploads.set(name, files);
        this.setState({fileUploads: fileUploads});
      }
    }

    if (failure) {
      if (pdfVersionFailure) {
        this.props.enqueueSnackbar("Es können nur Stimmen mit einer PDF-Version <= 1.4 hochgeladen werden", { variant: 'error' });
      } else {
        this.props.enqueueSnackbar("Es konnten nicht alle Stimmen hochgeladen werden", { variant: 'error' });
      }
    } else {
      this.props.enqueueSnackbar("Stimmen erfolgreich hochgeladen", { variant: 'success' });
    }

    this.context.setLoading(false);

    if (!failure) {
      this.props.navigate("/archive/tracks/" + id);
    }

  }

  setUploadFiles = (fileInputName: string, files: FileInput[]): void => {
    const { fileUploads } = this.state;

    fileUploads.set(fileInputName, files);
    this.setState({fileUploads: fileUploads});
  }

  async search(uri: string, data: any) {
    return this.props.rest.post(uri, data);
  }

  searchComposer = debounce(async () => { 
    const { composer } = this.state;
    if (composer) {
      this.setState({searchComposer: 
        await this.search('track/searchComposer', {composer: composer})
      });
    }
  }, 450);

  searchArrangement = debounce(async () => { 
    const { arrangement } = this.state;
    if (arrangement) {
      this.setState({searchArrangement: 
        await this.search('track/searchArrangement', {arrangement: arrangement})
      });
    }
  }, 450);

  searchPublisher = debounce(async () => { 
    const { publisher } = this.state;
    if (publisher) {
      this.setState({searchPublisher: 
        await this.search('track/searchPublisher', {publisher: publisher})
      }) 
    }
  }, 450);

  concatDomCboIdToNames = (domEntries: any[], selItems: number[], tPrefix?: string): string => {
    const { t } = this.props;

    let names: string = "";
    let iNames: number = 0;
    domEntries.forEach((domEntry: any, i: number) => {
      if (selItems.indexOf(domEntry.id) > -1) {
        names = names + (tPrefix ? t(tPrefix + '.' + domEntry.name) : domEntry.name);
        if (iNames < (selItems.length - 1)) {
          names = names + ", ";
        }
        iNames++;
      }
    });
    return names;
  }

  getDisplayName = (instr: any): string => {
    const { t } = this.props;

    let displayName: string = t('instrument.' + instr.name);
    if (instr.filename) {
      displayName = displayName + " (" + instr.filename + ")";
    }
    return displayName;
  }

  render() {
    const { t } = this.props;
    const { step, config, edit, title, searchTitle, archivNr, composer, searchComposer, arrangement, searchArrangement, publisher, searchPublisher, comment, akmId, folder, dir, folderNr, cat, orchSize, fileUploads } = this.state;

    let mdLeftArray: any[] = [];
    let mdRightArray: any[] = [];
    if (config && config.instruments) {
      config && config.instruments.forEach((instr: any, i: number) => {

        if (i < (config.instruments.length / 2)) {
          mdLeftArray.push(instr);
        } else {
          mdRightArray.push(instr);
        }

      });
    }

    let maxMdArrayLength: number = Math.max(mdLeftArray.length, mdRightArray.length);
    let mdArray: any[] = [...Array(maxMdArrayLength)];

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Stück {edit ? 'bearbeiten' : 'anlegen'}</Typography>
        
          <Box sx={{ width: '100%' }}>

            <Stepper alternativeLabel activeStep={step}>

              <Step>
                <StepLabel>Daten eingeben</StepLabel>
              </Step>
              <Step>
                <StepLabel>Stimmen hochladen</StepLabel>
              </Step>
            
            </Stepper>

            <Paper sx={{
              width: '100%', 
              overflow: 'hidden',
              mt: 5,
              mb: 5,
              p: 3,
            }}>

              {(step == 0) && (
            
                <form action="" method="POST" onSubmit={this.createTrack}>

                  <Grid container spacing={2}>

                    <Grid item md={6} xs={12}>
                      <TextField label="Titel" fullWidth inputProps={{ maxLength: 500 }} value={title} onChange={e => { this.setState({title: e.target.value}); }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField label="Archiv-Nr." fullWidth value={archivNr} inputProps={{ maxLength: 500 }} onChange={e => { this.setState({archivNr: e.target.value}); }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={searchComposer.map((option: any) => option.composer)}
                        autoComplete
                        inputValue={composer}
                        onInputChange={(event, value) => { 
                          this.setState({composer: value, searchComposer: []}, this.searchComposer); 
                        }}
                        fullWidth
                        renderInput={(params) => {
                          const { inputProps, ...rest } = params;
                          return (<TextField label="Komponist" inputProps={{ ...inputProps, maxLength: 500 }} {...rest} />);
                        }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={searchArrangement.map((option: any) => option.arrangement)}
                        autoComplete
                        inputValue={arrangement}
                        onInputChange={(event, value) => { 
                          this.setState({arrangement: value, searchArrangement: []}, this.searchArrangement); 
                        }}
                        fullWidth
                        renderInput={(params) => {
                          const { inputProps, ...rest } = params;
                          return (<TextField label="Arrangement" inputProps={{ ...inputProps, maxLength: 500 }} {...rest} />);
                        }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={searchPublisher.map((option: any) => option.publisher)}
                        autoComplete
                        inputValue={publisher}
                        onInputChange={(event, value) => { 
                          this.setState({publisher: value, searchPublisher: []}, this.searchPublisher); 
                        }}
                        fullWidth
                        renderInput={(params) => {
                          const { inputProps, ...rest } = params;
                          return (<TextField label="Verlag" inputProps={{ ...inputProps, maxLength: 500 }} {...rest} />);
                        }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField label="Anmerkung" fullWidth value={comment} inputProps={{ maxLength: 2500 }} onChange={e => { this.setState({comment: e.target.value}); }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <NumericFormat
                        fullWidth
                        label="AKM-ID"
                        value={akmId}
                        customInput={TextField}
                        onValueChange={value => { this.setState({akmId: value.value}); }}
                        thousandSeparator={false}
                        allowNegative={false}
                        decimalScale={0}
                        decimalSeparator=","
                      />
                    </Grid>

                    {/*<Grid item md={6} xs={12}>
                      <NumericFormat
                        fullWidth
                        label="Stücklänge"
                        value={length}
                        customInput={TextField}
                        format="##:##:##"
                        onValueChange={value => { this.setState({length: value.formattedValue}); }}
                      />
                    </Grid>*/}

                    {/*<Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Digitalisierungs auswählen"
                          inputFormat="DD.MM.YYYY HH:mm"
                          mask="__.__.____ __:__"
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          value={date}
                          onChange={(date, keyboardInputValue) => { this.setState({date: date}); }}
                          ampm={false}
                          clearable
                          //error={this.state.toMsg ? true : false}
                          //helperText={this.state.toMsg}
                        />
                      </LocalizationProvider>
                    </Grid>*/}

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Inhaltsverzeichnis</InputLabel>
                        <Select value={dir} onChange={e => { this.setState({dir: e.target.value}); }} >
                          <MenuItem value="">&nbsp;</MenuItem>
                          {config && config.directories && config.directories.map((idir: any, i: number) => {
                            return (<MenuItem value={idir.id} key={idir.id}>{idir.name}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Mappe</InputLabel>
                        <Select value={folder} onChange={e => { this.setState({folder: e.target.value}); }} >
                          <MenuItem value="">&nbsp;</MenuItem>
                          {config && config.folders && config.folders.map((ifolder: any, i: number) => {
                            return (<MenuItem value={ifolder.id} key={ifolder.id}>{ifolder.name}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <NumericFormat
                        fullWidth
                        label="Mappen-Nr."
                        value={folderNr}
                        customInput={TextField}
                        onValueChange={(value) => { this.setState({folderNr: value.value}); }}
                        thousandSeparator={false}
                        allowNegative={false}
                        decimalScale={0}
                        decimalSeparator=","
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Kategorie</InputLabel>
                        <Select 
                          multiple 
                          value={cat} 
                          onChange={e => { this.setState({cat: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                          renderValue={(selected) => this.concatDomCboIdToNames(config.categories, selected, 'category')} 
                          >
                          {config && config.categories && config.categories.map((icat: any, i: number) => {
                            return (
                              <MenuItem value={icat.id} key={icat.id}>
                                <Checkbox checked={cat.indexOf(icat.id) > -1} />
                                <ListItemText primary={t('category.' + icat.name)} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Orchestergröße</InputLabel>
                        <Select 
                          multiple 
                          value={orchSize} 
                          onChange={e => { this.setState({orchSize: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                          renderValue={(selected) => this.concatDomCboIdToNames(config.orchestraSizes, selected, 'orchestra_size')} 
                          >
                          {config && config.orchestraSizes && config.orchestraSizes.map((iorchSize: any, i: number) => {
                            return (
                              <MenuItem value={iorchSize.id} key={iorchSize.id}>
                                <Checkbox checked={orchSize.indexOf(iorchSize.id) > -1} />
                                <ListItemText primary={t('orchestra_size.' + iorchSize.name)} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} textAlign="right">
                      <Button type="submit" variant="contained" endIcon={<ChevronRight />}>Weiter</Button>
                    </Grid>
                    

                  </Grid>

                </form>

              )}


              {(step == 1) && (

                  <Grid container spacing={2}>

                    {config && config.instruments && config.instruments.map((instr: any, i: number) => {
                      return (
                        <Grid item sx={{ display: { xs: 'block', md: 'none' } }} xs={12} key={i + 10000}>
                          <Dropzone fileInputName={"instr_" + instr.id} displayName={this.getDisplayName(instr)} files={fileUploads.get("instr_" + instr.id)} onFilesAdded={this.setUploadFiles} onlyPdf />
                        </Grid>
                      );
                    })}

                    {mdArray.map((un: any, i: number) => {
                      let instrLeft: any = undefined;
                      let instrRight: any = undefined;
                      if (i < mdLeftArray.length) {
                        instrLeft = mdLeftArray[i];
                      }
                      if (i < mdRightArray.length) {
                        instrRight = mdRightArray[i];
                      }

                      return (
                        <React.Fragment key={i + 20000}>
                          {instrLeft ? (
                            <Grid item sx={{ display: { xs: 'none', md: 'block' } }} xs={12} md={6}>
                              <Dropzone fileInputName={"instr_" + instrLeft.id} displayName={this.getDisplayName(instrLeft)} files={fileUploads.get("instr_" + instrLeft.id)} onFilesAdded={this.setUploadFiles} onlyPdf />
                            </Grid>
                          ) : (
                            <Grid item sx={{ display: { xs: 'none', md: 'block' } }} xs={12} md={6}>
                              &nbsp;
                            </Grid>
                          )}

                          {instrRight ? (
                            <Grid item sx={{ display: { xs: 'none', md: 'block' } }} xs={12} md={6}>
                              <Dropzone fileInputName={"instr_" + instrRight.id} displayName={this.getDisplayName(instrRight)} files={fileUploads.get("instr_" + instrRight.id)} onFilesAdded={this.setUploadFiles} onlyPdf />
                            </Grid>
                          ) : (
                            <Grid item sx={{ display: { xs: 'none', md: 'block' } }} xs={12} md={6}>
                              &nbsp;
                            </Grid>
                          )}
                        </React.Fragment>
                      );
                    })}


                    <Grid item sx={{ mt: 5 }} xs={12}>
                      <Dropzone multiple fileInputName="add_files[]" displayName="Zusätzliche Dateien hochladen" files={fileUploads.get("add_files[]")} onFilesAdded={this.setUploadFiles} />
                    </Grid>


                    <Grid item xs={12} textAlign="right">
                      <Button variant="contained" endIcon={<ChevronRight />} onClick={this.uploadFiles}>Hochladen</Button>
                    </Grid>

                  </Grid>

              )}

            </Paper>

          </Box>
        
        </Container>
    );
  }

}

export default withHocs(TrackNew);