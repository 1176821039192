import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce } from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import Article from '@mui/icons-material/Article';
import LibraryBooks from '@mui/icons-material/LibraryBooks';

import { DateTimePicker } from '@mui/x-date-pickers';

import { AppContext } from '../../context';
import Role from '../../components/role';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';

//import Cookies from 'js-cookie';


interface Props {
  children?: React.ReactNode,
}

interface State {
  fabOpen: boolean,
  delOpen: boolean,
  renameOpen: boolean,
  renameProgName: string,
  renameProgLocation: string,
  searchLocation: any[],
  renameProgramBegin: moment.Moment | undefined | null,
  renameProgramEnd: moment.Moment | undefined | null,
  renameProgramShortcut: boolean,

  data: any,

  trackConfig: any,
  folderGenOpen: boolean,
  folderGenInstr: string,
}


class Program extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = {
    fabOpen: false,
    delOpen: false,
    renameOpen: false,
    renameProgName: "",
    renameProgLocation: "",
    searchLocation: [],
    renameProgramBegin: null,
    renameProgramEnd: null,
    renameProgramShortcut: false,

    data: undefined as any,

    trackConfig: undefined as any,
    folderGenOpen: false,
    folderGenInstr: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadProg();
  }

  componentDidUpdate(prevProps: Props & WithHocs) {
    if (this.props && this.props.params && prevProps && prevProps.params && (this.props.params.id !== prevProps.params.id)) {
      this.loadProg();
    }
  }

  loadProg = () => {
    const { id } = this.props.params;
    if (id) {
      this.context.setLoading(true);

      let progData: any = { id: parseInt(id) };
      
      this.props.rest.post('program/get', progData).then(data => {

        this.setState({data: data.program});
        console.log(data);

        this.props.rest.post('track/getConfig').then(trackConfig => {

            this.context.setLoading(false);
            this.setState({trackConfig: trackConfig});
      
          }).catch(err => {
            this.context.setLoading(false);
            console.log(err);
          });

      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
      });

    }

  }

  onDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { data } = this.state;
    if (data && data.id) {
      let title: string | undefined = data.title;
      this.context.setLoading(true);

      let delData: any = { id: data.id };

      this.props.rest.post('program/del', delData).then(data => {

        this.context.setLoading(false);

        if (data && data.status && (data.status == 'ok')) {
          this.props.enqueueSnackbar('Programm "' + title + '" erfolgreich gelöscht', { variant: 'success' });
          this.setState({delOpen: false}, () => {
            this.props.navigate('/archive/programs', { replace: true });
          });
        } else {
          this.props.enqueueSnackbar('Programm konnte nicht gelöscht werden', { variant: 'error' });
        }
  
      }).catch(err => {
        this.context.setLoading(false);
        this.props.enqueueSnackbar('Programm konnte nicht gelöscht werden', { variant: 'error' });
        console.log(err);
      });

    }
  }

  onRenameClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { renameProgName, renameProgLocation, renameProgramBegin, renameProgramEnd, renameProgramShortcut, renameOpen, data } = this.state;

    if (!renameProgName || !renameProgName.trim()) {
      this.props.enqueueSnackbar("Ein Programm-Name muss angegeben werden", { variant: 'error' });
      return ;
    }

    if (!data || !data.id) {
      this.props.enqueueSnackbar("Das Programm konnte nicht ermittelt werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);
    let progData: any = { id: data.id, title: renameProgName, location: renameProgLocation, begin_date: Util.dateToIsoString(renameProgramBegin), end_date: Util.dateToIsoString(renameProgramEnd), shortcut: renameProgramShortcut };

    this.props.rest.post("program/new", progData).then(data => {

      this.context.setLoading(false);

      if (data && data.id && data.status && data.status == "ok") {

        this.props.enqueueSnackbar("Programm erfolgreich umbenannt", { variant: 'success' });
        this.setState({renameProgName: "", renameProgLocation: "", searchLocation: [], renameProgramBegin: null, renameProgramEnd: null, renameProgramShortcut: false, renameOpen: false}, () => {
            this.loadProg();
        });

      } else {
        this.props.enqueueSnackbar("Programm konnte nicht umbenannt werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Programm konnte nicht umbenannt werden", { variant: 'error' });
    });

  }

  onGenClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { data, folderGenOpen, folderGenInstr } = this.state;

    if (!folderGenInstr) {
      this.props.enqueueSnackbar("Es muss ein Instrument ausgewählt werden", { variant: 'error' });
      return ;
    }

    this.props.navigate("/pdf/" + encodeURIComponent("api/program/gen?format=both&program=" + data.id + "&instr=" + folderGenInstr));

  }

  async search(uri: string, data: any) {
    return this.props.rest.post(uri, data);
  }

  searchLocation = debounce(async () => { 
    const { renameProgLocation } = this.state;
    if (renameProgLocation) {
        this.setState({searchLocation: 
            await this.search('program/searchLocation', {location: renameProgLocation})
        });
    }
  }, 450);


  render() {
    const { t } = this.props;
    const { fabOpen, delOpen, renameOpen, renameProgName, renameProgLocation, searchLocation, renameProgramBegin, renameProgramEnd, renameProgramShortcut, data, trackConfig, folderGenOpen, folderGenInstr } = this.state;
    
    if (!data)
      return null;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Programm</Typography>

          <Grid container spacing={3} sx={{ mb: 3 }}>

            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="h5" color="textSecondary">Titel:</Typography>
            </Grid>

            <Grid item md={9} sm={6} xs={12}>
              <Typography variant="h4" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{data.title}</Typography>
            </Grid>

            {data.location && (
                <React.Fragment>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">Ort:</Typography>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                    <   Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{data.location}</Typography>
                    </Grid>
                </React.Fragment>
            )}

            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Erstellt am:</Typography>
            </Grid>

            <Grid item md={data.location ? 3 : 9} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(data.created_at, true)}</Typography>
            </Grid>

            {data.begin_date && (
                <React.Fragment>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">Beginn:</Typography>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(data.begin_date, true)}</Typography>
                    </Grid>
                </React.Fragment>
            )}

            {data.end_date && (
                <React.Fragment>
                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">Ende:</Typography>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(data.end_date, true)}</Typography>
                    </Grid>
                </React.Fragment>
            )}

            <Grid item md={3} sm={6} xs={6}>
                <Typography variant="subtitle1" color="textSecondary">Vorlage:</Typography>
            </Grid>

            <Grid item md={3} sm={6} xs={6}>
                <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 0, sm: 0 } }}>
                    <Checkbox 
                      checked={data.shortcut && true}
                      disabled
                      color="primary"
                    />
                </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>

            { /* Stuecke --------------------- */ }

          </Grid>
          
          {data.program_tracks && data.program_tracks.length > 0 && (

            <Paper sx={{
                width: '100%', 
                overflow: 'hidden',
                mt: 5,
                mb: 5,
            }}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Position</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Titel</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Mappe</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Nr.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            {data.program_tracks && data.program_tracks.map((row: any, i: number) => {
                                let dirTrack: any = (row.track && row.track.directory_tracks && row.track.directory_tracks.length > 0 && row.track.directory_tracks[0] && row.track.directory_tracks[0].folder ? row.track.directory_tracks[0] : undefined);

                                return (
                                <TableRow hover tabIndex={-1} key={i} onClick={() => { if (row.track) { this.props.navigate("/archive/tracks/" + row.track.id); } }} sx={{ textDecoration: 'none', cursor: 'pointer' }} >
                                    <TableCell align="center">{row.position}</TableCell>
                                    <TableCell align="center">{row.track ? row.track.title : (row.dom_pause_type ? t('pause_type.' + row.dom_pause_type.name) : '')}</TableCell>
                                    <TableCell align="center">{dirTrack && dirTrack.folder && dirTrack.folder.name}</TableCell>
                                    <TableCell align="center">{dirTrack && (dirTrack.position + (dirTrack.sub_position ? ("." + dirTrack.sub_position) : ""))}</TableCell>
                                </TableRow>
                                );
                            })}
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
          )}

          <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

            <ButtonGroup variant="outlined">
              <Button component={NavLink} color="primary" startIcon={<Article />} to={"/pdf/" + encodeURIComponent("api/program/download?program=" + data.id)}>PDF generieren</Button>
              <Button color="secondary" startIcon={<LibraryBooks />} onClick={() => { this.setState({folderGenOpen: true, folderGenInstr: ""}); }}>Stimmen-PDF generieren</Button>
            </ButtonGroup>

          </Box>


          <Backdrop open={fabOpen} />

          {Role.isProgramEditor() && (

            <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 30, right: 30 }}>
              
              <SpeedDial
                ariaLabel="Programm bearbeiten"
                sx={{ position: 'absolute', bottom: 0, right: 0 }}
                icon={<SpeedDialIcon icon={<Edit />} openIcon={<Close />} />}
                onClose={() => { this.setState({fabOpen: false}); }}
                onOpen={() => { this.setState({fabOpen: true}); }}
                open={fabOpen}
                FabProps={{ color: "secondary" }}
              >
                  <SpeedDialAction
                    icon={<Add />}
                    tooltipTitle="Stücke bearbeiten"
                    tooltipOpen
                    onClick={() => { this.props.navigate("/archive/programs/edit/" + data.id); }}
                  />
                  <SpeedDialAction
                    icon={<Edit />}
                    tooltipTitle="Bearbeiten"
                    tooltipOpen
                    onClick={() => { this.setState({fabOpen: false, renameOpen: true, renameProgName: (data.title || ""), renameProgLocation: (data.location || ""), searchLocation: [], renameProgramBegin: Util.isoStringToDate(data.begin_date) || null, renameProgramEnd: Util.isoStringToDate(data.end_date) || null, renameProgramShortcut: (data.shortcut && true)}); }}
                  />
                  <SpeedDialAction
                    icon={<Delete />}
                    tooltipTitle="Löschen"
                    tooltipOpen
                    onClick={() => { this.setState({fabOpen: false, delOpen: true}); }}
                  />
              </SpeedDial>
            </Box>

          )}

            <Dialog
              open={folderGenOpen}
              onClose={() => { this.setState({folderGenOpen: false, folderGenInstr: ""}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='md'
            >
              <form onSubmit={this.onGenClicked}>
                <DialogTitle>Stimmen-PDF generieren</DialogTitle>
                <DialogContent>
                  
                  <Grid container spacing={2} sx={{mt: 1}}>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                          <InputLabel>Instrument</InputLabel>
                          <Select value={folderGenInstr} onChange={e => { this.setState({folderGenInstr: e.target.value}); }} >
                              <MenuItem value="">&nbsp;</MenuItem>
                              {trackConfig && trackConfig.directories && trackConfig.instruments.map((iinstr: any, i: number) => {
                                  return (<MenuItem value={iinstr.id} key={iinstr.id}>{t('instrument.' + iinstr.name)}</MenuItem>);
                              })}
                          </Select>
                      </FormControl>
                    </Grid>

                  </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({folderGenOpen: false, folderGenInstr: ""}); }}>Abbrechen</Button>
                    <Button type="submit">Generieren</Button>
                </DialogActions>
              </form>
          </Dialog>

          <Dialog
              open={delOpen}
              onClose={() => { this.setState({delOpen: false}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='sm'
            >
              <form onSubmit={this.onDelete}>
                <DialogTitle>Programm löschen</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle1">Programm "{data.title}" wirklich löschen?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({delOpen: false}); }}>Abbrechen</Button>
                    <Button type="submit">Löschen</Button>
                </DialogActions>
              </form>
          </Dialog>


          <Dialog
              open={renameOpen}
              onClose={() => { this.setState({renameOpen: false}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='md'
            >
              <form onSubmit={this.onRenameClicked}>
                <DialogTitle>Programm bearbeiten</DialogTitle>
                <DialogContent>
                
                  <Grid container spacing={2} sx={{mt: 1}}>
                  
                    <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Titel"
                          autoFocus
                          value={renameProgName}
                          onChange={event => { this.setState({renameProgName: event.target.value}) } }
                          inputProps={{ maxLength: 255 }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                          freeSolo
                          disableClearable
                          options={searchLocation.map((option: any) => option.location)}
                          autoComplete
                          inputValue={renameProgLocation}
                          onInputChange={(event, value) => { 
                              this.setState({renameProgLocation: value, searchLocation: []}, this.searchLocation); 
                          }}
                          fullWidth
                          renderInput={(params) => {
                              const { inputProps, ...rest } = params;
                              return (<TextField label="Ort" inputProps={{ ...inputProps, maxLength: 255 }} {...rest} />);
                          }}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <DateTimePicker
                            label="Beginn"
                            inputFormat="DD.MM.YYYY HH:mm"
                            mask="__.__.____ __:__"
                            renderInput={(params: any) => <TextField {...params} fullWidth />}
                            value={renameProgramBegin}
                            onChange={(date: any, keyboardInputValue: any) => { this.setState({renameProgramBegin: date}); }}
                            ampm={false}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <DateTimePicker
                            label="Ende"
                            inputFormat="DD.MM.YYYY HH:mm"
                            mask="__.__.____ __:__"
                            renderInput={(params: any) => <TextField {...params} fullWidth />}
                            value={renameProgramEnd}
                            onChange={(date: any, keyboardInputValue: any) => { this.setState({renameProgramEnd: date}); }}
                            ampm={false}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <FormControlLabel
                        control={
                            <Checkbox 
                                checked={renameProgramShortcut}
                                onChange={evt => { this.setState({renameProgramShortcut: evt.target.checked}) }}
                                color="primary"
                            />
                        }
                        label="Verknüpfung"
                      />
                    </Grid>

                  </Grid>
                

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({renameOpen: false, renameProgName: "", renameProgLocation: "", searchLocation: [], renameProgramBegin: null, renameProgramEnd: null, renameProgramShortcut: false}); }}>Abbrechen</Button>
                    <Button type="submit">Bearbeiten</Button>
                </DialogActions>

              </form>
          </Dialog>
        
        </Container>
    );
  }

}

export default withHocs(Program);