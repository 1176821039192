import React from 'react';

let cache: Map<string, any> = new Map<string, any>();

export interface CacheInterface {
    put(toCache: any, cacheName?: string): void,
    get(cacheName?: string): any,
    has(cacheName?: string): boolean,
}

export interface WithCache {
    cache: CacheInterface
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Diff<T, K> = Omit<T, keyof K>;

type WithoutCacheProps<T> = Diff<T, WithCache>;

const withCache = <P extends object>(
    componentName: string,
    WrappedComponent: React.ComponentType<P>
) => {
    class Cache extends React.Component<WithoutCacheProps<P>> {

        static displayName = "Cache";

        constructor(props: any) {
            super(props);
        }

        render() {
            return (
                <WrappedComponent cache={this} {...this.props as P} />
            );
        }

        public get(cacheName?: string): any {
            let name: string = (cacheName ? cacheName : componentName);
            if (cache.has(name)) {
                return cache.get(name);
            } else {
                return undefined;
            }
        }

        public put(toCache: any, cacheName?: string): void {
            let name: string = (cacheName ? cacheName : componentName);
            if (!toCache) {
                cache.delete(name);
            } else {
                cache.set(name, toCache);
            }
        }

        public has(cacheName?: string): boolean {
            let name: string = (cacheName ? cacheName : componentName);
            return cache.has(name);
        }

    }
    return Cache;
};

export {cache};
export default withCache;