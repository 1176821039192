import React from 'react';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';

import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';


interface TRow {
  id: number,
  archivNr?: number,
  title: string,
  composer?: string,
  arrangement?: string,
  publisher?: string,
  cat?: string,
  orchSize?: string,
}


interface Props {
  children?: React.ReactNode,
}

interface State {
  tableData: any,
  page: number,
  rowsPerPage: number,
  showAddDialog: boolean,
  addDirName: string,
}


class DirOverview extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = { 
    tableData: undefined as any,
    page: 0,
    rowsPerPage: 25,
    showAddDialog: false,
    addDirName: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadDirs(true);
  }

  loadDirs = (fresh: boolean, e?: React.FormEvent) => {
    e?.preventDefault();

    const { rowsPerPage } = this.state;

    let page: number = (fresh ? 0 : this.state.page);
    if (fresh) {
      this.setState({tableData: undefined, page: page});
    }

    this.context.setLoading(true);
    let dirData: any = { page: (page + 1), rowsPerPage: rowsPerPage };

    this.props.rest.post("dir/search", dirData).then(data => {

      this.context.setLoading(false);

      if (data && data.dirs && data.status && data.status == "ok") {

        this.setState({tableData: data.dirs});
        console.log(data);

      } else {
        this.props.enqueueSnackbar("Inhaltsverzeichnisse konnten nicht gesucht werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Inhaltsverzeichnisse konnten nicht gesucht werden", { variant: 'error' });
    });

  }

  onAddClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { addDirName } = this.state;

    if (!addDirName || !addDirName.trim()) {
      this.props.enqueueSnackbar("Ein Inhaltsverzeichnis-Name muss angegeben werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);
    let dirData: any = { name: addDirName };

    this.props.rest.post("dir/new", dirData).then(data => {

      this.context.setLoading(false);

      if (data && data.id && data.status && data.status == "ok") {

        this.props.enqueueSnackbar("Inhaltsverzeichnis erfolgreich angelegt", { variant: 'success' });
        this.setState({addDirName: "", showAddDialog: false}, () => {
          this.props.navigate("/archive/dirs/" + data.id);
        });

      } else if (data && data.status && data.status == "name_already_used") {
        this.props.enqueueSnackbar("Inhaltsverzeichnis-Name wurde bereits verwendet", { variant: 'error' });
      } else {
        this.props.enqueueSnackbar("Inhaltsverzeichnis konnte nicht angelegt werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Inhaltsverzeichnis konnte nicht angelegt werden", { variant: 'error' });
    });

  }

  render() {
    const { tableData, page, rowsPerPage, showAddDialog, addDirName } = this.state;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Inhaltsverzeichnisse</Typography>

          {tableData && tableData.data && tableData.data.length > 0 ? (

            <Paper sx={{
              width: '100%', 
              overflow: 'hidden',
              mt: 5,
              mb: 5,
            }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Name</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Erstellt am</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                      {tableData && tableData.data && tableData.data.map((row: any, i: number) => {
                        return (
                          <TableRow hover tabIndex={-1} key={i} onClick={() => { this.props.navigate("/archive/dirs/" + row.id) }} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{Util.isoDateStringToString(row.created_at, true)}</TableCell>
                          </TableRow>
                        );
                      })}

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData && tableData.total ? tableData.total : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event: React.MouseEvent | null, page: number) => { this.setState({page: page}, () => this.loadDirs(false)); }}
                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => { if (event?.target?.value) { this.setState({rowsPerPage: parseInt(event.target.value)}, () => this.loadDirs(false)); } }}
              />

            </Paper>

          ) : (!this.context.loading && (
                <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, textAlign: 'center' }}>
                    Keine Inhaltsverzeichnisse vorhanden
                </Typography>
              )
          )}


          {Role.isDirectoryEditor() && (
            <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

              <ButtonGroup variant="outlined">
                <Button color="primary" startIcon={<Edit />} component={NavLink} to="/archive/dirs/folder">Mappen bearbeiten</Button>
              </ButtonGroup>

            </Box>
          )}










          {Role.isDirectoryEditor() && (
            <Fab color="secondary" onClick={() => { this.setState({showAddDialog: true}); }} sx={{
              position: 'fixed',
              bottom: 30,
              right: 30,
            }}>
              <Add />
            </Fab>
          )}


            <Dialog
                open={showAddDialog}
                onClose={() => { this.setState({showAddDialog: false}); }}
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='sm'
            >
              <form onSubmit={this.onAddClicked}>
                <DialogTitle>Inhaltsverzeichnis hinzufügen</DialogTitle>
                <DialogContent>
                  
                  <Grid container spacing={2} sx={{mt: 1}}>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Inhaltsverzeichnis"
                        autoFocus
                        value={addDirName}
                        onChange={event => { this.setState({addDirName: event.target.value}) } }
                    />
                    </Grid>

                  </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({showAddDialog: false, addDirName: ""}); }}>Abbrechen</Button>
                    <Button type="submit">Anlegen</Button>
                </DialogActions>
              </form>
            </Dialog>
        
        </Container>
    );
  }

}

export default withHocs(DirOverview);