import React from 'react';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';

import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness2 from '@mui/icons-material/Brightness2';
import BrightnessAuto from '@mui/icons-material/BrightnessAuto';

import { AppContext } from './context';
import { withHocs, WithHocs } from './components/hocs';
import DialogTransition from './components/transition';
import Util from './components/util';


//import '@pwabuilder/pwainstall';


interface State {
    id: string,
    password: string,
    passwordConfirm: string,
    mailVerified: boolean,
  }

interface Props {
    children?: React.ReactNode,
}

class PwdReset extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: State = { 
        id: "",
        password: "",
        passwordConfirm: "",
        mailVerified: false,
    };
  
    constructor(props: any) {
      super(props);
    }
    
    componentDidMount() {
        this.confirmMail();
    }

    confirmMail = () => {
        const params = new URLSearchParams(window.location.search);

        const id = params.get('id');

        if (id) {

            this.setState({id: id});

            let mailData: any = {id: id};

            this.context.setLoading(true);

            this.props.rest.post('user/mailConfirm', mailData).then(data => {
                this.context.setLoading(false);

                if (data && data.status) {
                    if (data.status == "already_confirmed") {
                        this.setState({mailVerified: true});
                    } else if (data.status == "ok") {
                        this.setState({mailVerified: true});
                        this.props.enqueueSnackbar("Ihre E-Mail-Adresse wurde bestätigt.", { variant: 'success' });
                    }
                } else {
                    this.props.enqueueSnackbar("Ihre E-Mail-Adresse konnte nicht bestätigt werden.", { variant: 'error' });
                }
                
            }).catch(err => {
                this.props.enqueueSnackbar("Ihre E-Mail-Adresse konnte nicht bestätigt werden.", { variant: 'error' });
                this.context.setLoading(false);
            });

        } else {
            this.props.enqueueSnackbar("Ihre E-Mail-Adresse konnte nicht bestätigt werden.", { variant: 'error' });
        }
    }


    onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        const { id, password, passwordConfirm } = this.state;

        if (!passwordConfirm || !passwordConfirm.trim()) {
            this.props.enqueueSnackbar("Ein Passwort muss angegeben werden", { variant: 'error' });
            return ;
        }

        if ((password && password.trim()) && (password !== passwordConfirm)) {
            this.props.enqueueSnackbar("Das Passwort muss korrekt wiederholt werden", { variant: 'error' });
            return ;
        }

        let pwdData: any = {id: id, password: password};

        this.context.setLoading(true);

        this.props.rest.post('user/pwdChange', pwdData).then(data => {
            this.context.setLoading(false);

            if (data && data.status && (data.status == "ok")) {
                this.props.enqueueSnackbar("Ihr Passwort wurde geändert.", { variant: 'success' });
                this.setState({mailVerified: true, password: "", passwordConfirm: ""}, () => {
                    this.props.navigate("/login", { replace: true });
                });
            } else {
                this.props.enqueueSnackbar("Ihr Passwort konnte nicht geändert werden.", { variant: 'error' });
            }
            
        }).catch(err => {
            this.props.enqueueSnackbar("Ihr Passwort konnte nicht geändert werden.", { variant: 'error' });
            this.context.setLoading(false);
        });
        
    }
  
    render() {
      const { password, passwordConfirm, mailVerified } = this.state;
      const { themeMode } = this.context;

      return (
            <React.Fragment>
                <form noValidate onSubmit={this.onSubmit} style={{
                    width: '100%',
                    marginTop: 1,
                }}>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="password"
                        label="Passwort"
                        type="password"
                        value={this.state.password}
                        onChange={event => { this.setState({password: event.target.value}) } }
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        name="passwordConfirm"
                        label="Passwort wiederholen"
                        type="password"
                        value={this.state.passwordConfirm}
                        onChange={event => { this.setState({passwordConfirm: event.target.value}) } }
                        sx={{ mb: 6 }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!mailVerified}
                    >
                        Speichern
                    </Button>

                </form>
            </React.Fragment>
      );
    }
  }

export default withHocs(PwdReset);