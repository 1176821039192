import React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import FormatLineSpacing from '@mui/icons-material/FormatLineSpacing';

import {CSS} from '@dnd-kit/utilities';
import {useSortable} from '@dnd-kit/sortable';

export default function DragListItem(props: any) {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({id: props.id, data: props.data});

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    touchAction: (isDragging ? 'none' : undefined),
  };

  return (
      <ListItem 
        ref={setNodeRef}
        style={style}
        {...attributes}

        {...props.listItemProps}
      >
        <ListItemIcon {...listeners}>
          <FormatLineSpacing sx={{ cursor: 'pointer' }} />
        </ListItemIcon>

        {props.children}
    
      </ListItem>
  );
}