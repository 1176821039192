import React from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams, Location } from 'react-router';


export interface WithRouter {
    navigate: NavigateFunction,
    params: any,
    location: Location
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Diff<T, K> = Omit<T, keyof K>;

type WithoutRouterProps<T> = Diff<T, WithRouter>;

const withRouter = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) => {
    return (props: WithoutRouterProps<P>) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return (
            <WrappedComponent navigate={navigate} params={params} location={location} {...props as P} />
        );
    }
};

export default withRouter;