import React from 'react';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';

import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import QuestionEdit from './questionEdit';


interface Props {
  children?: React.ReactNode,
}

interface State {
  tableData: any,
  page: number,
  rowsPerPage: number,
  showAddDialog: boolean,
}


class QuestionOverview extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = { 
    tableData: undefined as any,
    page: 0,
    rowsPerPage: 25,
    showAddDialog: false,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadQuestions(true);
  }

  loadQuestions = (fresh: boolean, e?: React.FormEvent) => {
    e?.preventDefault();

    const { rowsPerPage } = this.state;

    let page: number = (fresh ? 0 : this.state.page);
    if (fresh) {
      this.setState({tableData: undefined, page: page});
    }

    this.context.setLoading(true);
    let questionData: any = { page: (page + 1), rowsPerPage: rowsPerPage };

    this.props.rest.post("question/search", questionData).then(data => {

      this.context.setLoading(false);

      if (data && data.questions && data.status && data.status == "ok") {

        this.setState({tableData: data.questions});
        console.log(data);

      } else {
        this.props.enqueueSnackbar("Umfragen konnten nicht gesucht werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Umfragen konnten nicht gesucht werden", { variant: 'error' });
    });

  }

  render() {
    const { tableData, page, rowsPerPage, showAddDialog } = this.state;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Umfragen</Typography>

          {tableData && tableData.data && tableData.data.length > 0 ? (

            <Paper sx={{
              width: '100%', 
              overflow: 'hidden',
              mt: 5,
              mb: 5,
            }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Titel</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Erstellt am</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                      {tableData && tableData.data && tableData.data.map((row: any, i: number) => {
                        return (
                          <TableRow hover tabIndex={-1} key={i} onClick={() => { this.props.navigate("/members/questions/" + row.id) }} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                            <TableCell align="center">{row.title}</TableCell>
                            <TableCell align="center">{Util.isoDateStringToString(row.created_at, true)}</TableCell>
                          </TableRow>
                        );
                      })}

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData && tableData.total ? tableData.total : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event: React.MouseEvent | null, page: number) => { this.setState({page: page}, () => this.loadQuestions(false)); }}
                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => { if (event?.target?.value) { this.setState({rowsPerPage: parseInt(event.target.value)}, () => this.loadQuestions(false)); } }}
              />

            </Paper>

          ) : (!this.context.loading && (
                <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, textAlign: 'center' }}>
                    Keine Umfragen vorhanden
                </Typography>
              )
          )}

          {Role.isQuestionEditor() && (
            <Fab color="secondary" onClick={() => { this.setState({showAddDialog: true}); }} sx={{
              position: 'fixed',
              bottom: 30,
              right: 30,
            }}>
              <Add />
            </Fab>
          )}


          <QuestionEdit 
            open={showAddDialog} 
            onClose={(id: any) => { this.setState({showAddDialog: false}, () => { if (id) { this.props.navigate('/members/questions/' + id); } }); }} 
          />
        
        </Container>
    );
  }

}

export default withHocs(QuestionOverview);