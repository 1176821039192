import React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';

import { DndContext, DragEndEvent, DragStartEvent, DragOverlay, closestCorners } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';

import DragListItem from '../../components/dragListItem';
import DropList from '../../components/dropList';
import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';

//import Cookies from 'js-cookie';


interface Props {
  children?: React.ReactNode,
}

interface State {
  data: any,
  showDelDialog: any,
  dragging: string | number,
}


class FolderEdit extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = {
    data: undefined as any,
    showDelDialog: undefined as any,
    dragging: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadFolders();
  }

  loadFolders = () => {

    this.context.setLoading(true);
    
    this.props.rest.post('dir/getFolders').then(data => {

      this.setState({data: data.folders});
      this.context.setLoading(false);
      console.log(data);

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
    });

  }

  onDelete = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const { showDelDialog, data } = this.state;

    let i: number = data.indexOf(showDelDialog);
    if (showDelDialog.new) {
      data.splice(i, 1);
    } else {
      data[i].del = true;
    }

    this.setState({data: data, showDelDialog: undefined});
  }

  onAdd = () => {
    const { data } = this.state;

    let id: number = -1;
    if (data) {
      data.forEach((d: any, i: number) => {
        if (d.id <= id) {
          id = (d.id - 1);
        }
      });
    }

    data.push({id: id, name: '', short_name: '', new: true});
    this.setState({data: data});
  }

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let data = this.state.data;

    let emptyError: boolean = false;
    let emptyShortError: boolean = false;

    let iDel: number = 1;

    data.forEach((d: any, i: number) => {
      if (!d.del) {
        data[i].position = iDel;
        iDel++;
        if (!d.name || !d.name.trim()) {
          emptyError = true;
        }
        if (!d.short_name || !d.short_name.trim()) {
          emptyShortError = true;
        }
      } else {
        data[i].name = 'Del';
        data[i].short_name = 'D';
        data[i].position = -1;
      }
    });
    
    if (emptyError) {
      this.props.enqueueSnackbar("Es muss ein Mappen-Name angegeben werden", { variant: 'error' });
      return ;
    }
    if (emptyShortError) {
      this.props.enqueueSnackbar("Es muss eine Abkürzung angegeben werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);
    let folderData: any = data;

    this.props.rest.post("dir/changeFolders", folderData).then(res => {

      this.context.setLoading(false);

      if (res && res.status && res.status == "ok") {
        this.props.enqueueSnackbar("Mappen erfolgreich bearbeitet", { variant: 'success' });
        this.loadFolders();
      } else {
        this.props.enqueueSnackbar("Mappen konnten nicht bearbeitet werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Mappen konnte nicht bearbeitet werden", { variant: 'error' });
    });
  }

  render() {
    const { data, showDelDialog, dragging } = this.state;

    if (!data)
      return null;

    let draggingElement: JSX.Element | undefined = undefined;

    let folders: any[] = [];

    if (data) {
      folders = data.filter((folder: any) => { 
        return !folder.del; 
      });
    }

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

            <Typography variant="h4" color="textSecondary" marginY={3}>Inhaltsverzeichnis</Typography>

            <form method="POST" onSubmit={this.onSubmit}>

              <Box>
                <Box maxWidth="sm" sx={{ margin: '0 auto' }}>
                  <Paper>

                    <DndContext collisionDetection={closestCorners} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}
                      autoScroll={{
                        threshold: {
                          x: 0,
                          y: 0.02,
                        },
                      }}
                    >

                      <SortableContext 
                          id='folders'
                          items={folders.map((f: any, i: number) => { return f.id.toString(); })}
                      >

                        <DropList id='folders'>
                        
                          {data && data.map((f: any, i: number) => {
                              if (f.del) {
                                return null;
                              }
                              let li: JSX.Element = (
                                <DragListItem key={f.id} id={f.id.toString()}
                                  listItemProps={{
                                    secondaryAction: 
                                      (<IconButton edge="end" onClick={e => { this.setState({showDelDialog: f}, () => { if (f.new) { this.onDelete(); } }); }}>
                                        <Delete />
                                      </IconButton>)
                                  }}
                                >

                                  <Grid container>

                                    <Grid item xs={9}>
                                      <TextField
                                        value={f.name}
                                        onChange={e => { data[i].name = e.target.value; this.setState({data: data}); }}
                                        label="Name"
                                        fullWidth
                                        variant="standard"
                                        inputProps={{ maxLength: 255 }}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextField
                                        value={f.short_name}
                                        onChange={e => { data[i].short_name = e.target.value; this.setState({data: data}); }}
                                        label="Abkürzung"
                                        fullWidth
                                        variant="standard"
                                        inputProps={{ maxLength: 255 }}
                                      />
                                    </Grid>

                                  </Grid>

                                </DragListItem>
                              );
                              if (dragging == f.id.toString()) {
                                draggingElement = li;
                              }
                              return (li);
                          })} 

                        </DropList>
                        
                      </SortableContext>

                      <DragOverlay>
                        {draggingElement && React.cloneElement(draggingElement)}
                      </DragOverlay>

                    </DndContext>

                    <List sx={{ mt: 3 }}>

                      <ListItemButton onClick={() => { this.onAdd(); }}>
                        <ListItemIcon>
                          <Add />
                        </ListItemIcon>
                        <ListItemText primary="Mappe hinzufügen" />
                      </ListItemButton>

                    </List>
                  </Paper>
                </Box>
              </Box>

              <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

                <ButtonGroup variant="outlined">
                  <Button color="primary" type="submit" startIcon={<Save />}>Mappen speichern</Button>
                </ButtonGroup>

              </Box>

            </form>


            <Dialog
              open={showDelDialog ? true : false}
              onClose={() => { this.setState({showDelDialog: undefined}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='sm'
            >
              <form onSubmit={this.onDelete}>
                <DialogTitle>Mappe löschen</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle1">Mappe "{showDelDialog && showDelDialog.name}" wirklich löschen?</Typography>
                  <Typography variant="subtitle2">Dadurch werden auch alle Stücke bei dieser Mappe in allen Inhaltsverzeichnissen entfernt.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({showDelDialog: undefined}); }}>Abbrechen</Button>
                    <Button type="submit">Löschen</Button>
                </DialogActions>
              </form>
            </Dialog>
          
        
        </Container>
    );
  }

  handleDragStart = (event: DragStartEvent) => {
    const { data } = this.state;
    
    this.setState({dragging: event.active.id});
  }

  handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    let data = this.state.data;

    this.setState({dragging: ""});

    let oldIndex: number = this.getIndexById(active.id) || 0;
    let newIndex: number = over ? (this.getIndexById(over.id) || 0) : data.length;
    
    this.setState({data: arrayMove(data, oldIndex, newIndex)});
  }

  getIndexById = (id: string | number): number | undefined => {
    const { data } = this.state;
    let res: number | undefined = undefined;

    if (data && id) {
      data.forEach((d: any, i: number) => {
        if (d.id.toString() === id) {
            res = i;
        }
      });
    }

    return res;
  }

}

export default withHocs(FolderEdit);