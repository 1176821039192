import React from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce, isUndefined } from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/icons-material/Menu';
import Edit from '@mui/icons-material/Edit';
import Person from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import IosShare from '@mui/icons-material/IosShare';

import { AppContext } from '../context';
import DialogTransition from '../components/transition';
import { withHocs, WithHocs } from '../components/hocs';
import Util from '../components/util';

interface Props {
  children?: React.ReactNode,
  open: boolean,
  onClose: (success: boolean) => void,
}

interface State {
    manifest: any,
}


class PwaInstallDialog extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;


  state: State = { 
    manifest: undefined,
  };

  constructor(props: any) {
    super(props);
  }

    componentDidMount() {
        if (this.props.open) {
            this.downloadManifest();
        }
    }

    componentDidUpdate(prevProps: WithHocs & Props): void {
        if (this.props.open && (prevProps.open !== this.props.open)) {
            this.downloadManifest();
        }
    }

  downloadManifest = () => {

    // get path from html
    // <link rel="manifest" href="manifest.json" />

    let linkTag: Element | null = document.querySelector('link[rel=manifest]');
    if (linkTag) {
        let manifestUrl = linkTag.getAttribute("href");

        if (manifestUrl) {
            this.context.setLoading(true);

            axios.get(manifestUrl).then(
                response => {
                    this.context.setLoading(false);

                    if (response.status >= 200 && response.status < 300) {
                        this.setState({manifest: response.data});
                    }

                }
            ). catch(err => {
                console.log(err);
                this.context.setLoading(false);
            });
        }
    }


  }

  openInstallPrompt = async (e?: React.FormEvent<HTMLFormElement>) => {
    const { beforeInstallPrompt } = this.context;
    e?.preventDefault();

    if (beforeInstallPrompt) {
        this.props.onClose(true);
        beforeInstallPrompt.prompt();

        const { outcome } = await beforeInstallPrompt.userChoice;
        this.context.setBeforeInstallPrompt(undefined);
        this.props.onClose(true);
    }
  }

  getBiggestIcon = (): any => {
    const { manifest } = this.state;
    let icon: any = undefined;
    if (manifest && manifest.icons) {
        manifest.icons.forEach((ic: any) => {
            if (!icon || !icon.sizes) {
                icon = ic;
            } else {
                if (icon.sizes) {
                    icon.sizes.split(" ").forEach((sizes: string) => {
                        let currentIconSize: string[] = sizes.toLowerCase().split("x");
                        let iconSize: string[] = icon.sizes.toLowerCase().split("x");

                        if ((currentIconSize[0] > iconSize[0]) && (currentIconSize[1] > iconSize[1])) {
                            icon = ic;
                        }
                    });
                }
            }
        });
    }
    return icon;
  }

  getScreenshots = (): any[] => {
    const { manifest } = this.state;
    return (manifest && manifest.screenshots) ? manifest.screenshots : [];
  }

  render() {
    const { open } = this.props;
    const { manifest } = this.state;
    const { beforeInstallPrompt } = this.context;

    return (
        <React.Fragment>

            {manifest && (
                <Dialog
                    open={open}
                    onClose={() => { this.props.onClose(false); }}
                    TransitionComponent={DialogTransition}
                    fullWidth={true}
                    maxWidth='md'
                >
            
                    <DialogTitle>"{manifest.name}" Installieren</DialogTitle>
                    <form onSubmit={this.openInstallPrompt}>
                        <DialogContent>

                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box component="img" src={this.getBiggestIcon().src} sx={{ 
                                    flexGrow: 0, flexShrink: 0, 
                                    background: 'rgb(211, 211, 211)', padding: 0.5, borderRadius: 1, marginRight: 2,
                                    height: { xs: 50, md: 122},
                                    width: { xs: 50, md: 122 }
                                }} />
                                <Box sx={{ flexGrow: 1, flexShrink: 1, textAlign: 'center' }}>
                                    <Typography variant="h4" gutterBottom>{manifest.name}</Typography>
                                    <Typography variant="body1">Durch die Installation von "{manifest.name}" wird eine Verknüpfung zur App-Übersicht bzw. zum Desktop hinzugefügt. Dadurch kann sie mit nur einem Klick geöffnet werden.</Typography>
                                </Box>
                            </Box>


                            {/* show images */}
                            {this.getScreenshots() && this.getScreenshots().length > 0 && (
                                <ImageList sx={{ overflowX: 'auto' }} cols={this.getScreenshots().length || 1} gap={15}>
                                    {this.getScreenshots().map((ss: any, i: number) => (
                                        <ImageListItem key={i} cols={1} rows={1}>
                                            <Box component="img"
                                                src={ss.src}
                                                loading="lazy"
                                                sx={{
                                                    height: { xs: 350, md: 400 },
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            )}

                            {Util.isIOS() && (
                                <Box sx={{ textAlign: 'center', mt: 3 }}>
                                    <Typography variant="body1" color="secondary">Zur Installation klicke <IosShare /> und anschließend "Zum Home-Bildschirm".</Typography>
                                </Box>
                            )}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { this.props.onClose(false) }}>Schließen</Button>
                            {beforeInstallPrompt && (
                                <Button type="submit">Installieren</Button>
                            )}
                        </DialogActions>

                    </form>

                </Dialog>
            )}

        </React.Fragment>
    );
  }

}

export default withHocs(PwaInstallDialog);