import React from 'react';
import { NavLink } from 'react-router-dom';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness2 from '@mui/icons-material/Brightness2';
import BrightnessAuto from '@mui/icons-material/BrightnessAuto';

import { AppContext } from './context';
import { withHocs, WithHocs } from './components/hocs';


interface Props {
    children?: React.ReactNode,
    title?: string,
}

interface State {
}

function ScrollTop(props: Props) {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
        '#back-to-top-anchor',
        );

        if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" style={{ 
                position: 'fixed',
                bottom: 5,
                right: 5,
             }}>
                {props.children}
            </div>
        </Zoom>
    );
}




class TermsWrapper extends React.Component<WithHocs & Props, State> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
    };

    constructor(props: any) {
        super(props);
    }

    handleToTopClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }


    render() {
        const { version, themeMode } = this.context;
        const { title, children } = this.props;

        return (
            <React.Fragment>

                <Box id="back-to-top-anchor" sx={{ height: '100%' }}>

                    <Container component="main" maxWidth="lg" sx={{ p: 4 }}>

                        <Paper sx={{ p: 4 }} elevation={5}>
                            <Typography variant="h4" color="primary" align="left" sx={{ fontFamily: 'Dancing Script', fontSize: 48 }}>
                                <Box component="span" sx={{ fontFamily: 'Dancing Script', fontSize: 64, fontWeight: 'bold' }}>M</Box>eine&nbsp;
                                <Box component="span" sx={{ fontFamily: 'Dancing Script', fontSize: 64, fontWeight: 'bold' }}>M</Box>usik
                            </Typography>
                            <Box ml={4}>
                                <Grid container>
                                    <Grid item sm={10} xs={12}>
                                        <Typography variant="h5" color="textSecondary">
                                        {title}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={2} xs={12} sx={{ textAlign: 'right' }}>
                                        <Tooltip TransitionComponent={Zoom} title={(themeMode.indexOf('auto') > -1) ? "Auto-Mode" : ((themeMode.indexOf('light') > -1) ? "Light-Mode" : "Dark-Mode")} placement="left" arrow>
                                            <IconButton
                                                aria-label="theme-switcher"
                                                onClick={() => { this.context.switchThemeMode(); }}
                                                color="inherit"
                                                component="span"
                                            >
                                                {(themeMode.indexOf('auto') > -1) 
                                                    ? <BrightnessAuto /> : 
                                                        ((themeMode.indexOf('light') > -1) 
                                                        ? <BrightnessLow />
                                                        : <Brightness2 />)
                                                }
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={2}>
                                {children}
                            </Box>
                        </Paper>

                        <Box sx={{ 
                            textAlign: 'center',
                            pt: 2,
                        }}>
                            <Typography variant="subtitle1" gutterBottom>
                                <Link component={NavLink} to="/terms" underline="hover" variant="body2" color="textSecondary">Nutzungsbedingungen</Link>
                                {" - "}
                                <Link component={NavLink} to="/legalnotice" underline="hover" variant="body2" color="textSecondary">Impressum</Link>
                            </Typography>
                        </Box>

                    </Container>

                </Box>

                <ScrollTop {...this.props}>
                    <Fab color="primary" size="small" aria-label="scroll back to top">
                        <KeyboardArrowUp />
                    </Fab>
                </ScrollTop>

            </React.Fragment>
        );
    }

}

export default withHocs(TermsWrapper);