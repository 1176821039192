import React from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Workbox } from 'workbox-window';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import DialogTransition from './components/transition';

import Login from './login';
import LoginWrapper from './loginWrapper';
import TermsWrapper from './termsWrapper';
import Terms from './terms';
import LegalNotice from './legalNotice';
import DataPrivacy from './dataPrivacy';
import PwdReset from './pwdReset';
import MailChange from './mailChange';
import MenuWrapper from './menuWrapper';
import { AppContext } from './context';
import withRest, { WithRest } from './rest';
import Role from './components/role';
import FcmService from './components/fcmService';
import WorkboxService from './components/workboxService';

import Dashboard from './dashboard/dashboard';

import TrackOverview from './archive/tracks/trackOverview';
import TrackNew from './archive/tracks/trackNew';
import Track from './archive/tracks/track';

import DirOverview from './archive/dir/dirOverview';
import Dir from './archive/dir/dir';
import DirTracksEdit from './archive/dir/dirTracksEdit';
import FolderEdit from './archive/dir/folderEdit';

import ProgramOverview from './archive/program/programOverview';
import Program from './archive/program/program';
import ProgramTracksEdit from './archive/program/programTracksEdit';
import ProgramAkm from './archive/program/programAkm';

import CalendarOverview from './events/calendar/calendarOverview';
import CalendarMonth from './events/calendar/calendarMonth';
import CalendarDay from './events/calendar/calendarDay';
import CalendarEvents from './events/calendar/calendarEvents';
import CalendarEventWrapper from './events/calendar/calendarEventWrapper';

import PresenceOverview from './events/presence/presenceOverview';

import StatisticOverview from './events/statistic/statisticOverview';

import UserOverview from './members/user/userOverview';

import QuestionOverview from './members/questions/questionOverview';
import Question from './members/questions/question';

import PdfViewer from './components/pdfViewer';


WorkboxService.getInstance();
FcmService.getInstance();


interface Props {
}

interface State {
    loggedInChecking: boolean,

    unions: any[],
    selUnion: string,
    showUnionDialog: boolean,
}

class App extends React.Component<WithRest & WithSnackbarProps & Props, State> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: State = {
        loggedInChecking: true,

        unions: [],
        selUnion: "",
        showUnionDialog: false,
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {

        this.initClient();

        /*if ('serviceWorker' in navigator) {
            const wb = new Workbox('./service-worker.js');
          
            wb.register();
        }*/

    }

    initClient = () => {
        this.setState({loggedInChecking: true});
        this.context.setLoading(true);

        this.props.rest.get('csrf-cookie').then(csrfRes => {

            this.props.rest.post('user/getClientConfig').then(clientConfigData => {

                this.context.setLoading(false);

                if (clientConfigData && clientConfigData.status && clientConfigData.status == "ok") {
                    this.context.setClientConfig(clientConfigData.data);
                }

                this.checkLoggedIn();

            }).catch(err => {
                this.context.setLoading(false);
                this.checkLoggedIn();
            });

        }).catch(err => {
            this.context.setLoading(false);
            window.location.reload();
        });


        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.context.setBeforeInstallPrompt(e);
        });

    }

    checkLoggedIn = () => {
        if (!this.context.loggedIn) {

            this.context.setLoading(true);

            this.props.rest.post('user').then(data => {

                this.context.setLoading(false);

                console.log('user', data);
                
                if (data && data.status) {
                    if (data.status == "ok") {
                        this.context.setPermission(data.roles);
                        this.context.setUser(data.user);
                        this.context.setUserUnion(data.user_union);
                        // this.context.setUnion(data.user_union.union);
                        this.context.setLoggedIn(true);
                        this.setState({loggedInChecking: false});
                    } else if (data.status == "multiple_unions") {
                        this.setState({selUnion: "", unions: data.user_unions.map((uu: any) => uu.union), showUnionDialog: true});
                    }
                } else {
                    this.setState({selUnion: "", unions: [], showUnionDialog: false});
                    this.setState({loggedInChecking: false});
                }
                
            }).catch(err => {
                this.context.setLoading(false);
                this.setState({loggedInChecking: false});
            });

        }
    }

    onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (this.state.showUnionDialog && !this.state.selUnion) {
            this.props.enqueueSnackbar("Bitte wählen Sie einen Verein aus.", { variant: 'error' });
        } else {

            this.context.setLoading(true);

            var loginData: any = {union: (this.state.selUnion ? parseInt(this.state.selUnion) : undefined)};

            this.props.rest.post('user', loginData).then(data => {
                this.context.setLoading(false);
                this.setState({selUnion: ""});

                if (data && data.status) {
                    if (data.status == "ok") {
                        this.setState({showUnionDialog: false});
                        this.context.setPermission(data.roles);
                        this.context.setUser(data.user);
                        this.context.setUserUnion(data.user_union);
                        // this.context.setUnion(data.union);
                        this.context.setLoggedIn(true);
                        this.setState({loggedInChecking: false});
                    } else if (data.status == "multiple_unions") {
                        this.setState({selUnion: "", unions: data.user_unions.map((uu: any) => uu.union), showUnionDialog: true});
                    }
                } else {
                    this.setState({selUnion: "", unions: [], showUnionDialog: false});
                    this.setState({loggedInChecking: false});
                }
                
            }).catch(err => {
                this.setState({selUnion: "", unions: [], showUnionDialog: false});
                this.context.setLoading(false);
                this.setState({loggedInChecking: false});
            });
        
        }
        
    }
  
    render() {
        const { loggedIn, loading } = this.context;
        const { loggedInChecking } = this.state;
        const { showUnionDialog, unions, selUnion } = this.state;

        if (loggedInChecking) {
            return (
                <Dialog
                open={showUnionDialog}
                onClose={() => { this.setState({showUnionDialog: false, loggedInChecking: false}); }}
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='sm'
                >
                    <DialogTitle>Vereins-Auswahl</DialogTitle>
                    <DialogContent>

                        <Typography variant="body1">Mehrere Vereine haben dich als Mitglied angegeben, bitte wähle den Verein aus, bei dem du dich anmelden möchtest.</Typography>

                        <FormControl fullWidth sx={{ my: 3 }}>
                            <InputLabel>Verein</InputLabel>
                            
                            {/* wegen IE-Bug NativeSelect verwenden */}
                            <Select
                                value={selUnion}
                                onChange={e => { this.setState({selUnion: e.target.value});  }}
                                fullWidth
                            >
                                {/* <MenuItem value=""></MenuItem> */}
                                {unions && unions.map((union: any, i: number) => {
                                    return (
                                        <MenuItem value={union.id} key={i}>{union.name}</MenuItem>
                                    );
                                })}
                            </Select> 

                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({unions: [], selUnion: "", showUnionDialog: false, loggedInChecking: false}); }}>Abbrechen</Button>
                        <Button onClick={() => { this.onSubmit(); }}>Login</Button>
                    </DialogActions>
                </Dialog>
            );
        } else if (!loggedIn) {
            return (
                <HashRouter>
                    <Routes>
                        <Route path="pwdreset" element={<LoginWrapper title="Passwort vergeben"><PwdReset /></LoginWrapper>} />
                        <Route path="mailchange" element={<LoginWrapper title="E-Mail-Adresse bestätigen"><MailChange /></LoginWrapper>} />
                        <Route path="terms" element={<TermsWrapper title="Nutzungsbedingungen"><Terms /></TermsWrapper>} />
                        <Route path="legalnotice" element={<TermsWrapper title="Impressum"><LegalNotice /></TermsWrapper>} />
                        <Route path="dataprivacy" element={<TermsWrapper title="Datenschutzerklärung"><DataPrivacy /></TermsWrapper>} />
                        <Route path="*" element={<LoginWrapper title="Login"><Login /></LoginWrapper>} />
                    </Routes>
                </HashRouter>
            );
        } else {
            return (
                <HashRouter>
                    <Role />
                    
                    <Routes>
                        <Route path="pwdreset" element={<LoginWrapper title="Passwort vergeben"><PwdReset /></LoginWrapper>} />
                        <Route path="mailchange" element={<LoginWrapper title="E-Mail-Adresse bestätigen"><MailChange /></LoginWrapper>} />
                        <Route path="terms" element={<TermsWrapper title="Nutzungsbedingungen"><Terms /></TermsWrapper>} />
                        <Route path="legalnotice" element={<TermsWrapper title="Impressum"><LegalNotice /></TermsWrapper>} />
                        <Route path="dataprivacy" element={<TermsWrapper title="Datenschutzerklärung"><DataPrivacy /></TermsWrapper>} />
                        
                        <Route path="dashboard" element={<MenuWrapper><Dashboard /></MenuWrapper>} />
                        <Route path="archive">
                            <Route index element={<Navigate replace to="/dashboard" />} />
                            <Route path="tracks">
                                <Route index element={<MenuWrapper><TrackOverview /></MenuWrapper>} />
                                <Route path="new" element={<MenuWrapper><TrackNew /></MenuWrapper>} />
                                <Route path="edit/:id" element={<MenuWrapper><TrackNew /></MenuWrapper>} />
                                <Route path=":id" element={<MenuWrapper><Track /></MenuWrapper>} />
                            </Route>
                            <Route path="dirs">
                                <Route index element={<MenuWrapper><DirOverview /></MenuWrapper>} />
                                <Route path="folder" element={<MenuWrapper><FolderEdit /></MenuWrapper>} />
                                <Route path="edit/:id" element={<MenuWrapper><DirTracksEdit /></MenuWrapper>} />
                                <Route path=":id" element={<MenuWrapper><Dir /></MenuWrapper>} />
                            </Route>
                            <Route path="programs">
                                <Route index element={<MenuWrapper><ProgramOverview /></MenuWrapper>} />
                                <Route path="akm" element={<MenuWrapper><ProgramAkm /></MenuWrapper>} />
                                <Route path="edit/:id" element={<MenuWrapper><ProgramTracksEdit /></MenuWrapper>} />
                                <Route path=":id" element={<MenuWrapper><Program /></MenuWrapper>} />
                            </Route>
                        </Route>
                        <Route path="events">
                            <Route path="calendar">
                                <Route index element={<Navigate replace to="/events/calendar/month" />} />
                                <Route path="event" element={<MenuWrapper><CalendarOverview view="event"><CalendarEvents /></CalendarOverview></MenuWrapper>} />
                                <Route path="event/:id" element={<MenuWrapper><CalendarEventWrapper /></MenuWrapper>} />
                                <Route path="month" element={<MenuWrapper><CalendarOverview view="month"><CalendarMonth /></CalendarOverview></MenuWrapper>} />
                                <Route path="month/:month" element={<MenuWrapper><CalendarOverview view="month"><CalendarMonth /></CalendarOverview></MenuWrapper>} />
                                <Route path="day" element={<MenuWrapper><CalendarOverview view="day"><CalendarDay /></CalendarOverview></MenuWrapper>} />
                                <Route path="day/:day" element={<MenuWrapper><CalendarOverview view="day"><CalendarDay /></CalendarOverview></MenuWrapper>} />
                            </Route>
                            <Route path="presence">
                                <Route index element={<MenuWrapper><PresenceOverview /></MenuWrapper>} />
                                <Route path=":id" element={<MenuWrapper><PresenceOverview /></MenuWrapper>} />
                            </Route>
                            <Route path="statistics">
                                <Route index element={<MenuWrapper><StatisticOverview /></MenuWrapper>} />
                            </Route>
                        </Route>
                        <Route path="members">
                            <Route path="user">
                                <Route index element={<MenuWrapper><UserOverview /></MenuWrapper>} />
                            </Route>
                            <Route path="questions">
                                <Route index element={<MenuWrapper><QuestionOverview /></MenuWrapper>} />
                                <Route path=":id" element={<MenuWrapper><Question /></MenuWrapper>} />
                            </Route>
                        </Route>
                        <Route path="pdf">
                            <Route index element={<Navigate replace to="/dashboard" />} />
                            <Route path=":url" element={<MenuWrapper hideFooter><PdfViewer /></MenuWrapper>} />
                        </Route>

                        <Route path="*" element={<Navigate replace to="/dashboard" />} />
                    </Routes>
                    
                </HashRouter>
            );
        }
    }

}

export default withRest(withSnackbar(App));