import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce } from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/icons-material/Menu';
import LibraryMusic from '@mui/icons-material/LibraryMusic';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';


interface TRow {
  id: number,
  archivNr?: number,
  title: string,
  composer?: string,
  arrangement?: string,
  publisher?: string,
  cat?: string,
  orchSize?: string,
}


interface Props {
  children?: React.ReactNode,
}

interface State {
  tableData: any,
  page: number,
  rowsPerPage: number,
  showAddDialog: boolean,
  addProgramName: string,
  addProgramLocation: string,
  addProgramSearchLocation: any[],
  addProgramBegin: moment.Moment | undefined | null,
  addProgramEnd: moment.Moment | undefined | null,
  addProgramShortcut: boolean,

  title: string,
  searchTitle: any[],
  location: string,
  searchLocation: any[],
  from: moment.Moment | undefined | null,
  to: moment.Moment | undefined | null,
  panelExpanded: boolean,
}


class ProgramOverview extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state: State = { 
    tableData: undefined as any,
    page: 0,
    rowsPerPage: 25,
    showAddDialog: false,
    addProgramName: "",
    addProgramLocation: "",
    addProgramSearchLocation: [],
    addProgramBegin: null,
    addProgramEnd: null,
    addProgramShortcut: false,

    title: "",
    searchTitle: [],
    location: "",
    searchLocation: [],
    from: null,
    to: null,
    panelExpanded: false,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.searchPrograms(true);
  }

  searchPrograms = (fresh: boolean, e?: React.FormEvent) => {
    e?.preventDefault();

    const { rowsPerPage, title, location, from, to, panelExpanded } = this.state;

    let page: number = (fresh ? 0 : this.state.page);
    if (fresh) {
      this.setState({tableData: undefined, page: page});
    }

    this.context.setLoading(true);
    let programData: any = { page: (page + 1), rowsPerPage: rowsPerPage, title: title, location: location, from: Util.dateToIsoString(from?.startOf('day')), to: Util.dateToIsoString(Util.dateToEndOfDayDate(to?.endOf('day'))) };

    this.props.rest.post("program/search", programData).then(data => {

      this.context.setLoading(false);

      if (data && data.programs && data.status && data.status == "ok") {

        this.setState({tableData: data.programs, panelExpanded: ((data.programs && data.programs.data && data.programs.data.length > 0) ? false : panelExpanded)});
        console.log(data);

      } else {
        this.props.enqueueSnackbar("Programme konnten nicht gesucht werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Programme konnten nicht gesucht werden", { variant: 'error' });
    });

  }

  onAddClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { addProgramName, addProgramLocation, addProgramBegin, addProgramEnd, addProgramShortcut } = this.state;

    if (!addProgramName || !addProgramName.trim()) {
      this.props.enqueueSnackbar("Ein Programm-Name muss angegeben werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);
    let programData: any = { title: addProgramName, location: addProgramLocation, begin_date: Util.dateToIsoString(addProgramBegin), end_date: Util.dateToIsoString(addProgramEnd), shortcut: addProgramShortcut };

    this.props.rest.post("program/new", programData).then(data => {

      this.context.setLoading(false);

      if (data && data.id && data.status && data.status == "ok") {

        this.props.enqueueSnackbar("Programm erfolgreich angelegt", { variant: 'success' });
        this.setState({addProgramName: "", addProgramLocation: "", addProgramSearchLocation: [], addProgramBegin: null, addProgramEnd: null, addProgramShortcut: false, showAddDialog: false}, () => {
          this.props.navigate("/archive/programs/" + data.id);
        });

      } else {
        this.props.enqueueSnackbar("Programm konnte nicht angelegt werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Programm konnte nicht angelegt werden", { variant: 'error' });
    });

  }

  async search(uri: string, data: any) {
    return this.props.rest.post(uri, data);
  }

  searchTitle = debounce(async () => {
    const { title } = this.state; 
    if (title) {
      console.log("searchTitle", title);
      this.setState({searchTitle: 
        await this.search('program/searchTitle', {title: title})
      });
    }
  }, 450);

  searchLocation = debounce(async () => { 
    const { location } = this.state;
    if (location) {
        this.setState({searchLocation: 
            await this.search('program/searchLocation', {location: location})
        });
    }
  }, 450);

  searchAddProgramLocation = debounce(async () => { 
    const { addProgramLocation } = this.state;
    if (addProgramLocation) {
        this.setState({addProgramSearchLocation: 
            await this.search('program/searchLocation', {location: addProgramLocation})
        });
    }
  }, 450);

  render() {
    const { tableData, page, rowsPerPage, showAddDialog, addProgramName, addProgramLocation, addProgramSearchLocation, addProgramBegin, addProgramEnd, addProgramShortcut, title, searchTitle, location, searchLocation, from, to, panelExpanded } = this.state;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Programme</Typography>

          
          <form action="" method="POST" onSubmit={e => { this.searchPrograms(true, e); }}>

            <Accordion expanded={panelExpanded} disableGutters>
              <AccordionSummary
                expandIcon={null}
                onClick={e => { e.preventDefault(); }}
              >

                <Paper
                  sx={{ p: 0, display: 'flex', alignItems: 'center', width: '100%' }}
                >
                  <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={() => { this.setState({panelExpanded: !panelExpanded}); }}>
                    <Menu />
                  </IconButton>

                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={searchTitle.map((option: any) => option.title)}
                    autoComplete
                    inputValue={title}
                    onInputChange={(event, value) => {
                      this.setState({title: value, searchTitle: []}, this.searchTitle); 
                    }}
                    fullWidth
                    renderInput={(params) => {
                      const {InputLabelProps,InputProps,...rest} = params;
                      return (
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Titel suchen"
                          {...params.InputProps} {...rest}
                        />
                      );
                    }}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton color="primary" sx={{ p: '10px' }} aria-label="search" onClick={() => { this.searchPrograms(true); }}>
                    <Search />
                  </IconButton>
                </Paper>

              </AccordionSummary>
              <AccordionDetails>

                <Grid container spacing={2}>

                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={searchTitle.map((option: any) => option.title)}
                            autoComplete
                            inputValue={title}
                            onInputChange={(event, value) => { 
                                this.setState({title: value, searchTitle: []}, this.searchTitle); 
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField label="Titel" {...params} />
                            )}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={searchLocation.map((option: any) => option.location)}
                            autoComplete
                            inputValue={location}
                            onInputChange={(event, value) => { 
                                this.setState({location: value, searchLocation: []}, this.searchLocation); 
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField label="Ort" {...params} />
                            )}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <DatePicker
                          label="Von Datum"
                          inputFormat="DD.MM.YYYY"
                          mask="__.__.____"
                          renderInput={(params: any) => <TextField {...params} fullWidth />}
                          value={from}
                          onChange={(date: any, keyboardInputValue: any) => { this.setState({from: date}); }}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <DatePicker
                          label="Bis Datum"
                          inputFormat="DD.MM.YYYY"
                          mask="__.__.____"
                          renderInput={(params: any) => <TextField {...params} fullWidth />}
                          value={to}
                          onChange={(date: any, keyboardInputValue: any) => { this.setState({to: date}); }}
                        />
                    </Grid>

                    <Grid item xs={12} textAlign="center">
                      <Button type="submit" variant="contained" startIcon={<Search />} size="large">Suchen</Button>
                    </Grid>

                </Grid>
              
              </AccordionDetails>
            </Accordion>

        </form>









        {tableData && tableData.data && tableData.data.length > 0 ? (

          <Paper sx={{
            width: '100%', 
            overflow: 'hidden',
            mt: 5,
            mb: 5,
          }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Name</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Ort</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Beginn</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Vorlage</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Erstellt am</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                    {tableData && tableData.data && tableData.data.map((row: any, i: number) => {
                      return (
                        <TableRow hover tabIndex={-1} key={i} onClick={() => { this.props.navigate("/archive/programs/" + row.id) }} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                          <TableCell align="center">{row.title}</TableCell>
                          <TableCell align="center">{row.location}</TableCell>
                          <TableCell align="center">{Util.isoDateStringToString(row.begin_date, true)}</TableCell>
                          <TableCell align="center"><Checkbox checked={row.shortcut} color="primary" disabled /></TableCell>
                          <TableCell align="center">{Util.isoDateStringToString(row.created_at, true)}</TableCell>
                        </TableRow>
                      );
                    })}

                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tableData && tableData.total ? tableData.total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event: React.MouseEvent | null, page: number) => { this.setState({page: page}, () => this.searchPrograms(false)); }}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => { if (event?.target?.value) { this.setState({rowsPerPage: parseInt(event.target.value)}, () => this.searchPrograms(false)); } }}
            />

          </Paper>

        ) : (!this.context.loading && (
              <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, textAlign: 'center' }}>
                  Keine Programme mit dem aktuellen Suchfilter gefunden
              </Typography>
            )
        )}




          {Role.isProgramEditor() && (
            <React.Fragment>

              <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

                <ButtonGroup variant="outlined">
                  <Button component={NavLink} color="primary" startIcon={<LibraryMusic />} to={"/archive/programs/akm"}>AKM-Ansicht</Button>
                </ButtonGroup>

              </Box>

              <Fab color="secondary" onClick={() => { this.setState({showAddDialog: true}); }} sx={{
                position: 'fixed',
                bottom: 30,
                right: 30,
              }}>
                <Add />
              </Fab>

            </React.Fragment>
          )}


            <Dialog
                open={showAddDialog}
                onClose={() => { this.setState({showAddDialog: false}); }}
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='sm'
            >
              <form onSubmit={this.onAddClicked}>
                <DialogTitle>Programm hinzufügen</DialogTitle>
                <DialogContent>
                    
                      <Grid container spacing={2} sx={{mt: 1}}>
                        
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Titel"
                            autoFocus
                            value={addProgramName}
                            onChange={event => { this.setState({addProgramName: event.target.value}) } }
                            inputProps={{ maxLength: 255 }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Autocomplete
                            freeSolo
                            disableClearable
                            options={addProgramSearchLocation.map((option: any) => option.location)}
                            autoComplete
                            inputValue={addProgramLocation}
                            onInputChange={(event, value) => { 
                                this.setState({addProgramLocation: value, addProgramSearchLocation: []}, this.searchAddProgramLocation); 
                            }}
                            fullWidth
                            renderInput={(params) => {
                                const { inputProps, ...rest } = params;
                                return (<TextField label="Ort" inputProps={{...inputProps, maxLength: 255}} {...rest} />);
                            }}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <DateTimePicker
                            label="Beginn"
                            inputFormat="DD.MM.YYYY HH:mm"
                            mask="__.__.____ __:__"
                            renderInput={(params: any) => <TextField {...params} fullWidth />}
                            value={addProgramBegin}
                            onChange={(date: any, keyboardInputValue: any) => { this.setState({addProgramBegin: date}); }}
                            ampm={false}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <DateTimePicker
                            label="Ende"
                            inputFormat="DD.MM.YYYY HH:mm"
                            mask="__.__.____ __:__"
                            renderInput={(params: any) => <TextField {...params} fullWidth />}
                            value={addProgramEnd}
                            onChange={(date: any, keyboardInputValue: any) => { this.setState({addProgramEnd: date}); }}
                            ampm={false}
                          />
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={addProgramShortcut}
                                    onChange={evt => { this.setState({addProgramShortcut: evt.target.checked}) }}
                                    color="primary"
                                />
                            }
                            label="Vorlage"
                          />
                        </Grid>

                    </Grid>
                  

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({showAddDialog: false, addProgramName: "", addProgramLocation: "", addProgramSearchLocation: [], addProgramBegin: null, addProgramEnd: null, addProgramShortcut: false}); }}>Abbrechen</Button>
                    <Button type="submit">Anlegen</Button>
                </DialogActions>

              </form>
            </Dialog>
        
        </Container>
    );
  }

}

export default withHocs(ProgramOverview);