import React from 'react';
import { NavLink } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';

import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness2 from '@mui/icons-material/Brightness2';
import BrightnessAuto from '@mui/icons-material/BrightnessAuto';

import moment from 'moment-timezone';
import Cookies from 'universal-cookie';

import { AppContext } from './context';
import { withHocs, WithHocs } from './components/hocs';
import DialogTransition from './components/transition';
import Util from './components/util';

interface State {
    open: boolean,
    required: boolean,
    statistic: boolean,
    marketing: boolean,
}

interface Props {
    children?: React.ReactNode,
}

class CookieBanner extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: State = { 
        open: false,
        required: false,
        statistic: false,
        marketing: false,
    };
  
    constructor(props: any) {
      super(props);
    }
    
    componentDidMount() {
        this.checkOpen();
    }

    checkOpen = () => {
        const cookies = new Cookies();
        const c: any = cookies.get('cookie_decision');

        if (c) {
            this.setState({
                required: !!c.required,
                statistic: !!c.statistic,
                marketing: !!c.marketing,
                open: !c.required,
            }, () => {
                //rewrite expiration date when cookie exists
                //chrome limits the expiration date to 400 days
                this.setCookie();
            });
        } else {
            this.setState({
                required: true,
                statistic: false,
                marketing: false,
                open: true,
            });
        }
    }

    setCookie = () => {
        const { required, statistic, marketing } = this.state;
        
        const c: any = { required: required, statistic: statistic, marketing: marketing };
        
        let expires: Date = moment().add(10, 'years').toDate();
        console.log(expires);

        const cookies = new Cookies();
        cookies.set('cookie_decision', c, { httpOnly: false, sameSite: 'strict', expires: expires });
    }

    onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        this.setCookie();

        this.setState({open: false});
    }
  
    render() {
      const { open, required, statistic, marketing } = this.state;

      return (
        <Dialog
            open={open}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='md'
        >
      
            <DialogTitle>Cookies</DialogTitle>
            <form onSubmit={this.onSubmit}>
                <DialogContent>

                    <Typography variant="body2" gutterBottom>"Meine Musik" verwendet nur notwendige Cookies, diese Cookies sind erforderlich um das Online-Service wie gewohnt benützen zu können.</Typography>

                    <Typography variant="body2" gutterBottom>Weitere Informationen zu Cookies finden Sie in den <Link component={NavLink} to="/terms" target="_blank" underline="hover" color="primary">Nutzungsbedingungen</Link> und in der <Link component={NavLink} to="/dataprivacy" target="_blank" underline="hover" color="primary">Datenschutzerklärung</Link>.</Typography>
                
                    <Grid container spacing={2} mt={2}>

                        <Grid item xs={12} md={4}>
                            <FormControlLabel control={<Checkbox disabled checked={required} onChange={e => { this.setState({required: e.target.checked}) }} />} label="Notwendige" />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControlLabel control={<Checkbox checked={statistic} onChange={e => { this.setState({statistic: e.target.checked}) }} />} label="Statistik" />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControlLabel control={<Checkbox checked={marketing} onChange={e => { this.setState({marketing: e.target.checked}) }} />} label="Marketing" />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({required: true, statistic: true, marketing: true}, () => { this.onSubmit(); }); }} variant="outlined" color="secondary">Alle auswählen</Button>
                    <Button type="submit" variant="outlined" color="primary">Auswahl speichern</Button>
                </DialogActions>

            </form>

        </Dialog>
      );
    }
  }

export default withHocs(CookieBanner);