import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce, isUndefined } from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/icons-material/Menu';
import Edit from '@mui/icons-material/Edit';
import Person from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import TextDivider from '../../components/textDivider';
import Dropzone, { FileInput } from '../../components/dropzone';
import { userInfo } from 'os';

interface Props {
  children?: React.ReactNode,
  open: boolean,
  onClose: (success: boolean) => void,
}

interface State {
  oldPwd: string,
  newPwd: string,
  newPwdConfirm: string,
}


class UserPwdChange extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state: State = { 
    oldPwd: "",
    newPwd: "",
    newPwdConfirm: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps: WithHocs & Props): void {
      if (prevProps.open !== this.props.open) {
        this.setState({oldPwd: "", newPwd: "", newPwdConfirm: ""});
      }
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { oldPwd, newPwd, newPwdConfirm } = this.state;

    if (!oldPwd || !oldPwd.trim()) {
        this.props.enqueueSnackbar("Das alte Passwort muss angegeben werden", { variant: 'error' });
        return ;
    }

    if (!newPwd || !newPwd.trim()) {
        this.props.enqueueSnackbar("Das neue Passwort muss angegeben werden", { variant: 'error' });
        return ;
    }

    if (newPwd !== newPwdConfirm) {
        this.props.enqueueSnackbar("Das neue Passwort wurde nicht korrekt wiederholt", { variant: 'error' });
        return ;
    }

    this.context.setLoading(true);

    let pwdData: any = { oldPwd: oldPwd, newPwd: newPwd };

    this.props.rest.post("settings/pwdChange", pwdData).then(data => {

      this.context.setLoading(false);

      if (data && data.status && data.status == "ok") {
        this.props.enqueueSnackbar("Password erfolgreich geändert", { variant: 'success' });
        this.props.onClose(true);
      } else if (data && data.status && data.status == "old_pwd_wrong") {
        this.props.enqueueSnackbar("Das alte Password ist nicht korrekt", { variant: 'error' });
      } else {
        this.props.enqueueSnackbar("Passwort konnten nicht geändert werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      if (err?.errors?.old_pwd && (err.errors.old_pwd == "wrong")) {
        this.props.enqueueSnackbar("Das alte Password ist nicht korrekt", { variant: 'error' });
      } else {
        this.props.enqueueSnackbar("Passwort konnten nicht geändert werden", { variant: 'error' });
      }
    });

  }

  render() {
    const { open } = this.props;
    const { oldPwd, newPwd, newPwdConfirm } = this.state;

    return (
        <Dialog
            open={open}
            onClose={() => { this.props.onClose(false); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='sm'
        >
      
            <DialogTitle>Passwort ändern</DialogTitle>
            <form onSubmit={this.onSubmit}>
                <DialogContent>

                    <Typography variant="body1" gutterBottom  sx={{ mb: 2 }}>Hier kannst du dein Passwort ändern, dazu gib bitte dein altes Passwort ein, dein neues Passwort und bestätitige es anschließend.</Typography>
                
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Altes Passwort"
                                value={oldPwd}
                                onChange={event => { this.setState({oldPwd: event.target.value}) } }
                                inputProps={{ maxLength: 255 }}
                                autoFocus
                                type="password"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Neues Passwort"
                                value={newPwd}
                                onChange={event => { this.setState({newPwd: event.target.value}) } }
                                inputProps={{ maxLength: 255 }}
                                type="password"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Neues Passwort wiederholen"
                                value={newPwdConfirm}
                                onChange={event => { this.setState({newPwdConfirm: event.target.value}) } }
                                inputProps={{ maxLength: 255 }}
                                type="password"
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.props.onClose(false); }}>Abbrechen</Button>
                    <Button type="submit">Ändern</Button>
                </DialogActions>

            </form>

        </Dialog>
    );
  }

}

export default withHocs(UserPwdChange);