import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

import { AppContext } from '../../context';
import { withHocs, WithHocs } from '../../components/hocs';


interface Props {
    children?: React.ReactNode,
    view: string,
}

interface State {
}


class CalendarOverview extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { 
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
    }


    render() {
        const { view } = this.props;

        return (
            <Box sx={{ pb: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>

                <Container maxWidth="xl" sx={{ flexGrow: 0, flexShrink: 0 }}>

                    <Grid container spacing={2} sx={{ my: 3 }}>

                        <Grid item xs={12} sm={6} md={9}>
                            <Typography variant="h4" color="textSecondary">Kalender</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>Ansicht</InputLabel>
                                <Select value={view || "month"} onChange={e => { this.props.navigate("/events/calendar/" + e.target.value); }} >
                                    <MenuItem value="event">Termine</MenuItem>
                                    <MenuItem value="month">Monat</MenuItem>
                                    <MenuItem value="day">Tag</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>

                </Container>

                <Box sx={{ flexGrow: 1, flexShrink: 1 }}>

                    { this.props.children }

                </Box>

            </Box>
        );
    }

}

export default withHocs(CalendarOverview);