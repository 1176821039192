import React from 'react';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import Article from '@mui/icons-material/Article';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import ContentCopy from '@mui/icons-material/ContentCopy';

import { AppContext } from '../../context';
import Role from '../../components/role';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';

//import Cookies from 'js-cookie';


interface Props {
  children?: React.ReactNode,
}

interface State {
  fabOpen: boolean,
  delOpen: boolean,
  renameOpen: boolean,
  duplicateRenameOpen: boolean,
  renameDirName: string,
  data: any,

  trackConfig: any,
  folderGenOpen: boolean,
  folderGenFolder?: number[],
  folderGenInstr: string,
}


class Dir extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = {
    fabOpen: false,
    delOpen: false,
    renameOpen: false,
    duplicateRenameOpen: false,
    renameDirName: "",
    data: undefined as any,

    trackConfig: undefined as any,
    folderGenOpen: false,
    folderGenFolder: [] as number[],
    folderGenInstr: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadDir();
  }

  componentDidUpdate(prevProps: WithHocs & Props) {
    if (this.props && this.props.params && prevProps && prevProps.params && (this.props.params.id !== prevProps.params.id)) {
      this.loadDir();
    }
  }

  loadDir = () => {
    const { id } = this.props.params;
    if (id) {
      this.context.setLoading(true);

      let dirData: any = { id: parseInt(id) };
      
      this.props.rest.post('dir/get', dirData).then(data => {

        this.setState({data: data.dir});

        this.props.rest.post('track/getConfig').then(trackConfig => {

          this.context.setLoading(false);
          this.setState({trackConfig: trackConfig});
    
        }).catch(err => {
          this.context.setLoading(false);
          console.log(err);
        });

      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
      });

    }

  }

  onDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { data } = this.state;
    if (data && data.id) {
      let title: string | undefined = data.name;
      this.context.setLoading(true);

      let delData: any = { id: data.id };

      this.props.rest.post('dir/del', delData).then(data => {

        this.context.setLoading(false);

        if (data && data.status && (data.status == 'ok')) {
          this.props.enqueueSnackbar('Inhaltsverzeichnis "' + title + '" erfolgreich gelöscht', { variant: 'success' });
          this.setState({delOpen: false}, () => {
            this.props.navigate('/archive/dirs', { replace: true });
          });
        } else {
          this.props.enqueueSnackbar('Inhaltsverzeichnis konnte nicht gelöscht werden', { variant: 'error' });
        }
  
      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
      });

    }
  }

  onRenameClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { renameDirName, renameOpen, duplicateRenameOpen, data } = this.state;

    if (!renameDirName || !renameDirName.trim()) {
      this.props.enqueueSnackbar("Ein Inhaltsverzeichnis-Name muss angegeben werden", { variant: 'error' });
      return ;
    }

    if (!data || !data.id) {
      this.props.enqueueSnackbar("Das Inhaltsverzeichnis konnte nicht ermittelt werden", { variant: 'error' });
      return ;
    }

    this.context.setLoading(true);
    let dirData: any = { id: data.id, name: renameDirName };
    let url: string = "dir/" + (duplicateRenameOpen ? "copy" : "new");

    this.props.rest.post(url, dirData).then(data => {

      this.context.setLoading(false);

      if (data && data.id && data.status && data.status == "ok") {

        let duplicateRenameOpen2 = duplicateRenameOpen;

        this.props.enqueueSnackbar("Inhaltsverzeichnis erfolgreich " + (duplicateRenameOpen ? "kopiert" : "umbenannt"), { variant: 'success' });
        this.setState({renameDirName: "", renameOpen: false, duplicateRenameOpen: false}, () => {
          if (duplicateRenameOpen2) {
            this.props.navigate("/archive/dirs/" + data.id);
          } else {
            this.loadDir();
          }
        });

      } else if (data && data.status && data.status == "name_already_used") {
        this.props.enqueueSnackbar("Inhaltsverzeichnis-Name bereits verwendet", { variant: 'error' });
      } else {
        this.props.enqueueSnackbar("Inhaltsverzeichnis konnte nicht " + (duplicateRenameOpen ? "kopiert" : "umbenannt") + " werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Inhaltsverzeichnis konnte nicht " + (duplicateRenameOpen ? "kopiert" : "umbenannt") + " werden", { variant: 'error' });
    });

  }

  concatDomCboIdToNames = (domEntries: any[], selItems: number[]): string => {
    let names: string = "";
    let iNames: number = 0;
    domEntries.forEach((domEntry: any, i: number) => {
      if (selItems.indexOf(domEntry.id) > -1) {
        names = names + domEntry.name;
        if (iNames < (selItems.length - 1)) {
          names = names + ", ";
        }
        iNames++;
      }
    });
    return names;
  }

  onGenClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { data, folderGenOpen, folderGenFolder, folderGenInstr } = this.state;

    if (!folderGenFolder || (folderGenFolder.length == 0)) {
      this.props.enqueueSnackbar("Mindestens eine Mappe muss ausgewählt werden", { variant: 'error' });
      return ;
    }

    if (!folderGenInstr) {
      this.props.enqueueSnackbar("Es muss ein Instrument ausgewählt werden", { variant: 'error' });
      return ;
    }

    this.props.navigate("/pdf/" + encodeURIComponent("api/dir/gen?format=both&dir=" + data.id + "&folder=" + folderGenFolder.join(",") + "&instr=" + folderGenInstr));

  }


  render() {
    const { t } = this.props;
    const { fabOpen, delOpen, renameOpen, duplicateRenameOpen, renameDirName, data, trackConfig, folderGenOpen, folderGenFolder, folderGenInstr } = this.state;
    
    if (!data)
      return null;

    let dirFolders: string[] = [];
    if (data.directory_tracks) {
      data.directory_tracks.forEach((dir: any, i: number) => {
        if (dir.folder && dir.folder.name) {
          if (!dirFolders.includes(dir.folder.name)) {
            dirFolders.push(dir.folder.name);
          }
        }
      });
    }

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Inhaltsverzeichnis</Typography>

          <Grid container spacing={3} sx={{ mb: 3 }}>

            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="h5" color="textSecondary">Titel:</Typography>
            </Grid>

            <Grid item md={9} sm={6} xs={12}>
              <Typography variant="h4" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{data.name}</Typography>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary">Erstellt am:</Typography>
            </Grid>

            <Grid item md={9} sm={6} xs={12}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(data.created_at, true)}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>

            { /* Stuecke --------------------- */ }

          </Grid>

          {/*
            <Paper sx={{
              width: '100%', 
              overflow: 'hidden',
              mt: 5,
              mb: 5,
            }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Mappe</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Position</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Sub-Position</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Titel</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                      {data && data.directory_tracks && data.directory_tracks.map((row: any, i: number) => {
                        return (
                          <TableRow hover tabIndex={-1} key={i} onClick={() => { this.props.navigate("/archive/tracks/" + row.track.id) }} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                            <TableCell align="center">{row.folder && row.folder.name}</TableCell>
                            <TableCell align="center">{row.position}</TableCell>
                            <TableCell align="center">{row.dom_sub_position && row.dom_sub_position.name}</TableCell>
                            <TableCell align="center">{row.track && row.track.title}</TableCell>
                          </TableRow>
                        );
                      })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          */}

          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', overflowY: 'auto' }}>
            {dirFolders.map((dirFolder: string, i: number) => {
              return (
                <Paper sx={{minWidth: 300, m: 3, flexGrow: 1 }} key={i}>
                  <Typography variant="subtitle1" color="textSecondary" sx={{ m: 2, fontWeight: 'bold' }}>{dirFolder}</Typography>

                  <List>
                    {data && data.directory_tracks && data.directory_tracks.map((row: any, i2: number) => {
                      if (row.folder && (row.folder.name === dirFolder)) {
                        return (
                          <ListItemButton key={i2} onClick={() => { if (row.track && row.track.id) { this.props.navigate('/archive/tracks/' + row.track.id); } }}>
                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                              {row.position + (row.sub_position ? ('.' + row.sub_position) : '')}
                            </ListItemIcon>
                            <ListItemText
                              primary={(row.track && row.track.title) ? row.track.title : '(leer)'}
                            />
                          </ListItemButton>
                        );
                      }
                    })}
                  </List>

                </Paper>
              );
            })}
          </Box>
          
          {/*Role.isDirectoryEditor() && (
            <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

              <ButtonGroup variant="outlined">
                <Button color="primary" startIcon={<Add />} component={NavLink} to={"/archive/dirs/edit/" + data.id}>Stücke bearbeiten</Button>
              </ButtonGroup>

            </Box>
          )*/}

          <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

            <ButtonGroup variant="outlined">
              <Button component={NavLink} color="primary" startIcon={<Article />} to={"/pdf/" + encodeURIComponent("api/dir/download?dir=" + data.id)}>PDF generieren</Button>
              <Button color="secondary" startIcon={<LibraryBooks />} onClick={() => { this.setState({folderGenOpen: true, folderGenFolder: [], folderGenInstr: ""}); }}>Mappen-PDF generieren</Button>
            </ButtonGroup>

          </Box>


          <Backdrop open={fabOpen} />

          {Role.isDirectoryEditor() && (

            <Box sx={{ height: 330, transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: 30, right: 30 }}>
              
              <SpeedDial
                ariaLabel="Inhaltsverzeichnis bearbeiten"
                sx={{ position: 'absolute', bottom: 0, right: 0 }}
                icon={<SpeedDialIcon icon={<Edit />} openIcon={<Close />} />}
                onClose={() => { this.setState({fabOpen: false}); }}
                onOpen={() => { this.setState({fabOpen: true}); }}
                open={fabOpen}
                FabProps={{ color: "secondary" }}
              >
                  <SpeedDialAction
                    icon={<Add />}
                    tooltipTitle="Stücke bearbeiten"
                    tooltipOpen
                    onClick={() => { this.props.navigate("/archive/dirs/edit/" + data.id); }}
                  />
                  <SpeedDialAction
                    icon={<ContentCopy />}
                    tooltipTitle="Inhaltsverzeichnis kopieren"
                    tooltipOpen
                    onClick={() => { this.setState({fabOpen: false, duplicateRenameOpen: true, renameDirName: (data.name || "")}); }}
                  />
                  <SpeedDialAction
                    icon={<Edit />}
                    tooltipTitle="Umbenennen"
                    tooltipOpen
                    onClick={() => { this.setState({fabOpen: false, renameOpen: true, renameDirName: (data.name || "")}); }}
                  />
                  <SpeedDialAction
                    icon={<Delete />}
                    tooltipTitle="Löschen"
                    tooltipOpen
                    onClick={() => { this.setState({fabOpen: false, delOpen: true}); }}
                  />
              </SpeedDial>
            </Box>

          )}

            <Dialog
              open={folderGenOpen}
              onClose={() => { this.setState({folderGenOpen: false, folderGenFolder: [], folderGenInstr: ""}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='md'
            >
              <form onSubmit={this.onGenClicked}>
                <DialogTitle>Mappen-PDF generieren</DialogTitle>
                <DialogContent>

                  <Grid container spacing={2} sx={{mt: 1}}>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Mappen</InputLabel>
                          <Select 
                            multiple 
                            value={folderGenFolder} 
                            onChange={e => { this.setState({folderGenFolder: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                            renderValue={(selected) => this.concatDomCboIdToNames(trackConfig.folders, selected)} 
                            >
                            {trackConfig && trackConfig.folders && trackConfig.folders.map((ifolder: any, i: number) => {
                              if (dirFolders.includes(ifolder.name)) {
                                return (
                                  <MenuItem value={ifolder.id} key={ifolder.id}>
                                    <Checkbox checked={folderGenFolder.indexOf(ifolder.id) > -1} />
                                    <ListItemText primary={ifolder.name} />
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Instrument</InputLabel>
                          <Select value={folderGenInstr} onChange={e => { this.setState({folderGenInstr: e.target.value}); }} >
                            <MenuItem value="">&nbsp;</MenuItem>
                            {trackConfig && trackConfig.directories && trackConfig.instruments.map((iinstr: any, i: number) => {
                              return (<MenuItem value={iinstr.id} key={iinstr.id}>{t('instrument.' + iinstr.name)}</MenuItem>);
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                  </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({folderGenOpen: false, folderGenFolder: [], folderGenInstr: ""}); }}>Abbrechen</Button>
                    <Button type="submit">Generieren</Button>
                </DialogActions>
              </form>
          </Dialog>

          <Dialog
              open={delOpen}
              onClose={() => { this.setState({delOpen: false}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='sm'
            >
              <form onSubmit={this.onDelete}>
                <DialogTitle>Inhaltsverzeichnis löschen</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle1">Inhaltsverzeichnis "{data.name}" wirklich löschen?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({delOpen: false}); }}>Abbrechen</Button>
                    <Button type="submit">Löschen</Button>
                </DialogActions>
              </form>
          </Dialog>


          <Dialog
              open={renameOpen || duplicateRenameOpen}
              onClose={() => { this.setState({renameOpen: false, duplicateRenameOpen: false}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
              maxWidth='sm'
            >
              <form onSubmit={this.onRenameClicked}>
                <DialogTitle>Inhaltsverzeichnis {duplicateRenameOpen ? 'kopieren' : 'umbenennen'}</DialogTitle>
                <DialogContent>

                  <Grid container spacing={2} sx={{mt: 1}}>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Inhaltsverzeichnis"
                        autoFocus
                        value={renameDirName}
                        onChange={event => { this.setState({renameDirName: event.target.value}) } }
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>

                  </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({renameOpen: false, duplicateRenameOpen: false, renameDirName: ""}); }}>Abbrechen</Button>
                    <Button type="submit">{duplicateRenameOpen ? 'Kopieren' : 'Umbenennen'}</Button>
                </DialogActions>
              </form>
          </Dialog>
        
        </Container>
    );
  }

}

export default withHocs(Dir);