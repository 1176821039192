import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import withRouter, { WithRouter } from './router';
import withRest, { WithRest } from '../rest';
import withCache, { WithCache } from './cache';
//import withLog, { WithLog } from './log';
import { withSnackbar, WithSnackbarProps } from 'notistack';

export interface WithHocs extends WithRouter, WithTranslation, WithRest, WithCache, WithSnackbarProps/*, WithLog*/ { };

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Diff<T, K> = Omit<T, keyof K>;

type WithoutHocsProps<T> = Diff<T, WithHocs>;

export const withHocs = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) => {
    class Hocs extends React.Component<WithoutHocsProps<P> & WithHocs> {

        static displayName = "Hocs";

        constructor(props: any) {
            super(props);
        }

        render() {
            return (
                <WrappedComponent {...this.props as P} />
            );
        }

    }
    

    return withSnackbar(
        withRest(
            //withLog((WrappedComponent.displayName || WrappedComponent.name || 'Component'),
                withCache((WrappedComponent.displayName || WrappedComponent.name || 'Component'),
                    withTranslation()(
                        withRouter(Hocs)
                    )
                )
            //)
        )
    );

};

export default withHocs;