import React from 'react';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Close from '@mui/icons-material/Close';

import { AppContext } from '../../context';
import Role from '../../components/role';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';


interface Props {
    addId?: any,
    addOpen: boolean,
    addBeginDate?: moment.Moment | undefined | null,
    onClose: (id?: any) => void,
}

interface State {
    addTitle: string,
    addBeginDate: moment.Moment | undefined | null,
    addEndDate: moment.Moment | undefined | null,
    addLocation: string,
    searchLocation: any[],
    addEventType: string,
    eventTypes: any[],
    addDescription: string,
}


class CalendarEventEditDialog extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        addTitle: "",
        addBeginDate: null,
        addEndDate: null,
        addLocation: "",
        searchLocation: [],
        addEventType: "",
        eventTypes: [] as any[],
        addDescription: "",
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { 
        if (this.props.addOpen) {
            this.searchEvent();
        }
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
        if (this.props && this.props.addOpen && ((prevProps && (this.props.addOpen !== prevProps.addOpen)) || !(prevProps && prevProps.addOpen))) {
            
            this.searchEvent();
        }
    }

    searchEvent = () => {
        const { addId, addOpen, addBeginDate } = this.props;

        this.setState({ addTitle: "", addBeginDate: null, addEndDate: null, addLocation: "", searchLocation: [], eventTypes: [], addEventType: "", addDescription: "" });

        if (addOpen) {
            this.context.setLoading(true);
            this.props.rest.post("calendar/getConfig").then(configData => {

                if (configData && configData.status && configData.status == "ok") {

                    if (addId) {
                
                        this.context.setLoading(true);
                        let eventData: any = { id: addId };
                    
                        this.props.rest.post("calendar/get", eventData).then(data => {
                    
                            this.context.setLoading(false);
                        
                            if (data && data.event && data.status && data.status == "ok") {
                        
                                this.setState({addTitle: data.event.title || "", addBeginDate: (data.event.begin_date ? Util.isoStringToDate(data.event.begin_date) : null), addEndDate: (data.event.end_date ? Util.isoStringToDate(data.event.end_date) : null), addLocation: data.event.location || "", eventTypes: ((configData && configData.event_types) ? configData.event_types : []), addEventType: (data.event.dom_event_type ? data.event.dom_event_type.id : ""), addDescription: data.event.description || ""});
                                console.log(data);
                        
                            } else {
                                this.props.enqueueSnackbar("Termin konnten nicht gesucht werden", { variant: 'error' });
                            }
                    
                        }).catch(err => {
                            this.context.setLoading(false);
                            console.log(err);
                            this.props.enqueueSnackbar("Termin konnten nicht gesucht werden", { variant: 'error' });
                        });

                    } else if (addBeginDate) {
                        this.context.setLoading(false);
                        this.setState({addBeginDate: addBeginDate, eventTypes: ((configData && configData.event_types) ? configData.event_types : [])});
                    }

                } else {
                    this.context.setLoading(false);
                    this.props.enqueueSnackbar("Erforderliche Termin-Daten konnten nicht abgerufen werden", { variant: 'error' });
                }

            }).catch(err => {
                this.context.setLoading(false);
                console.log(err);
                this.props.enqueueSnackbar("Erforderliche Termin-Daten konnten nicht abgerufen werden", { variant: 'error' });
            });
        }
    }

    async search(uri: string, data: any) {
        return this.props.rest.post(uri, data);
    }

    searchLocation = debounce(async () => { 
        const { addLocation } = this.state;
        if (addLocation) {
            this.setState({searchLocation: 
                await this.search('calendar/searchLocation', {location: addLocation})
            });
        }
    }, 450);

    onAddClicked = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { addId } = this.props;
        const { addTitle, addLocation, addBeginDate, addEndDate, addEventType, addDescription } = this.state;
    
        if (!addTitle || !addTitle.trim()) {
          this.props.enqueueSnackbar("Ein Termin-Titel muss angegeben werden", { variant: 'error' });
          return ;
        }

        if (!addBeginDate) {
            this.props.enqueueSnackbar("Ein Termin-Beginn muss angegeben werden", { variant: 'error' });
            return ;
        }
    
        this.context.setLoading(true);
        let evtData: any = { id: addId, title: addTitle, location: addLocation, begin_date: Util.dateToIsoString(addBeginDate), end_date: Util.dateToIsoString(addEndDate), dom_event_type_id: addEventType, description: addDescription };
    
        this.props.rest.post("calendar/new", evtData).then(data => {
    
          this.context.setLoading(false);
    
          if (data && data.status && data.status == "ok") {
    
            this.props.enqueueSnackbar("Termin erfolgreich " + (addId ? "bearbeitet" : "angelegt"), { variant: 'success' });
            this.setState({addTitle: "", addLocation: "", searchLocation: [], addBeginDate: null, addEndDate: null, addEventType: "", addDescription: ""}, () => {
                this.props.onClose(data.id);
            });
    
          } else {
            this.props.enqueueSnackbar("Termin konnte nicht " + (addId ? "bearbeitet" : "angelegt") + " werden", { variant: 'error' });
          }
    
        }).catch(err => {
          this.context.setLoading(false);
          console.log(err);
          this.props.enqueueSnackbar("Termin konnte nicht " + (addId ? "bearbeitet" : "angelegt") + " werden", { variant: 'error' });
        });
    
    }

    render() {
        const { t } = this.props;
        const { addId, addOpen } = this.props;
        const { addTitle, addBeginDate, addEndDate, addLocation, searchLocation, eventTypes: [], addEventType, eventTypes, addDescription } = this.state;

        return (

            <Dialog
                open={addOpen}
                onClose={() => { this.props.onClose(); }}
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='md'
            >
                <form onSubmit={this.onAddClicked}>
                    <DialogTitle>Termin {addId ? "bearbeiten" : "anlegen"}</DialogTitle>
                    <DialogContent>
                    
                    <Grid container spacing={2} sx={{mt: 1}}>
                    
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Titel"
                                autoFocus
                                value={addTitle}
                                onChange={event => { this.setState({addTitle: event.target.value}) } }
                                inputProps={{ maxLength: 500 }}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                freeSolo
                                disableClearable
                                options={searchLocation.map((option: any) => option.location)}
                                autoComplete
                                inputValue={addLocation}
                                onInputChange={(event, value) => { 
                                    this.setState({addLocation: value, searchLocation: []}, this.searchLocation); 
                                }}
                                fullWidth
                                renderInput={(params) => {
                                    const { inputProps, ...rest } = params;
                                    return (<TextField label="Ort" inputProps={{ ...inputProps, maxLength: 255 }} {...rest} />);
                                }}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Kategorie</InputLabel>
                                <Select value={addEventType} onChange={e => { this.setState({addEventType: e.target.value}); }} >
                                    <MenuItem value="">&nbsp;</MenuItem>
                                    {eventTypes && eventTypes.map((et: any, i: number) => {
                                        return (<MenuItem value={et.id} key={et.id}>{t('event_type.' + et.name)}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <DateTimePicker
                                label="Beginn"
                                inputFormat="DD.MM.YYYY HH:mm"
                                mask="__.__.____ __:__"
                                renderInput={(params: any) => <TextField {...params} fullWidth />}
                                value={addBeginDate}
                                onChange={(date: any, keyboardInputValue: any) => { this.setState({addBeginDate: date}); }}
                                ampm={false}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <DateTimePicker
                                label="Ende"
                                inputFormat="DD.MM.YYYY HH:mm"
                                mask="__.__.____ __:__"
                                renderInput={(params: any) => <TextField {...params} fullWidth />}
                                value={addEndDate}
                                onChange={(date: any, keyboardInputValue: any) => { this.setState({addEndDate: date}); }}
                                ampm={false}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Beschreibung"
                                value={addDescription}
                                onChange={event => { this.setState({addDescription: event.target.value}) } }
                                inputProps={{ maxLength: 2500 }}
                                multiline
                            />
                        </Grid>

                    </Grid>
                    

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.props.onClose(); }}>Abbrechen</Button>
                        <Button type="submit">{addId ? "Bearbeiten" : "Anlegen"}</Button>
                    </DialogActions>

                </form>
            </Dialog>
        );
    }

}

export default withHocs(CalendarEventEditDialog);