import React from 'react';
import { NavLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';

import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness2 from '@mui/icons-material/Brightness2';
import BrightnessAuto from '@mui/icons-material/BrightnessAuto';

import { AppContext } from './context';
import { withHocs, WithHocs } from './components/hocs';


//import '@pwabuilder/pwainstall';


interface State {
  }

interface Props {
    children?: React.ReactNode,
    title?: string,
}

class LoginWrapper extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: State = { 
    };
  
    constructor(props: any) {
      super(props);
    }
  
    render() {
      const { version, themeMode } = this.context;
      const { title, children } = this.props;

      return (
        <Container component="main" maxWidth="sm" sx={{ height: '100vh' }}>
            
            
            <Grid container sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Grid item sx={{ flexShrink: 1, flexGrow: 1 }} />
                <Grid item sx={{ flexShrink: 0, flexGrow: 1 }}>
                    <Paper sx={{ p: 4 }} elevation={5}>
                        <Typography variant="h4" color="primary" align="left" sx={{ fontFamily: 'Dancing Script', fontSize: 48 }}>
                            <Box component="span" sx={{ fontFamily: 'Dancing Script', fontSize: 64, fontWeight: 'bold' }}>M</Box>eine&nbsp;
                            <Box component="span" sx={{ fontFamily: 'Dancing Script', fontSize: 64, fontWeight: 'bold' }}>M</Box>usik
                        </Typography>
                        <Box ml={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant="h5" color="textSecondary">
                                    {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sx={{ position: 'relative' }}>
                                    <Box sx={{ 
                                        position: 'absolute', 
                                        bottom: 0, 
                                        right: 0, 
                                    }}>
                                        <Tooltip TransitionComponent={Zoom} title={(themeMode.indexOf('auto') > -1) ? "Auto-Mode" : ((themeMode.indexOf('light') > -1) ? "Light-Mode" : "Dark-Mode")} placement="left" arrow>
                                            <IconButton
                                                aria-label="theme-switcher"
                                                onClick={() => { this.context.switchThemeMode(); }}
                                                color="inherit"
                                                component="span"
                                            >
                                                {(themeMode.indexOf('auto') > -1) 
                                                    ? <BrightnessAuto /> : 
                                                        ((themeMode.indexOf('light') > -1) 
                                                        ? <BrightnessLow />
                                                        : <Brightness2 />)
                                                }
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                {/*
                                <Grid item xs={2} sx={{ position: 'relative' }}>
                                    <Box sx={{ 
                                        position: 'absolute', 
                                        bottom: 0, 
                                        right: 0, 
                                    }}>
                                        <IconButton aria-label="info popup" component="span" onClick={() => { this.setState({infoOpen: true}) }}>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>*/}
                            </Grid>
                        </Box>
                        { children }
                    </Paper>

                    <Box sx={{ 
                        textAlign: 'center',
                        pt: 2,
                    }}>
                        <Typography variant="subtitle1" gutterBottom>
                            <Link component={NavLink} to="/terms" target="_blank" underline="hover" variant="body2" color="textSecondary">Nutzungsbedingungen</Link>
                            {" - "}
                            <Link component={NavLink} to="/legalnotice" target="_blank" underline="hover" variant="body2" color="textSecondary">Impressum</Link>
                        </Typography>
                    </Box>

                </Grid>
                <Grid item sx={{ flexShrink: 1, flexGrow: 2 }} />
            </Grid>

        </Container>
      );
    }
  }

export default withHocs(LoginWrapper);