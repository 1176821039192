import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import moment from 'moment-timezone';
import 'moment/locale/de-at';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { I18nextProvider } from 'react-i18next';

import App from './app';
import { AppProvider } from './context';
import Theme from './theme';
import i18n from './lang/i18n';

//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/dancing-script/400.css';
import '@fontsource/dancing-script/500.css';
import '@fontsource/dancing-script/600.css';
import '@fontsource/dancing-script/700.css';

import './app.css';

//(window as any).axios = require('axios');
//(window as any).axios.defaults.withCredentials = true;
//(window as any).axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

moment.locale('de');
moment.tz.setDefault('Europe/Vienna');


const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
root.render(
    <AppProvider>
        <I18nextProvider i18n={i18n}>
            <Theme>
                <SnackbarProvider maxSnack={10}>
                    <CssBaseline />
                    <StyledEngineProvider injectFirst>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <App />
                        </LocalizationProvider>
                    </StyledEngineProvider>
                </SnackbarProvider>
            </Theme>
        </I18nextProvider>
    </AppProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
