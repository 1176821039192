import React from 'react';
import axios from 'axios';

import { AppProvider, AppContext } from './context';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//let baseUrl = "http://localhost:80/backend/";
//let baseUrl = "https://www.tk-bad-grosspertholz.at/backend/";
let baseUrl = "api/";

export interface RestInterface {
    post(uri: string, data?: any): Promise<any>,
    postForm(uri: string, data?: FormData): Promise<any>,
    get(uri: string): Promise<any>,
    //getFile(uri: string): Promise<any>,
}

export interface WithRest {
    rest: RestInterface
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Diff<T, K> = Omit<T, keyof K>;

type WithoutRestProps<T> = Diff<T, WithRest>;

const withRest = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) => {
    class Rest extends React.Component<WithoutRestProps<P>> {

        static contextType = AppContext;
        context!: React.ContextType<typeof AppContext>;

        static displayName = "Rest";

        constructor(props: any) {
            super(props);
        }

        render() {
            return (
                <WrappedComponent rest={this} {...this.props as P} />
            );
        }

        async postForm(uri: string, data?: FormData) {
            let response: Response;
            try {
                response = await axios.post(baseUrl + uri, data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
            } catch(reqErr: any) {
                response = reqErr.response;
            }
            return this.handleHttpStatus(response);
        }

        async post(uri: string, data?: any) {
            let response: Response;
            try {
                response = await axios.post(baseUrl + uri, data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            } catch(reqErr: any) {
                response = reqErr.response;
            }

            return this.handleHttpStatus(response);

        }

        async get(uri: string) {
            let response: Response;
            try {
                response = await axios.get(baseUrl + uri);
                return response;
            } catch(reqErr: any) {
                response = reqErr.response;
            }
            return this.handleHttpStatus(response);
        }


        /*async getFile(uri: string) {
            let response: Response;
            try {
                response = await this.withTimeout(fetch(baseUrl + uri, {
                    method: 'GET',
                    credentials: 'include',
                    redirect: 'follow',
                }));
            } catch(reqErr) {
                throw reqErr;
            }

            let httpOk: boolean  = await response.ok;
            let httpStatus: number  = await response.status;
            let httpType: string  = response.type;
            let contentType: string | null = response.headers.get("Content-Type");
            let fileName: string = uri;

            if (fileName.indexOf('/') >= 0) {
                fileName = fileName.substr(fileName.lastIndexOf('/') + 1);
            }

            if ((httpType === "opaque") || httpOk) {
                let responseBlob: Blob = await response.blob();
                if (responseBlob) {
                    try {
                        console.log(responseBlob);
                        let f: File = new File([responseBlob], fileName, { type: contentType ? contentType : undefined });
                        return f;
                    } catch(e) {
                        throw e;
                    }
                } else {
                    return null;
                }
            } else {
                this.handleHttpStatus(httpStatus);
                return null;
            }
        }*/


        handleHttpStatus = (response: any): any => {
            if (response && response.status) {
                if (response.status >= 200 && response.status < 300) {
                    return response.data;
                } else if (response.status == 401) {
                    this.navigateToLogin();
                } else if (response.status == 419) {
                    window.location.reload();
                }
            }
            throw response.data;
        }

        navigateToLogin = () => {
            this.context.setLoading(false);
            this.context.setLoggedIn(false);
        }

    }

    return Rest;
}

export default withRest;