import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce, isUndefined } from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/icons-material/Menu';
import Edit from '@mui/icons-material/Edit';
import Person from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';

import { DndContext, DragEndEvent, DragStartEvent, DragOverlay, closestCorners } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';

import DragListItem from '../../components/dragListItem';
import DropList from '../../components/dropList';
import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import TextDivider from '../../components/textDivider';
import Dropzone, { FileInput } from '../../components/dropzone';
import { userInfo } from 'os';
import FcmService from '../../components/fcmService';

interface Props {
  children?: React.ReactNode,
  open: boolean,
  onClose: (id?: any) => void,
  id?: number,
}

interface State {
  questionTitle: string,
  questionAnonymous: boolean,
  questionOptions: any[],
  showDelDialog: any,
  dragging: string | number,
}


class QuestionEdit extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: State = { 
    questionTitle: "",
    questionAnonymous: false,
    questionOptions: [],
    showDelDialog: undefined as any,
    dragging: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps: WithHocs & Props): void {
      if (prevProps.open !== this.props.open) {
        this.setState({questionTitle: "", questionAnonymous: false, questionOptions: []}, () => {
          if (this.props.open) {
            this.loadQuestion();
          }
        });
      }
  }

  loadQuestion = () => {

    if (this.props.id) {
        let questionData = { id: this.props.id };

        this.context.setLoading(true);

        this.props.rest.post("question/get", questionData).then(data => {

            this.context.setLoading(false);

            if (data && data.status && data.status == "ok") {

            this.setState({
                questionTitle: (data.question.title || ""),
                questionAnonymous: (data.question.anonymous ? true : false),
                questionOptions: (data.question.question_options || [])
            });
            
            } else {
                this.props.enqueueSnackbar("Umfrage konnte nicht ausgelesen werden", { variant: 'error' });
            }

        }).catch(err => {
            this.context.setLoading(false);
            console.log(err);
            this.props.enqueueSnackbar("Umfrage konnte nicht ausgelesen werden", { variant: 'error' });
        });

    }

  }

  onDelete = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const { showDelDialog, questionOptions } = this.state;

    let i: number = questionOptions.indexOf(showDelDialog);
    if (showDelDialog.new) {
        questionOptions.splice(i, 1);
    } else {
        questionOptions[i].del = true;
    }

    this.setState({questionOptions: questionOptions, showDelDialog: undefined});
  }

  onAdd = () => {
    const { questionOptions } = this.state;

    let id: number = -1;
    if (questionOptions) {
        questionOptions.forEach((d: any, i: number) => {
        if (d.id <= id) {
          id = (d.id - 1);
        }
      });
    }

    questionOptions.push({id: id, name: '', new: true});
    this.setState({questionOptions: questionOptions});
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id } = this.props;
    const { questionTitle, questionAnonymous } = this.state;

    let questionOptions = this.state.questionOptions;

    if (!questionTitle || !questionTitle.trim()) {
        this.props.enqueueSnackbar("Es muss ein Titel angegeben werden", { variant: 'error' });
        return ;
    }


    let emptyError: boolean = false;

    let iDel: number = 1;
    let iVisible: number = 0;

    questionOptions.forEach((d: any, i: number) => {
      if (!d.del) {
        questionOptions[i].position = iDel;
        iDel++;
        iVisible++;
        if (!d.name || !d.name.trim()) {
          emptyError = true;
        }
      } else {
        questionOptions[i].name = 'Del';
        questionOptions[i].position = -1;
      }
    });
    
    if (emptyError) {
      this.props.enqueueSnackbar("Es muss ein Options-Name angegeben werden", { variant: 'error' });
      return ;
    }

    if (iVisible == 0) {
      this.props.enqueueSnackbar("Es muss mindestens eine Option angegeben werden", { variant: 'error' });
      return ;
    }


    this.context.setLoading(true);

    let questionData: any = { id: id, title: questionTitle, anonymous: questionAnonymous, options: questionOptions };

    this.props.rest.post("question/new", questionData).then(data => {

      this.context.setLoading(false);

      if (data && data.id && data.status && data.status == "ok") {
        this.props.enqueueSnackbar("Umfrage wurden erfolgreich " + (id ? "aktualisiert" : "erstellt"), { variant: 'success' });
        this.props.onClose(data.id);
      } else {
        this.props.enqueueSnackbar("Umfrage konnten nicht " + (id ? "aktualisiert" : "erstellt") + " werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Umfrage konnten nicht " + (id ? "aktualisiert" : "erstellt") + " werden", { variant: 'error' });
    });

  }

  render() {
    const { open, id } = this.props;
    const { questionTitle, questionAnonymous, questionOptions, showDelDialog, dragging } = this.state;

    let draggingElement: JSX.Element | undefined = undefined;

    let options: any[] = [];

    if (questionOptions) {
      options = questionOptions.filter((o: any) => { 
        return !o.del; 
      });
    }

    return (
      <React.Fragment>
        <Dialog
            open={open}
            onClose={() => { this.props.onClose(); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='sm'
        >
      
            <DialogTitle>Umfrage {id ? "bearbeiten" : "erstellen"}</DialogTitle>
            <form onSubmit={this.onSubmit}>
                <DialogContent>
                
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Titel"
                                autoFocus
                                value={questionTitle}
                                onChange={event => { this.setState({questionTitle: event.target.value}) } }
                            />
                        </Grid>

                        <Grid item xs={12}>

                            <Box>
                                <Box maxWidth="sm" sx={{ margin: '0 auto' }}>
                                <Paper>

                                    <DndContext collisionDetection={closestCorners} modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}
                                        autoScroll={{
                                            threshold: {
                                            x: 0,
                                            y: 0.02,
                                            },
                                        }}
                                    >

                                    <SortableContext 
                                        id='options'
                                        items={options.map((o: any, i: number) => { return o.id.toString(); })}
                                    >

                                        <DropList id='options'>
                                        
                                        {options && options.map((o: any, i: number) => {
                                            if (o.del) {
                                                return null;
                                            }
                                            let li: JSX.Element = (
                                                <DragListItem key={o.id} id={o.id.toString()}
                                                listItemProps={{
                                                    secondaryAction: 
                                                    (<IconButton edge="end" onClick={e => { this.setState({showDelDialog: o}, () => { if (o.new) { this.onDelete(); } }); }}>
                                                        <Delete />
                                                    </IconButton>)
                                                }}
                                                >

                                                <Grid container>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            value={o.name}
                                                            onChange={e => { questionOptions[i].name = e.target.value; this.setState({questionOptions: questionOptions}); }}
                                                            label="Name"
                                                            fullWidth
                                                            variant="standard"
                                                            inputProps={{ maxLength: 255 }}
                                                        />
                                                    </Grid>

                                                </Grid>

                                                </DragListItem>
                                            );
                                            if (dragging == o.id.toString()) {
                                                draggingElement = li;
                                            }
                                            return (li);
                                        })} 

                                        </DropList>
                                        
                                    </SortableContext>

                                    <DragOverlay>
                                        {draggingElement && React.cloneElement(draggingElement)}
                                    </DragOverlay>

                                    </DndContext>

                                    <List sx={{ mt: 3 }}>

                                        <ListItemButton onClick={() => { this.onAdd(); }}>
                                            <ListItemIcon>
                                                <Add />
                                            </ListItemIcon>
                                            <ListItemText primary="Option hinzufügen" />
                                        </ListItemButton>

                                    </List>
                                </Paper>
                                </Box>
                            </Box>

                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={questionAnonymous}
                                        onChange={evt => { this.setState({questionAnonymous: evt.target.checked}) }}
                                        color="primary"
                                    />
                                }
                                label="anonyme Umfrage"
                                disabled={id ? true : false}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.props.onClose(); }}>Abbrechen</Button>
                    <Button type="submit">{id ? "Bearbeiten" : "Erstellen"}</Button>
                </DialogActions>

            </form>

        </Dialog>

        <Dialog
            open={showDelDialog ? true : false}
            onClose={() => { this.setState({showDelDialog: undefined}); }}
            TransitionComponent={DialogTransition}
            fullWidth={true}
            maxWidth='sm'
        >
            <form onSubmit={this.onDelete}>
            <DialogTitle>Option löschen</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">Option "{showDelDialog && showDelDialog.name}" wirklich löschen?</Typography>
                <Typography variant="subtitle2">Dadurch werden auch alle für diese Option bereits abgegebenen Stimmen zurückgesetzt.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { this.setState({showDelDialog: undefined}); }}>Abbrechen</Button>
                <Button type="submit">Löschen</Button>
            </DialogActions>
            </form>
        </Dialog>

      </React.Fragment>
    );
  }

  handleDragStart = (event: DragStartEvent) => {
    this.setState({dragging: event.active.id});
  }

  handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    let questionOptions = this.state.questionOptions;

    this.setState({dragging: ""});

    let oldIndex: number = this.getIndexById(active.id) || 0;
    let newIndex: number = over ? (this.getIndexById(over.id) || 0) : questionOptions.length;
    
    this.setState({questionOptions: arrayMove(questionOptions, oldIndex, newIndex)});
  }

  getIndexById = (id: string | number): number | undefined => {
    const { questionOptions } = this.state;
    let res: number | undefined = undefined;

    if (questionOptions && id) {
        questionOptions.forEach((d: any, i: number) => {
        if (d.id.toString() === id) {
            res = i;
        }
      });
    }

    return res;
  }

}

export default withHocs(QuestionEdit);