import React from 'react';
import moment from 'moment-timezone';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { DatePicker } from '@mui/x-date-pickers';

import Search from '@mui/icons-material/Search';

import { AppContext } from '../../context';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';


interface TRow {
  id: number,
  archivNr?: number,
  title: string,
  composer?: string,
  arrangement?: string,
  publisher?: string,
  cat?: string,
  orchSize?: string,
}


interface Props {
  children?: React.ReactNode,
}

interface State {
  tableData: any,
  page: number,
  rowsPerPage: number,

  from: moment.Moment | undefined | null,
  to: moment.Moment | undefined | null,
}


class ProgramAkm extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state: State = { 
    tableData: undefined as any,
    page: 0,
    rowsPerPage: 25,

    from: null,
    to: null,
  };

  constructor(props: any) {
    super(props);

    let d: moment.Moment = moment().startOf('year');
    this.state.from = d;

    d = moment().endOf('year');
    this.state.to = d;
  }

  componentDidMount() { 
    this.searchTracks(true);
  }

  searchTracks = (fresh: boolean, e?: React.FormEvent) => {
    e?.preventDefault();

    const { rowsPerPage, from, to } = this.state;

    let page: number = (fresh ? 0 : this.state.page);
    if (fresh) {
      this.setState({tableData: undefined, page: page});
    }

    this.context.setLoading(true);
    let programData: any = { page: (page + 1), rowsPerPage: rowsPerPage, from: Util.dateToIsoString(Util.dateToStartOfDayDate(from)), to: Util.dateToIsoString(Util.dateToEndOfDayDate(to))};

    this.props.rest.post("program/akm", programData).then(data => {

      this.context.setLoading(false);

      if (data && data.tracks && data.status && data.status == "ok") {

        this.setState({tableData: data.tracks});
        console.log(data);

      } else {
        this.props.enqueueSnackbar("Stücke konnten nicht gesucht werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Stücke konnten nicht gesucht werden", { variant: 'error' });
    });

  }

  render() {
    const { tableData, page, rowsPerPage, from, to } = this.state;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>AKM</Typography>

          
          <form action="" method="POST" onSubmit={e => { this.searchTracks(true, e); }}>

            <Paper sx={{ p: 2 }}>

              <Grid container spacing={2}>

                <Grid item md={6} xs={12}>
                    <DatePicker
                      label="Von Datum"
                      inputFormat="DD.MM.YYYY"
                      mask="__.__.____"
                      renderInput={(params: any) => <TextField {...params} fullWidth />}
                      value={from}
                      onChange={(date: any, keyboardInputValue: any) => { this.setState({from: date}); }}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <DatePicker
                      label="Bis Datum"
                      inputFormat="DD.MM.YYYY"
                      mask="__.__.____"
                      renderInput={(params: any) => <TextField {...params} fullWidth />}
                      value={to}
                      onChange={(date: any, keyboardInputValue: any) => { this.setState({to: date}); }}
                    />
                </Grid>

                <Grid item xs={12} textAlign="center">
                  <Button type="submit" variant="contained" startIcon={<Search />} size="large">Suchen</Button>
                </Grid>

              </Grid>

            </Paper>

          </form>









        {tableData && tableData.data && tableData.data.length > 0 && (

          <Paper sx={{
            width: '100%', 
            overflow: 'hidden',
            mt: 5,
            mb: 5,
          }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Archiv-Nr.</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Titel</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Komponist</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Arrangement</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Anzahl Aufführungen</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>aufgeführt im Jahr</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>AKM-ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                    {tableData && tableData.data && tableData.data.map((row: any, i: number) => {
                      let years: number[] = [];
                      if (row.program_tracks && row.program_tracks.length > 0) {
                        row.program_tracks.forEach((pt: any) => {
                          if (pt && pt.program && pt.program.begin_date) {
                            let year: number | undefined = Util.isoStringToDate(pt.program.begin_date)?.year();
                            if (year && (!years.includes(year))) {
                              years.push(year);
                            }
                          }
                        });
                      }

                      return (
                        <TableRow hover tabIndex={-1} key={i} onClick={() => { this.props.navigate("/archive/tracks/" + row.id) }} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                          <TableCell align="center">{row.register_number}</TableCell>
                          <TableCell align="center">{row.title}</TableCell>
                          <TableCell align="center">{row.composer}</TableCell>
                          <TableCell align="center">{row.arrangement}</TableCell>
                          <TableCell align="center">{row.program_tracks && row.program_tracks.length > 0 && row.program_tracks.length}</TableCell>
                          <TableCell align="center">{years && years.join(", ")}</TableCell>
                          <TableCell align="center">{row.akm_id}</TableCell>
                        </TableRow>
                      );
                    })}

                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tableData && tableData.total ? tableData.total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event: React.MouseEvent | null, page: number) => { this.setState({page: page}, () => this.searchTracks(false)); }}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => { if (event?.target?.value) { this.setState({rowsPerPage: parseInt(event.target.value)}, () => this.searchTracks(false)); } }}
            />

          </Paper>

        )}

        
        </Container>
    );
  }

}

export default withHocs(ProgramAkm);