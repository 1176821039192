import React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';

import { DndContext, DragEndEvent, DragStartEvent, DragOverEvent, DragOverlay, closestCorners } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import { AppContext } from '../../context';
import DialogTransition from '../../components/transition';
import DragListItem from '../../components/dragListItem';
import DropList from '../../components/dropList';
import TrackOverview from '../tracks/trackOverview';
import { withHocs, WithHocs } from '../../components/hocs';

//import Cookies from 'js-cookie';


interface Props {
  children?: React.ReactNode,
}

interface State {
  data: any,
  dragging: string | number,

  addTrackDialogOpen: boolean,
  choosenTracks: any[],

  anchorEl?: Element,
}


class ProgramTracksEdit extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = {
    data: undefined as any,
    dragging: "",

    addTrackDialogOpen: false,
    choosenTracks: [] as any[],

    anchorEl: undefined,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadProgramTracks();
  }

  loadProgramTracks = () => {
    const { id } = this.props.params;
    if (id) {
      this.context.setLoading(true);

      let programData: any = { id: parseInt(id) };
      
      this.props.rest.post('program/getTracks', programData).then(data => {
        
        this.context.setLoading(false);
        if (data && data.status == "ok") {
          this.setState({data: data});
        }
        

      }).catch(err => {
        this.context.setLoading(false);
        console.log(err);
      });

    }

  }

  onAddDialogOpen = () => {
    this.setState({addTrackDialogOpen: true, choosenTracks: []});
  }

  addTracks = (e?: React.FormEvent<HTMLFormElement>, domPauseType?: any) => {
    e?.preventDefault();
    const { choosenTracks, data } = this.state;

    let programs = [];
    if (data.program) {
      programs.push(data.program);
    }
    if (data.shortcut_programs) {
      data.shortcut_programs.forEach((prog: any) => {
        programs.push(prog);
      });
    }

    if (domPauseType || (choosenTracks && (choosenTracks.length > 0))) {
      let lastId: number = 0;

      programs.forEach((prog: any, i: number) => {

        if (prog.program_tracks) {
          prog.program_tracks.forEach((pt: any, i2: number) => {
            if (pt && pt.id && (pt.id > lastId)) {
              lastId = pt.id;
            }
          });
        }
        
      });

      let lastPos: number = 0;
      let lastAbsPos: number = 0;
      if (!data.program.program_tracks) {
        data.program.program_tracks = [];
      }
      data.program.program_tracks.forEach((pt: any, i2: number) => {
        if (pt.position) {
          lastPos = pt.position;
        }
        lastAbsPos = pt.abs_position;
      });

      let newPos: number = (lastPos + 1);
      let newAbsPos: number = (lastAbsPos + 1);
      let newId: number = (lastId + 1);
      if (domPauseType) {
        data.program.program_tracks.push({id: newId, new: true, position: undefined, abs_position: newAbsPos, track: undefined, dom_pause_type: domPauseType});
      } else {
        choosenTracks.forEach((ct: any, i2: number) => {
            data.program.program_tracks.push({id: newId, new: true, position: newPos, abs_position: newAbsPos, track: { id: ct.id, title: ct.title }, dom_pause_type: undefined});
            newId++;
            newPos++;
            newAbsPos++;
        });
      }

      data.program = this.reorderProgram(data.program);

    }

    this.setState({data: data, addTrackDialogOpen: false, choosenTracks: []});
  }

  onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    
    const { data } = this.state;
    const { id } = this.props.params;

    console.log(data);


    this.context.setLoading(true);
    let progTrackData: any = { program_id: id, program: data.program };

    this.props.rest.post("program/changeTracks", progTrackData).then(res => {

      this.context.setLoading(false);

      if (res && res.status && res.status == "ok") {
        this.props.enqueueSnackbar("Stücke erfolgreich bearbeitet", { variant: 'success' });
        this.loadProgramTracks();
      } else {
        this.props.enqueueSnackbar("Stücke konnten nicht bearbeitet werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Stücke konnte nicht bearbeitet werden", { variant: 'error' });
    });

  }

  render() {
    const { t } = this.props;
    const { data, dragging, addTrackDialogOpen, choosenTracks, anchorEl } = this.state;

    if (!data)
      return null;

    const { program_tracks } = data;

    let draggingElement: JSX.Element | undefined = undefined;

    let mainProgramTracks: any[] = [];

    if (data && data.program && data.program.program_tracks) {
      mainProgramTracks = data.program.program_tracks.filter((progTrack: any) => {
        return !progTrack.del;
      });
    }

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Programm</Typography>


          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>

          <DndContext collisionDetection={closestCorners} onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart} onDragOver={this.handleDragOver}
                autoScroll={{
                  threshold: {
                    x: 0,
                    y: 0.02,
                  },
                }}
              >
          
            <Paper sx={{minWidth: 420, m: 3, flexGrow: 1 }}>
              <Typography variant="subtitle1" color="textSecondary" sx={{ m: 2, fontWeight: 'bold' }}>{data && data.program && data.program.title}</Typography>
                

              <SortableContext 
                id={'program-main'}
                items={
                    mainProgramTracks.map((progTrack: any, i2: number) => {
                        return progTrack.id.toString();
                    })
                }>

                  <DropList id='program-main'>
                  
                    {data && data.program && data.program.program_tracks && data.program.program_tracks.map((progTrack: any, i2: number) => {

                      if (progTrack.del) {
                        return null;
                      }
                        
                      let li: JSX.Element = (
                        <DragListItem key={progTrack.id} id={progTrack.id.toString()} 
                          listItemProps={{
                            secondaryAction: 
                              (<IconButton edge="end" onClick={e => { this.delProgTrack(i2 /*,progTrack.id.toString()*/); }}>
                                <Delete />
                              </IconButton>)
                          }}
                        >

                          <ListItemText
                            primary={(progTrack.track && progTrack.track.title) ? progTrack.track.title : (progTrack.dom_pause_type ? t('pause_type.' + progTrack.dom_pause_type.name) : '')}
                            secondary={progTrack.position}
                          />
                      
                        </DragListItem>
                      );
                      if (dragging == progTrack.id.toString()) {
                        draggingElement = li;
                      }
                      return (li);

                    })}

                  </DropList>

              </SortableContext>

              <List sx={{ mt: 3 }}>

                {data && data.dom_pause_types && data.dom_pause_types.length > 0 && (

                  <React.Fragment>

                    <ListItemButton onClick={e => { this.setState({anchorEl: e.currentTarget}); }} 
                      id='pause-button'
                      aria-controls={anchorEl ? 'pause-menu' : undefined}
                      aria-expanded={anchorEl ? 'true' : undefined}
                      aria-haspopup="true"
                    >
                      <ListItemIcon>
                        <Add />
                      </ListItemIcon>
                      <ListItemText primary="Unterbrechung hinzufügen" />
                    </ListItemButton>

                    <Menu
                      id='pause-menu'
                      anchorEl={anchorEl}
                      open={anchorEl ? true : false}
                      onClose={() => { this.setState({anchorEl: undefined}); }}
                      MenuListProps={{
                        'aria-labelledby': 'pause-button',
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      {data.dom_pause_types.map((dpt: any, i3: number) => {
                        return (
                          <MenuItem key={i3} onClick={() => { this.setState({anchorEl: undefined}, () => { this.addTracks(undefined, dpt); }); }}>
                            {t('pause_type.' + dpt.name)}
                          </MenuItem>
                        );
                      })}
                    </Menu>

                  </React.Fragment>

                )}

                <ListItemButton onClick={() => { this.onAddDialogOpen(); }}>
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText primary="Stück hinzufügen" />
                </ListItemButton>

              </List>
                

            </Paper>

            {!(data && data.program && data.program.shortcut) && (
              <Hidden mdDown>
                {data && data.shortcut_programs && data.shortcut_programs.map((shortProg: any, i2: number) => {

                  let programTracks: any[] = [];
                  if (shortProg && shortProg.program_tracks) {
                    programTracks = shortProg.program_tracks.filter((progTrack: any) => {
                      return !progTrack.del;
                    });
                  }

                  return (
                      <Paper sx={{minWidth: 350, m: 3, flexGrow: 1 }} key={i2}>
                        <Typography variant="subtitle1" color="textSecondary" sx={{ m: 2, fontWeight: 'bold' }}>{shortProg.title}</Typography>

                        

                        <SortableContext 
                          id={'program-' + shortProg.id.toString()}
                          items={
                              programTracks.map((progTrack: any, i2: number) => {
                                  return progTrack.id.toString();
                              })
                          }>
                            <DropList id={'program-' + shortProg.id.toString()}>
                            
                                {shortProg.program_tracks && shortProg.program_tracks.map((progTrack: any, i: number) => {

                                  if (progTrack.del) {
                                    return null;
                                  }
                                    
                                  let li: JSX.Element = (
                                    <DragListItem key={progTrack.id} id={progTrack.id.toString()}>

                                      <ListItemText
                                        primary={(progTrack.track && progTrack.track.title) ? progTrack.track.title : (progTrack.dom_pause_type ? t('pause_type.' + progTrack.dom_pause_type.name) : '')}
                                        secondary={progTrack.position}
                                      />
                                  
                                  </DragListItem>
                                  );
                                  if (dragging == progTrack.id.toString()) {
                                    draggingElement = li;
                                  }
                                  return (li);

                                })}

                            </DropList>

                        </SortableContext>
                          

                      </Paper>
                  );
                })}
              </Hidden>
            )}

            <DragOverlay>
              {draggingElement && React.cloneElement(draggingElement)}
            </DragOverlay>

          </DndContext>
            
          </Box>

          <Box sx={{ textAlign: 'center', mt: 3, mb: 3 }}>

            <ButtonGroup variant="outlined">
              <Button color="primary" startIcon={<Save />} onClick={this.onSubmit}>Stücke speichern</Button>
            </ButtonGroup>

          </Box>



          <Dialog
              fullScreen
              open={addTrackDialogOpen}
              onClose={() => { this.setState({addTrackDialogOpen: false}); }}
              TransitionComponent={DialogTransition}
              fullWidth={true}
          >

            <AppBar position="fixed" color="secondary">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => { this.setState({addTrackDialogOpen: false, choosenTracks: []}); }}
                >
                  <Close />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Stücke hinzufügen
                </Typography>
                <IconButton 
                  autoFocus 
                  edge="start"
                  color="inherit"
                  onClick={() => { this.addTracks(); }}
                >
                  <Check />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Box sx={{ mt: 6 }}>
              {addTrackDialogOpen && (<TrackOverview choose choosenTracks={choosenTracks} chooseFunction={(ct: any[]) => { this.setState({choosenTracks: ct}) }} />)}
            </Box>

          </Dialog>

        
        </Container>
    );
  }

  handleDragStart = (event: DragStartEvent) => {
    const { data } = this.state;
    
    this.setState({dragging: event.active.id});
  }

  handleDragOver = (event: DragOverEvent) => {
    let data = this.state.data;

    const { over, active } = event;
    const overId = over?.id;
    
    console.log(over, active);

    if (!over /*|| !over.data.current || !over.data.current.sortable*/ || !active.data.current || !active.data.current.sortable) {
      return;
    }

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current ? over.data.current.sortable.containerId : over.id;

    if ((activeContainer !== overContainer) && (overContainer == 'program-main')) {
      //const activeIndex = active.data.current?.sortable.index;
      //const overIndex = over.data.current?.sortable.index || 0;

      //let oldIndex: number = this.getIndexById(folder.directory_tracks, active.id) || 0;
      let overIndex: number = over.data.current ? (this.getIndexById(data.program.program_tracks, over.id) || 0) : data.program.program_tracks.length;

      //Loeschen aus altem Programm
      let item: any = undefined;
      if (activeContainer == 'program-main') {
        if (data && data.program && data.program.program_tracks) {
          let activeIndex: number | undefined = this.getIndexById(data.program.program_tracks, active.id);
          if (activeIndex !== undefined) {
            item = data.program.program_tracks[activeIndex];
            data.program.program_tracks.splice(activeIndex, 1);
          }
        }
      } else {
        if (data && data.shortcut_programs) {
          data.shortcut_programs.forEach((prog: any, i: number) => {
            if (('program-' + prog.id.toString()) == activeContainer) {
              let activeIndex: number | undefined = this.getIndexById(prog.program_tracks, active.id);
              if (activeIndex !== undefined) {
                item = prog.program_tracks[activeIndex];
                prog.program_tracks.splice(activeIndex, 1);
              }
            }
          });
        }
      }

      //Einfuegen in neues Programm
      if (item) {
        item.new = true;
        data.program.program_tracks.push(item);
        data.program.program_tracks = arrayMove(data.program.program_tracks, (data.program.program_tracks.length - 1), overIndex);
      }
      this.setState({data: data});
    }
  }

  handleDragEnd = (event: DragEndEvent) => {
    let data = this.state.data;

    this.setState({dragging: ""});
    
    const {active, over} = event;

    let programs = [];
    if (data.program) {
      programs.push(data.program);
    }
    if (data.shortcut_programs) {
      data.shortcut_programs.forEach((prog: any) => {
        programs.push(prog);
      });
    }

    if (!over || !active.data.current) {
      return ;
    }

    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId;

      //const activeIndex = active.data.current.sortable.index;
      //const overIndex = over.data.current?.sortable.index || 0;
      

      if (overContainer == 'program-main') {
        let overIndex: number = over ? (this.getIndexById(data.program.program_tracks, over.id) || 0) : data.program.program_tracks.length;

        if (activeContainer === overContainer) {
          //innerhalb des Haupt-Programm: nur Index-Position in Array verschieben
          let activeIndex: number | undefined = this.getIndexById(data.program.program_tracks, active.id);
          if (activeIndex !== undefined) {
            data.program.program_tracks = arrayMove(data.program.program_tracks, activeIndex, overIndex);
            data.program = this.reorderProgram(data.program);
          }
        } else {
          //von Shortcut-Programm zu Haupt-Programm: in altem loeschen und in Haupt-Programm einfuegen

          //Loeschen aus altem Programm
          let item: any = undefined;
          if (activeContainer == 'program-main') {
            if (data && data.program && data.program.program_tracks) {
              let activeIndex: number | undefined = this.getIndexById(data.program.program_tracks, active.id);
              if (activeIndex !== undefined) {
                item = data.program.program_tracks[activeIndex];
                data.program.program_tracks.splice(activeIndex, 1);
                data.program = this.reorderProgram(data.program);
              }
            }
          } else {
            if (data && data.shortcut_programs) {
              data.shortcut_programs.forEach((prog: any, i: number) => {
                if (('program-' + prog.id.toString()) == activeContainer) {
                  let activeIndex: number | undefined = this.getIndexById(prog.program_tracks, active.id);
                  if (activeIndex !== undefined) {
                    item = prog.program_tracks[activeIndex];
                    prog.program_tracks.splice(activeIndex, 1);
                    prog = this.reorderProgram(prog);
                  }
                }
              });
            }
          }

          //Einfuegen in neues Programm
          if (item) {
            item.new = true;
            data.program.program_tracks.push(item);
            data.program.program_tracks = arrayMove(data.program.program_tracks, (data.program.program_tracks.length - 1), overIndex);
            data.program = this.reorderProgram(data.program);
          }

        }

        this.setState({data: data});
      }

    }

    /*programs.forEach((prog: any, i: number) => {

      if (prog.program_tracks && (prog.program_tracks.length > 0)) {

        let oldIndex: number = 0, newIndex: number = 0;

        prog.program_tracks.forEach((d: any, i2: number) => {

          if (d.id.toString() === active.id) {
            oldIndex = i2;
          }
          if (over && (d.id.toString() === over.id)) {
            newIndex = i2;
          }

        });

        if (oldIndex !== newIndex) {
          prog.program_tracks = arrayMove(prog.program_tracks, oldIndex, newIndex);

          prog = this.reorderProgram(prog);

        }

      }

    });
    
    this.setState({data: data});*/
  }

  delProgTrack = (trackIndex: number /*,id: string*/) => {
    const { data } = this.state;

    /*if (data.program.program_tracks && (data.program.program_tracks.length > 0)) {

      let reorder: boolean = false;
      let delIndex: number | undefined = undefined;

      data.program.program_tracks.forEach((pt: any, i2: number) => {

        if (pt.id.toString() == id) {
          pt.del = true;
          reorder = true;
          if (pt.new) {
            delIndex = i2;
          }
        }

      });

      if (delIndex) {
        data.program.program_tracks.splice(delIndex, 1);
      }
      if (reorder) {
        data.program = this.reorderProgram(data.program);
      }

    }*/

    data.program.program_tracks[trackIndex].del = true;
    if (data.program.program_tracks[trackIndex].new) {
      data.program.program_tracks.splice(trackIndex, 1);
    }
    data.program = this.reorderProgram(data.program);

    this.setState({data: data});
  }

  reorderProgram = (prog: any): any => {
    let pos: number = 1;
    let abs_pos: number = 1;
    prog.program_tracks.forEach((pt: any, i2: number) => {
      if (!pt.del) {

        pt.abs_position = abs_pos;
        abs_pos++;
        
        if (pt.track) {
          pt.position = pos;
          pos++;
        }

      }
      
    });
    return prog;
  }

  getIndexById = (progTracks: any, id: string | number): number | undefined => {
    const { data } = this.state;
    let res: number | undefined = undefined;

    if (progTracks && id) {
      progTracks.forEach((d: any, i: number) => {
        if (d.id.toString() === id) {
            res = i;
        }
      });
    }

    return res;
  }

}

export default withHocs(ProgramTracksEdit);