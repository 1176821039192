import React from 'react';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';

import Folder from '@mui/icons-material/Folder';
import List from '@mui/icons-material/List';
import Chat from '@mui/icons-material/Chat';
import Equalizer from '@mui/icons-material/Equalizer';
import GTranslate from '@mui/icons-material/GTranslate';
import MusicVideo from '@mui/icons-material/MusicVideo';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Group from '@mui/icons-material/Group';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import MusicNote from '@mui/icons-material/MusicNote';
import CalendarToday from '@mui/icons-material/CalendarToday';
import VpnKey from '@mui/icons-material/VpnKey';
import Mail from '@mui/icons-material/Mail';
import Notifications from '@mui/icons-material/Notifications';
import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness2 from '@mui/icons-material/Brightness2';
import BrightnessAuto from '@mui/icons-material/BrightnessAuto';

import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { AppContext } from '../context';
import Role from '../components/role';
import TextDivider from '../components/textDivider';
import { withHocs, WithHocs } from '../components/hocs';


interface Props {
    children?: React.ReactNode,
}

interface State {
}

class Dashboard extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = { 
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //this.context.setHeaderTitle("Startseite");
  }

  onLogout = () => {
    this.context.setLoading(true);
    this.props.rest.post('logout').then(data => {
        this.context.setLoading(false);
        this.context.setLoggedIn(false);
    }).catch(err => {
        this.context.setLoading(false);
        this.context.setLoggedIn(false);
    });
  }


  render() {
    const { themeMode } = this.context;

    const card: SxProps = {
        textAlign: 'center', 
        height: '100%',
    };

    const cardTitle: SxProps = {
        textAlign: 'center',
        mb: 0,
        mt: 1,
        wordWrap: 'break-word',
        wordBreak: 'normal',
        overflowWrap: 'break-word',
        hyphens: 'auto',
        fontSize: '1.3rem',
        '@media (min-width:600px)': {
            fontSize: "1.7rem",
        },
        '@media (min-width:960px)': {
            fontSize: "2.125rem",
        },
    };

    const cardIcon: SxProps = {
        height: '50%',
        width: '50%',
    };

    return (
        <Container maxWidth="lg" sx={{ pt: 1, pb: 1 }}>

            <Grid container spacing={3}>

                {/*<Grid item xs={12}>
                    <Typography variant="h4" color="primary">
                        Startseite
                    </Typography>
                </Grid>*/}

                <Grid item xs={12}>
                    <TextDivider textAlign="left"><Typography variant="h5" color="textSecondary">Notenarchiv</Typography></TextDivider>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } component={NavLink} to="/archive/tracks">
                            <CardContent>
                                <MusicNote color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>St&uuml;cke</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } component={NavLink} to="/archive/dirs">
                            <CardContent>
                                <Folder color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Inhaltsverzeichnisse</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } component={NavLink} to="/archive/programs">
                            <CardContent>
                                <List color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Programme</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <TextDivider textAlign="left"><Typography variant="h5" color="textSecondary">Termine</Typography></TextDivider>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } component={NavLink} to="/events/calendar">
                            <CardContent>
                                <CalendarToday color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Kalender</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } component={NavLink} to="/events/presence">
                            <CardContent>
                                <EmojiPeople color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Anwesenheit</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                {Role.isEventEditor() && (
                    <Grid item lg={3} sm={4} xs={6}>
                        <Card sx={{ height: '100%' }}>
                            <CardActionArea sx={ card } component={NavLink} to="/events/statistics">
                                <CardContent>
                                    <Equalizer color="action" sx={ cardIcon } />
                                    <Typography variant="h4" color="primary" sx={ cardTitle }>Statistiken</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextDivider textAlign="left"><Typography variant="h5" color="textSecondary">Mitglieder</Typography></TextDivider>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } component={NavLink} to="/members/user">
                            <CardContent>
                                <Group color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Mitglieder</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } component={NavLink} to="/members/questions">
                            <CardContent>
                                <Chat color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Umfragen</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <TextDivider textAlign="left"><Typography variant="h5" color="textSecondary">Mein Profil</Typography></TextDivider>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } onClick={() => { this.context.setPwdChangeOpen(true); }}>
                            <CardContent>
                                <VpnKey color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Passwort ändern</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } onClick={() => { this.context.setMailChangeOpen(true); }}>
                            <CardContent>
                                <Mail color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>E-Mail-Adresse ändern</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } onClick={() => { this.context.setNotifyChangeOpen(true); }}>
                            <CardContent>
                                <Notifications color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Benachrichtigung</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } onClick={(e: React.MouseEvent) => { this.context.switchThemeMode(); }}>
                            <CardContent>
                                {(themeMode.indexOf('auto') > -1) 
                                    ? <BrightnessAuto color="action" sx={ cardIcon } /> : 
                                        ((themeMode.indexOf('light') > -1) 
                                        ? <BrightnessLow color="action" sx={ cardIcon } />
                                        : <Brightness2 color="action" sx={ cardIcon } />)
                                }
                                <Typography variant="h4" color="primary" sx={ cardTitle }>Thema</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardActionArea sx={ card } onClick={() => { this.onLogout(); }}>
                            <CardContent>
                                <ExitToApp color="action" sx={ cardIcon } />
                                <Typography variant="h4" color="textSecondary" sx={ cardTitle }>Logout</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>





            </Grid>
        
        </Container>
    );
  }

}

export default withHocs(Dashboard);