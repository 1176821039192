import { Workbox } from 'workbox-window';

export default class WorkboxService {

    private _wb: Workbox;

    private static _instance: WorkboxService;

    public static getInstance = (): WorkboxService | undefined => {
        if ('serviceWorker' in navigator) {
            if (!WorkboxService._instance) {
                try {
                    WorkboxService._instance = new WorkboxService();
                } catch(e) {
                    console.log("Webpack-Initialisierung fehlgeschlagen", e);
                }
            }
            return WorkboxService._instance;
        } else {
            console.log("serviceWorker not supporterd");
        }
    }

    private constructor() {
        this._wb = new Workbox('./service-worker.js', {});
        this.setupListeners();
        this._wb.register();
    }

    public getWb = (): Workbox => {
        return this._wb;
    }

    private setupListeners = (): void => {
        this.getWb().addEventListener('installed', event => {
            if (!event.isUpdate) {
                console.log('installed: first', event);
            } else {
                console.log('installed: update', event);
            }
        });

        this.getWb().addEventListener('activated', event => {
            if (!event.isUpdate) {
              console.log('activated: first', event);
            } else {
                console.log('activated: update', event);
            }

            this.getWb().messageSW({type: 'GET_VERSION'}).then(swVersion => {
                console.log('Service Worker version:', swVersion);
            }).catch(error => {
                console.log(error);
            });
        });

        this.getWb().addEventListener('waiting', event => {
            console.log("waiting", event);
        });

        this.getWb().addEventListener('controlling', event => {
            console.log("controlling", event);
        });

        this.getWb().addEventListener('message', event => {
            console.log("message", event);
        });
        
    }

}