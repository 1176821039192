import React from 'react';

import { AppContext } from '../context';

export default class Role extends React.Component<{}, {}> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    static instance: Role;
    
    public static sudo: string = "sudo";
    public static admin: string = "admin";
    public static trackEditor: string = "track editor";
    public static programEditor: string = "program editor";
    public static directoryEditor: string = "directory editor";
    public static userEditor: string = "user editor";
    public static eventEditor: string = "event editor";
    public static questionEditor: string = "question editor";

    constructor(props: any) {
        super(props);

        Role.instance = this;
    }

    public static getInstance = (): Role => {
        if (!this.instance) {
            this.instance = new Role(undefined);
        }
        return this.instance;
    }

    public static hasRole = (role: string): boolean => {
        return this.getInstance().hasRole(role);
    }

    //clientseitige Rollen-Pruefung = gleiche Logik wie am Server->User-Model
    private hasRole = (role: string): boolean => { 
        const { permission } = this.context;

        let hasRole: boolean = false;
        if (permission) { 
          permission.forEach((perm: any) => {
            if (role && perm.name && ((perm.name === Role.sudo) || (perm.name === role) || ((perm.name === Role.admin) && (role !== Role.sudo)))) {
              hasRole = true;
            }
          }); 
        }
        return hasRole;
    }

    public static isSudo = (): boolean => {
        return this.hasRole(Role.sudo);
    }

    public static isAdmin = (): boolean => {
        return this.hasRole(Role.admin);
    }

    public static isTrackEditor = (): boolean => {
        return this.hasRole(Role.trackEditor);
    }

    public static isProgramEditor = (): boolean => {
        return this.hasRole(Role.programEditor);
    }

    public static isDirectoryEditor = (): boolean => {
        return this.hasRole(Role.directoryEditor);
    }

    public static isUserEditor = (): boolean => {
        return this.hasRole(Role.userEditor);
    }

    public static isEventEditor = (): boolean => {
        return this.hasRole(Role.eventEditor);
    }

    public static isQuestionEditor = (): boolean => {
        return this.hasRole(Role.questionEditor);
    }

    render() {
        return null;
    }

}