import moment from 'moment-timezone';

export default class Util {

    public static isoDateStringToString = (date: moment.MomentInput | undefined, withTime: boolean): string | undefined => {
        if (!date) {
            return undefined;
        }

        if (withTime) {
            return moment(date).format('DD.MM.YYYY HH:mm');
        } else {
            return moment(date).startOf('day').format('DD.MM.YYYY');
        }
    }

    public static dateToIsoString = (date: moment.MomentInput | undefined): string | undefined => {
        if (!date) {
            return undefined;
        }

        return moment(date).format();
    }

    public static isoStringToDate = (date: moment.MomentInput | undefined): moment.Moment | undefined => {
        if (!date) {
            return undefined;
        }

        console.log(date, moment(date), moment(date).isBefore());

        return moment(date);
    }

    public static dateToUrlString = (date: moment.MomentInput | undefined, withDay: boolean, withTime: boolean): string | undefined => {
        if (!date) {
            return undefined;
        }

        if (withTime && withDay) {
            return moment(date).format('YYYY-MM-DDTHH:mm');
        } else if (withDay) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return moment(date).format('YYYY-MM');
        }
    }

    public static dateToStartOfDayDate = (date: moment.MomentInput | undefined): moment.Moment | undefined => {
        if (!date) {
            return undefined;
        }

        return moment(date).startOf('day');
    }

    public static dateToEndOfDayDate = (date: moment.MomentInput | undefined): moment.Moment | undefined => {
        if (!date) {
            return undefined;
        }

        return moment(date).endOf('day');
    }

    /*public static dateToString = (date: Date, withTime?: boolean, en?: boolean): string | undefined => {
        if (!date) {
            return undefined;
        }

        let yearInt: number = date.getFullYear();
        let monthInt: number = date.getMonth() + 1;
        let dayInt: number = date.getDate();
        let hourInt: number = date.getHours();
        let minInt: number = date.getMinutes();

        let year: string = yearInt.toString();
        let month: string = monthInt.toString();
        let day: string = dayInt.toString();
        let hour: string = hourInt.toString();
        let min: string = minInt.toString();

        if (monthInt < 10) {
            month = "0" + month;
        }
        if (dayInt < 10) {
            day = "0" + day;
        }
        if (hourInt < 10) {
            hour = "0" + hour;
        }
        if (minInt < 10) {
            min = "0" + min;
        }

        let res: string = "";

        if (en) {
            res = year + "-" + month + "-" + day;
            if (withTime) {
                res = res + "T" + hour + ":" + min;
            }
        } else {
            res = day + "." + month + "." + year;
            if (withTime) {
                res = res + " " + hour + ":" + min;
            }
        }

        return res;
    }

    public static isoStringToDate = (date: string): Date | undefined => {
        if (!date) {
            return undefined;
        }
        let m: moment.Moment = moment.utc(date);
        console.log(date, m, m.toDate());
        return m.toDate();
    }

    public static stringToDate = (date: string, withTime?: boolean, en?: boolean): Date | undefined => {
        if (!date) {
            return undefined;
        }
        
        let yearInt: number = (new Date()).getFullYear();
        let monthInt: number = (new Date()).getMonth() + 1;
        let dayInt: number = (new Date()).getDate();
        let hourInt: number = 0;
        let minInt: number = 0;

        if (en) {
            yearInt = parseInt(date.substring(0, 4));
            monthInt = parseInt(date.substring(5, 7));
            dayInt = parseInt(date.substring(8, 10));

            if (withTime) {
                hourInt = parseInt(date.substring(11, 13));
                minInt = parseInt(date.substring(14, 16));
            }

        } else {
            dayInt = parseInt(date.substring(0, 2));
            monthInt = parseInt(date.substring(3, 5));
            yearInt = parseInt(date.substring(6, 10));

            if (withTime) {
                hourInt = parseInt(date.substring(11, 13));
                minInt = parseInt(date.substring(14, 16));
            }
        }

        return new Date(yearInt, (monthInt - 1), dayInt, hourInt, minInt, 0, 0);
    }*/

    public static isIE = (): boolean => {
        return (navigator.userAgent.toUpperCase().indexOf("MSIE ") > -1 || navigator.userAgent.toUpperCase().indexOf("TRIDENT") > -1);
    }

    public static isIOS = (): boolean => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    public static isAndroid = (): boolean => {
        return (navigator.userAgent.toLowerCase().indexOf("android") > -1);
    }

    public static isChrome = (): boolean => {
        return (navigator.userAgent.toLowerCase().indexOf("chrome") > -1);
    }

    public static isDarkMode = (themeMode: string): boolean => {
        let darkMode: boolean = false;
        if (themeMode.indexOf('auto') > -1) {
            darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        } else {
            darkMode = (themeMode.indexOf('dark') > -1);
        }
        return darkMode;
    }

    public static isPwaInstalled = (): boolean => {
        return window.matchMedia('(display-mode: standalone)').matches;
    }

}