import React from 'react';
import { NavLink } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Home from '@mui/icons-material/Home';
import Folder from '@mui/icons-material/Folder';
import ListIcon from '@mui/icons-material/List';
import MusicNote from '@mui/icons-material/MusicNote';
import Group from '@mui/icons-material/Group';
import Chat from '@mui/icons-material/Chat';
import Equalizer from '@mui/icons-material/Equalizer';
import GTranslate from '@mui/icons-material/GTranslate';
import MusicVideo from '@mui/icons-material/MusicVideo';
import ExitToApp from '@mui/icons-material/ExitToApp';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import CalendarToday from '@mui/icons-material/CalendarToday';
import VpnKey from '@mui/icons-material/VpnKey';
import Mail from '@mui/icons-material/Mail';
import Notifications from '@mui/icons-material/Notifications';
import BrightnessLow from '@mui/icons-material/BrightnessLow';
import Brightness2 from '@mui/icons-material/Brightness2';
import BrightnessAuto from '@mui/icons-material/BrightnessAuto';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';

import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { AppContext } from './context';
import Role from './components/role';
import { withHocs, WithHocs } from './components/hocs';
import TextDivider from './components/textDivider';
import Util from './components/util';
import UserPwdChange from './settings/user/userPwdChange';
import UserMailChange from './settings/user/userMailChange';
import UserNotifyChange from './settings/user/userNotifyChange';
import FcmService from './components/fcmService';


interface Props {
    children: React.ReactElement<any, any>,
    hideFooter?: boolean,
}

interface State {
  drawerOpen: boolean,
  anchorEl?: Element,
  anchorIndex?: number,
}

function HideOnScroll(props: Props) {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {props.children}
      </Slide>
    );
}

function ScrollTop(props: Props) {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
        '#back-to-top-anchor',
        );

        if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" style={{ 
                position: 'fixed',
                bottom: 5,
                right: 5,
             }}>
                {props.children}
            </div>
        </Zoom>
    );
}




class MenuWrapper extends React.Component<WithHocs & Props, State> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        drawerOpen: false,
        anchorEl: undefined,
        anchorIndex: undefined,
    };

    constructor(props: any) {
        super(props);
    }

    logout = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        this.handleFirstLogin();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }


    handleToTopClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        this.setState({ drawerOpen: open });
    }

    onMenuOpen = (event: React.MouseEvent, anchorIndex: number) => {
        this.setState({anchorEl: event.currentTarget, anchorIndex: anchorIndex});
    }
    onMenuClose = (event: React.MouseEvent) => {
        this.setState({anchorEl: undefined, anchorIndex: undefined});
    }

    handleScroll = () => {
        if (this.state.anchorIndex) { 
            this.setState({anchorEl: undefined, anchorIndex: undefined}); 
        }
    }

    onLogout = () => {
        this.context.setLoading(true);
        this.props.rest.post('logout').then(data => {
            this.context.setLoading(false);
            this.context.setLoggedIn(false);
        }).catch(err => {
            this.context.setLoading(false);
            this.context.setLoggedIn(false);
        });
    }

    isSelected = (...routes: string[]): boolean => {
        const { location } = this.props;

        let res: boolean = false;

        if (routes && location && location.pathname) {
            routes.forEach((route: string, i: number) => {
                if (route && (location.pathname.indexOf(route) > -1)) {
                    res = true;
                }
            });
        }

        return res;
    }

    goBack = () => {
        if (this.showBackButton() && (this.props.location.key !== 'default')) {
            this.props.navigate(-1);
        } else {
            this.props.navigate("/dashboard");
        }
    }

    showBackButton = (): boolean => {
        return !this.isSelected("/dashboard");
    }

    handleFirstLogin = (): void => {
        const { userUnionLocalStorageConfig } = this.context;
        if (!userUnionLocalStorageConfig) {
            //first login
            let configJson = userUnionLocalStorageConfig || {};
            configJson.was_logged_in = true;
            this.context.setUserUnionLocalStorageConfig(configJson);
            this.context.setNotifyChangeOpen(true);
        } else if (userUnionLocalStorageConfig && userUnionLocalStorageConfig.fcm_decision) {
            //check if token changed and update token on server

            let fcmSvc = FcmService.getInstance();
            if (fcmSvc) {
                fcmSvc.requestPermission().then((decision: NotificationPermission) => {

                    if (decision == 'granted') {

                        fcmSvc?.getToken().then((token: string) => {
                            console.log("fcm-token", token);

                            let notifyData: any = { notify_push: true, fcm_token: token };

                            this.props.rest.post("settings/notifyChange", notifyData).then(data => {

                                if (data && data.status && data.status == "ok") {
                                    console.log("fcm-token updated");
                                }

                            }).catch(error => {
                                console.log(error);
                            });

                        }).catch(error => {
                            console.log(error);
                        });

                    }

                }).catch(error => {
                    console.log(error);
                });
            }

        }
    }


    render() {
        const { themeMode } = this.context;

        const { loading, loggedIn, user, pwdChangeOpen, mailChangeOpen, notifyChangeOpen } = this.context;
        const { anchorEl, anchorIndex } = this.state;

        const selMenuItem: SxProps = { textDecoration: 'underline', fontWeight: 'bold' };
        const selListItem: SxProps = { textDecoration: 'underline', fontWeight: 'bold', backgroundColor: '#d6d6d6' };

        return (
            <React.Fragment>
                
                <Box id="back-to-top-anchor" sx={{ height: '100%' }}>

                    <SwipeableDrawer
                        anchor="right"
                        open={this.state.drawerOpen}
                        onClose={this.toggleDrawer(false)}
                        onOpen={this.toggleDrawer(true)}
                        disableBackdropTransition={!Util.isIOS()}
                        disableDiscovery={Util.isIOS()}>

                        <Box
                            style={{ width: 250 }}
                            role="presentation"
                            onClick={this.toggleDrawer(false)}
                            onKeyDown={this.toggleDrawer(false)}>

                            <List>
                                <ListItemButton key="Startseite" component={NavLink} to="/dashboard" sx={this.isSelected("/dashboard") ? selListItem : {}}>
                                    <ListItemIcon><Home /></ListItemIcon>
                                    <ListItemText primary="Startseite" />
                                </ListItemButton>
                            </List>

                            <TextDivider>Notenarchiv</TextDivider>

                            <List>
                                <ListItemButton key="Stücke" component={NavLink} to="/archive/tracks" sx={this.isSelected("/archive/tracks") ? selListItem : {}}>
                                    <ListItemIcon><MusicNote /></ListItemIcon>
                                    <ListItemText primary="Stücke" />
                                </ListItemButton>
                                <ListItemButton key="Inhaltsverzeichnisse" component={NavLink} to="/archive/dirs" sx={this.isSelected("/archive/dirs") ? selListItem : {}}>
                                    <ListItemIcon><Folder /></ListItemIcon>
                                    <ListItemText primary="Inhaltsverzeichnisse" />
                                </ListItemButton>
                                <ListItemButton key="Programme" component={NavLink} to="/archive/programs" sx={this.isSelected("/archive/programs") ? selListItem : {}}>
                                    <ListItemIcon><ListIcon /></ListItemIcon>
                                    <ListItemText primary="Programme" />
                                </ListItemButton>
                            </List>

                            <TextDivider>Termine</TextDivider>

                            <List>
                                <ListItemButton key="Kalender" component={NavLink} to="/events/calendar" sx={this.isSelected("/events/calendar") ? selListItem : {}}>
                                    <ListItemIcon><CalendarToday /></ListItemIcon>
                                    <ListItemText primary="Kalender" />
                                </ListItemButton>
                                <ListItemButton key="Anwesenheit" component={NavLink} to="/events/presence" sx={this.isSelected("/events/presence") ? selListItem : {}}>
                                    <ListItemIcon><EmojiPeople /></ListItemIcon>
                                    <ListItemText primary="Anwesenheit" />
                                </ListItemButton>
                                {Role.isProgramEditor() && (
                                    <ListItemButton key="Statistiken" component={NavLink} to="/events/statistics" sx={this.isSelected("/events/statistics") ? selListItem : {}}>
                                        <ListItemIcon><Equalizer /></ListItemIcon>
                                        <ListItemText primary="Statistiken" />
                                    </ListItemButton>
                                )}
                            </List>

                            <TextDivider>Mitglieder</TextDivider>

                            <List>
                                <ListItemButton key="Mitglieder" component={NavLink} to="/members/user" sx={this.isSelected("/members/user") ? selListItem : {}}>
                                    <ListItemIcon><Group /></ListItemIcon>
                                    <ListItemText primary="Mitglieder" />
                                </ListItemButton>
                                <ListItemButton key="Umfragen" component={NavLink} to="/members/questions" sx={this.isSelected("/members/questions") ? selListItem : {}}>
                                    <ListItemIcon><Chat /></ListItemIcon>
                                    <ListItemText primary="Umfragen" />
                                </ListItemButton>
                            </List>

                            <TextDivider>Mein Profil</TextDivider>

                            <List>
                                <ListItemButton key="Passwort aendern" onClick={() => { this.context.setPwdChangeOpen(true); }}>
                                    <ListItemIcon><VpnKey /></ListItemIcon>
                                    <ListItemText primary="Passwort ändern" />
                                </ListItemButton>
                                <ListItemButton key="Mail aendern" onClick={() => { this.context.setMailChangeOpen(true); }}>
                                    <ListItemIcon><Mail /></ListItemIcon>
                                    <ListItemText primary="E-Mail-Adresse ändern" />
                                </ListItemButton>
                                <ListItemButton key="Benachrichtigung" onClick={() => { 
                                    this.context.setNotifyChangeOpen(true); 
                                    const storageKey = "logged_in_" + this.context.userUnion.id;
                                    if (typeof(Storage) !== "undefined") {
                                        localStorage.setItem(storageKey, "true");
                                    }
                                }}>
                                    <ListItemIcon><Notifications /></ListItemIcon>
                                    <ListItemText primary="Benachrichtigung" />
                                </ListItemButton>
                                <ListItemButton key="theme" onClick={(e: React.MouseEvent) => { this.context.switchThemeMode(); }}>
                                    <ListItemIcon>
                                        {(themeMode.indexOf('auto') > -1) 
                                            ? <BrightnessAuto /> : 
                                                ((themeMode.indexOf('light') > -1) 
                                                ? <BrightnessLow />
                                                : <Brightness2 />)
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary="Thema" />
                                </ListItemButton>
                                <ListItemButton key="Logout" onClick={() => { this.onLogout(); }}>
                                    <ListItemIcon><ExitToApp /></ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItemButton>
                            </List>
                        
                        </Box>
                    </SwipeableDrawer>


                    <HideOnScroll>
                        <AppBar position="fixed" enableColorOnDark>
                            <Toolbar>
                                
                                {this.showBackButton() && (
                                    <IconButton edge="start" sx={{ mr: 2 }} color="inherit" aria-label="back" onClick={() => { this.goBack() }}>
                                        {Util.isIOS() ? <ArrowBackIosNew /> : <ArrowBack />}
                                    </IconButton>
                                )}
                                
                                <Typography color="inherit" variant="h6" sx={{ flexGrow: 1, textDecoration: 'none' }}>
                                    <Button color="inherit" component={NavLink} to="/dashboard" sx={{ fontFamily: 'Dancing Script', fontSize: 22, textTransform: 'none' }}>
                                        <Box component="span" sx={{ fontFamily: 'Dancing Script', fontSize: 28, fontWeight: 'bold' }}>M</Box>eine&nbsp;
                                        <Box component="span" sx={{ fontFamily: 'Dancing Script', fontSize: 28, fontWeight: 'bold' }}>M</Box>usik
                                    </Button>
                                </Typography>
                                <Hidden mdDown>

                                        <Button color="inherit" onClick={e => this.onMenuOpen(e, 1)} aria-haspopup="true" sx={this.isSelected("/archive") ? selMenuItem : {}}>
                                            Notenarchiv
                                        </Button>
                                        <Menu anchorEl={anchorEl} keepMounted open={anchorIndex === 1} onClose={this.onMenuClose} disableScrollLock
                                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                                            <MenuItem onClick={this.onMenuClose} component={NavLink} to="/archive/tracks" sx={this.isSelected("/archive/tracks") ? selMenuItem : {}}><ListItemIcon><MusicNote /></ListItemIcon> St&uuml;cke</MenuItem>
                                            <MenuItem onClick={this.onMenuClose} component={NavLink} to="/archive/dirs" sx={this.isSelected("/archive/dirs") ? selMenuItem : {}}><ListItemIcon><Folder /></ListItemIcon> Inhaltsverzeichnisse</MenuItem>
                                            <MenuItem onClick={this.onMenuClose} component={NavLink} to="/archive/programs" sx={this.isSelected("/archive/programs") ? selMenuItem : {}}><ListItemIcon><ListIcon /></ListItemIcon> Programme</MenuItem>
                                        </Menu>
                                        
                                        <Button color="inherit" onClick={e => this.onMenuOpen(e, 2)} aria-haspopup="true" sx={this.isSelected("/events") ? selMenuItem : {}}>
                                            Termine
                                        </Button>
                                        <Menu anchorEl={anchorEl} keepMounted open={anchorIndex === 2} onClose={this.onMenuClose} disableScrollLock
                                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                                            <MenuItem onClick={this.onMenuClose} component={NavLink} to="/events/calendar" sx={this.isSelected("/events/calendar") ? selMenuItem : {}}><ListItemIcon><CalendarToday /></ListItemIcon> Kalender</MenuItem>
                                            <MenuItem onClick={this.onMenuClose} component={NavLink} to="/events/presence" sx={this.isSelected("/events/presence") ? selMenuItem : {}}><ListItemIcon><EmojiPeople /></ListItemIcon> Anwesenheit</MenuItem>
                                            {Role.isProgramEditor() && (<MenuItem onClick={this.onMenuClose} component={NavLink} to="/events/statistics" sx={this.isSelected("/events/statistics") ? selMenuItem : {}}><ListItemIcon><Equalizer /></ListItemIcon> Statistiken</MenuItem>)}
                                        </Menu>

                                        <Button color="inherit" onClick={e => this.onMenuOpen(e, 3)} aria-haspopup="true" sx={this.isSelected("/members") ? selMenuItem : {}}>
                                            Mitglieder
                                        </Button>
                                        <Menu anchorEl={anchorEl} keepMounted open={anchorIndex === 3} onClose={this.onMenuClose} disableScrollLock
                                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                                            <MenuItem onClick={this.onMenuClose} component={NavLink} to="/members/user" sx={this.isSelected("/members/user") ? selMenuItem : {}}><ListItemIcon><Group /></ListItemIcon> Mitglieder</MenuItem>
                                            <MenuItem onClick={this.onMenuClose} component={NavLink} to="/members/questions" sx={this.isSelected("/members/questions") ? selMenuItem : {}}><ListItemIcon><Chat /></ListItemIcon> Umfragen</MenuItem>
                                        </Menu>

                                        <Tooltip TransitionComponent={Zoom} title="Mein Profil" placement="bottom" arrow>
                                            <IconButton onClick={e => this.onMenuOpen(e, 4)} size="medium" sx={this.isSelected("/settings") ? selMenuItem : {}} aria-haspopup="true">
                                                <Avatar sx={{ width: 32, height: 32 }}>{(user && user.firstname ? user.firstname.substring(0, 1) : "") + (user && user.surname ? user.surname.substring(0, 1) : "")}</Avatar>
                                            </IconButton>
                                        </Tooltip>
                                        <Menu anchorEl={anchorEl} keepMounted open={anchorIndex === 4} onClose={this.onMenuClose} disableScrollLock
                                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                                            <MenuItem onClick={e => { this.onMenuClose(e); this.context.setPwdChangeOpen(true); }}><ListItemIcon><VpnKey /></ListItemIcon> Passwort ändern</MenuItem>
                                            <MenuItem onClick={e => { this.onMenuClose(e); this.context.setMailChangeOpen(true); }}><ListItemIcon><Mail /></ListItemIcon> E-Mail-Adresse ändern</MenuItem>
                                            <MenuItem onClick={e => { this.onMenuClose(e); this.context.setNotifyChangeOpen(true); }}><ListItemIcon><Notifications /></ListItemIcon> Benachrichtigungen</MenuItem>
                                            
                                            <MenuItem onClick={(e: React.MouseEvent) => { this.context.switchThemeMode(); }}>
                                                <ListItemIcon>
                                                    {(themeMode.indexOf('auto') > -1) 
                                                        ? <BrightnessAuto /> : 
                                                            ((themeMode.indexOf('light') > -1) 
                                                            ? <BrightnessLow />
                                                            : <Brightness2 />)
                                                    }
                                                </ListItemIcon>
                                                Thema
                                            </MenuItem>

                                            <MenuItem onClick={(e: React.MouseEvent) => { this.onMenuClose(e); this.onLogout(); }}><ListItemIcon><ExitToApp /></ListItemIcon> Logout</MenuItem>
                                        </Menu>

                                </Hidden>
                                <Hidden mdUp>
                                    <IconButton edge="start" sx={{ mr: 2 }} color="inherit" aria-label="menu" onClick={this.toggleDrawer(true)}>
                                        <MenuIcon />
                                    </IconButton>
                                </Hidden>

                            </Toolbar>
                                
                        </AppBar>
                    </HideOnScroll>


                    {this.props.hideFooter ? (
                        <Box sx={{ paddingTop: 8, height: '100%' }}>
                            { this.props.children }
                        </Box>
                    ) : (
                        <Grid container sx={{ minHeight: '100%', paddingTop: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Grid item sx={{ flexShrink: 1, flexGrow: 1, maxWidth: '100%' }}>
                                { this.props.children }
                            </Grid>
                            <Grid item sx={{ flexShrink: 0, flexGrow: 0, textAlign: 'center' }}>
                                <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                                    <Link component={NavLink} to="/terms" target="_blank" underline="hover" variant="body2" color="textSecondary">Nutzungsbedingungen</Link>
                                    {" - "}
                                    <Link component={NavLink} to="/legalnotice" target="_blank" underline="hover" variant="body2" color="textSecondary">Impressum</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <UserPwdChange open={pwdChangeOpen} onClose={(success: boolean) => { this.context.setPwdChangeOpen(false); }} />
                    <UserMailChange open={mailChangeOpen} onClose={(success: boolean) => { this.context.setMailChangeOpen(false); }} />
                    <UserNotifyChange open={notifyChangeOpen} onClose={(success: boolean) => { this.context.setNotifyChangeOpen(false); }} />

                </Box>

                <ScrollTop {...this.props}>
                    <Fab color="primary" size="small" aria-label="scroll back to top">
                        <KeyboardArrowUp />
                    </Fab>
                </ScrollTop>
            </React.Fragment>
        );
    }

}

export default withHocs(MenuWrapper);