import React from 'react';

interface Props { 
    children?: React.ReactNode | string,
    index: number,
    value: number,
}

interface State { }

class TabPanel extends React.Component<Props, State> {

    state = { };

    constructor(props: any) {
        super(props);
    }

    render() {
        const { children, value, index } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
            >
                {(value === index) && children}
            </div>
        );
    }

}

export default TabPanel;