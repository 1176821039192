import React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { AppContext } from '../../context';
import { withHocs, WithHocs } from '../../components/hocs';

import CalendarEvent from '../calendar/calendarEvent';


interface Props {
}

interface State {
}


class PresenceOverview extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { 

    }

    render() {
        const { id } = this.props.params;
        
        return (
            <Container maxWidth="lg" sx={{ pb: 1 }}>

                <Typography variant="h4" color="textSecondary" marginY={3}>Anwesenheit</Typography>

                <CalendarEvent id={id} showOtherEvents={true} />

            </Container>
        );
    }

}

export default withHocs(PresenceOverview);