import React from 'react';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Close from '@mui/icons-material/Close';

import { AppContext } from '../../context';
import Role from '../../components/role';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';
import CalendarEventEditDialog from './calendarEventEditDialog';
import CalendarEventDelDialog from './calendarEventDelDialog';


interface Props {
    children?: React.ReactNode,
}

interface State {
    from: moment.Moment,
    to: moment.Moment,
    data: any,
    //presenceTypes: any,
    page: number,
    rowsPerPage: number,

    addOpen: boolean,
    addId: any,
    delOpen: boolean,
    delId: any,

    //showId: number | undefined,
    //showEvt: any,
}


class CalendarEvents extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        from: moment().subtract(1, 'M').startOf('day'),
        to: moment().add(3, 'M').endOf('day'),
        data: undefined as any,
        //presenceTypes: undefined as any,
        page: 1,
        rowsPerPage: 25,

        addOpen: false,
        addId: undefined,
        delOpen: false,
        delId: undefined,

        //showId: undefined,
        //showEvt: undefined as any,
    };

    constructor(props: any) {
        super(props);
    }

    weekdays = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    weekdaysShort = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
    months = ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    componentDidMount() { 
        this.searchEvents(true);
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
        /*if (this.props && this.props.params && this.props.params.day && ((prevProps && prevProps.params && (this.props.params.day !== prevProps.params.day)) || !(prevProps && prevProps.params && prevProps.params.day))) {
            this.searchEvents();
        }*/
    }

    searchEvents = (fresh: boolean) => {
        const { rowsPerPage } = this.state;

        this.setState({data: undefined/*, presenceTypes: undefined*/});

        let page: number = (fresh ? 1 : this.state.page);
        if (fresh) {
            this.setState({data: undefined, page: page});
        }

        let from: moment.Moment = this.state.from.clone().startOf('day');
        let to: moment.Moment = this.state.to.clone().endOf('day');
    
        this.context.setLoading(true);
        let eventData: any = { from: Util.dateToIsoString(from), to: Util.dateToIsoString(to), page: page, rowsPerPage: rowsPerPage };
    
        this.props.rest.post("calendar/search", eventData).then(data => {
    
          this.context.setLoading(false);
    
          if (data && data.events && data.status && data.status == "ok") {
    
            this.setState({data: data.events/*, presenceTypes: data.presence_types*/});
            console.log(data);
    
          } else {
            this.props.enqueueSnackbar("Termine konnten nicht gesucht werden", { variant: 'error' });
          }
    
        }).catch(err => {
          this.context.setLoading(false);
          console.log(err);
          this.props.enqueueSnackbar("Termine konnten nicht gesucht werden", { variant: 'error' });
        });
    }


    render() {
        const { from, to, data, /*presenceTypes,*/ page, rowsPerPage, addOpen, addId, delOpen, delId/*, showEvt*/ } = this.state;
        
        return (
            <Container maxWidth="xl">

                <Container maxWidth="md">

                    <Paper sx={{ p: 2 }}>

                        <Grid container spacing={2}>

                            <Grid item md={6} xs={12}>

                                <DatePicker
                                    label="Von Beginn-Datum"
                                    inputFormat="DD.MM.YYYY"
                                    mask="__.__.____"
                                    minDate={moment().year(1950).month(0).date(1).startOf('month')}
                                    maxDate={moment().year(2999).month(11).date(1).startOf('month')}
                                    renderInput={(params: any) => <TextField {...params} variant="standard" fullWidth />}
                                    value={from}
                                    onChange={(date: any, keyboardInputValue: any) => {
                                        if (date && date.isValid()) {
                                            this.setState({from: date}, () => {
                                                this.searchEvents(true);
                                            });
                                        }
                                    }}
                                    
                                />

                            </Grid>
                            
                            <Grid item md={6} xs={12}>

                                <DatePicker
                                    label="Bis Ende-Datum"
                                    inputFormat="DD.MM.YYYY"
                                    mask="__.__.____"
                                    minDate={moment().year(1950).month(0).date(1).startOf('month')}
                                    maxDate={moment().year(2999).month(11).date(1).startOf('month')}
                                    renderInput={(params: any) => <TextField {...params} variant="standard" fullWidth />}
                                    value={to}
                                    onChange={(date: any, keyboardInputValue: any) => {
                                        if (date && date.isValid()) {
                                            this.setState({to: date}, () => {
                                                this.searchEvents(true);
                                            });
                                        }
                                    }}
                                />

                            </Grid>
                            
                        </Grid>

                    </Paper>

                </Container>

                <Container maxWidth="md">

                    {data && data.data && data.data.length && (data.data.length > 0) ? (
                        <React.Fragment>
                            <List>
                                {data.data.map((evt: any, i: number) => {
                                    return (
                                        <React.Fragment key={2000 + i}>
                                            <ListItem
                                                secondaryAction={Role.isEventEditor() && (
                                                    <React.Fragment>
                                                        <IconButton edge="end" sx={{ mr: 1 }} onClick={() => { this.setState({addId: evt.id, addOpen: true}); }}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton edge="end" onClick={() => { this.setState({delId: evt.id, delOpen: true}); }}>
                                                            <Delete />
                                                        </IconButton>
                                                    </React.Fragment>
                                                )}
                                                disablePadding
                                            >
                                                <ListItemButton /*onClick={() => { this.setState({showEvt: evt}); }}*/ component={NavLink} to={'/events/calendar/event/' + evt.id} dense>
                                                    <ListItemText
                                                        primary={evt.title}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography variant="body2" component="span" sx={{ display: 'block' }} color="text.secondary">{Util.isoDateStringToString(evt.begin_date, true) + (evt.end_date ? (" - " + Util.isoDateStringToString(evt.end_date, true)) : "")}</Typography>
                                                                {evt.location && (<Typography variant="body2" component="span" sx={{ display: 'block' }} color="text.secondary">{evt.location}</Typography>)}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider component="li" />
                                        </React.Fragment>
                                    );
                                })}
                            </List>

                            <Pagination
                                count={data && data.total ? Math.ceil(data.total / rowsPerPage) : 0}
                                page={page}
                                onChange={(e : React.ChangeEvent<unknown>, page: number) => { this.setState({page: page}, () => this.searchEvents(false)); }}
                                sx={{ display: 'flex', justifyContent: 'center' }}
                            />
                        </React.Fragment>
                    ) : (!this.context.loading && (
                            <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, textAlign: 'center' }}>
                                Keine Termine mit dem aktuellen Suchfilter gefunden
                            </Typography>
                        )
                    )}

                </Container>

                {Role.isEventEditor() && (
                    <Fab color="secondary" onClick={() => { this.setState({addOpen: true, addId: undefined}) }} sx={{
                        position: 'fixed',
                        bottom: 30,
                        right: 30,
                        }}>
                        <Add />
                    </Fab>
                )}

                <CalendarEventEditDialog 
                    addOpen={addOpen} 
                    addId={addId}
                    addBeginDate={moment(moment.now()).clone().startOf('day')} 
                    onClose={(id: any) => { this.setState({addOpen: false}, () => { if (id) { this.props.navigate('/events/calendar/event/' + id); } }); }} 
                />

                <CalendarEventDelDialog 
                    delOpen={delOpen} 
                    delId={delId}
                    onClose={(success: boolean) => { this.setState({delOpen: false}, () => { if (success) { this.searchEvents(true); } }); }} 
                />

                {/*
                <Dialog
                    open={showEvt ? true : false}
                    onClose={() => { this.setState({showEvt: undefined}); }}
                    TransitionComponent={DialogTransition}
                    fullWidth={true}
                    maxWidth='md'
                >
                    <DialogContent>

                        <Grid container spacing={1} sx={{ mb: 3 }}>

                            <Grid item sm={3} xs={12}>
                                <Typography variant="h5" color="textSecondary">Titel:</Typography>
                            </Grid>

                            <Grid item sm={9} xs={12}>
                                <Typography variant="h5" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{showEvt && showEvt.title}</Typography>
                            </Grid>

                            {showEvt && showEvt.location && (
                                <React.Fragment>
                                    <Grid item sm={3} xs={12}>
                                        <Typography variant="subtitle1" color="textSecondary">Ort:</Typography>
                                    </Grid>

                                    <Grid item sm={9} xs={12}>
                                        <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{showEvt && showEvt.location}</Typography>
                                    </Grid>
                                </React.Fragment>
                            )}
                            
                            {showEvt && showEvt.begin_date && (
                                <React.Fragment>
                                    <Grid item sm={3} xs={12}>
                                        <Typography variant="subtitle1" color="textSecondary">Beginn:</Typography>
                                    </Grid>

                                    <Grid item sm={9} xs={12}>
                                        <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(showEvt.begin_date, true)}</Typography>
                                    </Grid>
                                </React.Fragment>
                            )}

                            {showEvt && showEvt.end_date && (
                                <React.Fragment>
                                    <Grid item sm={3} xs={12}>
                                        <Typography variant="subtitle1" color="textSecondary">Ende:</Typography>
                                    </Grid>

                                    <Grid item sm={9} xs={12}>
                                        <Typography variant="subtitle1" color="textSecondary" sx={{ fontWeight: 'bold', ml: { xs: 5, sm: 0 } }}>{Util.isoDateStringToString(showEvt.end_date, true)}</Typography>
                                    </Grid>
                                </React.Fragment>
                            )}

                            {showEvt && showEvt.begin_date && presenceTypes && (presenceTypes.length > 0) && (
                                <React.Fragment>
                                    <Grid item sm={3} xs={12}>
                                        <Typography variant="subtitle1" color="textSecondary">Anwesend:</Typography>
                                    </Grid>

                                    <Grid item sm={9} xs={12}>

                                        <Box sx={{ ml: { xs: 5, sm: 0 } }}>

                                            <Grid container spacing={2}>
                                                {presenceTypes.map((pt: any, i2: number) => {
                                                    return (
                                                        <Grid item sm={4} xs={12} key={i2}>
                                                            <Button 
                                                                startIcon={(pt.id == 1) ? <Check /> : ((pt.id == 2) ? <QuestionMark /> : ((pt.id == 3) ? <Close /> : null))} 
                                                                color={(pt.id && showEvt.event_presences && (showEvt.event_presences.length > 0) && showEvt.event_presences[0].dom_presence_type && (showEvt.event_presences[0].dom_presence_type.id == pt.id)) ? "primary" :"secondary"} 
                                                                variant={(pt.id && showEvt.event_presences && (showEvt.event_presences.length > 0) && showEvt.event_presences[0].dom_presence_type && (showEvt.event_presences[0].dom_presence_type.id == pt.id)) ? "contained" : "outlined"}
                                                                fullWidth
                                                                onClick={() => { this.onPresenceTypeClicked(pt.id); }}
                                                                disabled={Util.isoStringToDate(showEvt.begin_date)?.isBefore() ? true : false}
                                                            >
                                                                {pt.name}
                                                            </Button>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Box>

                                    </Grid>
                                </React.Fragment>
                            )}

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({showEvt: undefined}); }}>Schließen</Button>
                    </DialogActions>
                </Dialog>
                */}

            </Container>
        );
    }

}

export default withHocs(CalendarEvents);