import React from 'react';
import { NavLink } from 'react-router-dom';
import { debounce } from 'lodash';
import { NumericFormat } from 'react-number-format';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/icons-material/Menu';

import { AppContext } from '../../context';
import Role from '../../components/role';
import { withHocs, WithHocs } from '../../components/hocs';


interface TRow {
  id: number,
  archivNr?: number,
  title: string,
  composer?: string,
  arrangement?: string,
  publisher?: string,
  cat?: string,
  orchSize?: string,
}


interface Props {
  children?: React.ReactNode,
  choose?: boolean,
  choosenTracks?: any[],
  chooseFunction?: Function,
}

interface State {
  config: any,

  title: string,
  searchTitle: any[],
  archivNr: string,
  composer: string,
  searchComposer: any[],
  arrangement: string,
  searchArrangement: any[],
  publisher: string,
  searchPublisher: any[],
  comment: string,
  akmId: string,
  //length: string,

  //from: string  | undefined | null,
  //to: string  | undefined | null,
  
  folder: string,
  dir: string,
  folderNr: string,

  cat: number[],
  orchSize: number[],

  tableData: any,
  page: number,
  rowsPerPage: number,

  panelExpanded: boolean,
}


class TrackOverview extends React.Component<WithHocs & Props, State> {
  static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

  state = { 
    config: undefined as any,

    title: "",
    searchTitle: [],
    archivNr: "",
    composer: "",
    searchComposer: [],
    arrangement: "",
    searchArrangement: [],
    publisher: "",
    searchPublisher: [],
    comment: "",
    akmId: "",
    //length: string,

    //from: null,
    //to: null,
    
    folder: "",
    dir: "",
    folderNr: "",

    cat: [] as number[],
    orchSize: [] as number[],

    tableData: undefined as any,
    page: 0,
    rowsPerPage: 25,

    panelExpanded: false,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() { 
    this.loadTrackConfigs();
  }

  loadTrackConfigs = () => {
    this.context.setLoading(true);

    this.props.rest.post('track/getConfig').then(data => {

      this.context.setLoading(false);
      if (data && data.status && data.status == "ok") {
        this.setState({config: data}, () => {
          this.searchTracks(true);
        });
      } else {
        this.props.enqueueSnackbar("Konfiguration konnte nicht geladen werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      this.props.enqueueSnackbar("Konfiguration konnte nicht geladen werden", { variant: 'error' });
      console.log(err);
    });
  }

  searchTracks = (fresh: boolean, e?: React.FormEvent) => {
    e?.preventDefault();

    const { rowsPerPage, title, archivNr, composer, arrangement, publisher, comment, akmId, folder, dir, folderNr, cat, orchSize, panelExpanded } = this.state;

    let page: number = (fresh ? 0 : this.state.page);
    if (fresh) {
      this.setState({tableData: undefined, page: page});
    }

    this.context.setLoading(true);
    let trackData: any = { page: (page + 1), rowsPerPage: rowsPerPage, title: title, register_number: archivNr, composer: composer, arrangement: arrangement, publisher: publisher, comment: comment, akm_id: akmId, dir: dir, folder: folder,  folder_position: folderNr, cat: cat, orchSize: orchSize };

    this.props.rest.post("track/search", trackData).then(data => {

      this.context.setLoading(false);

      if (data && data.tracks && data.status && data.status == "ok") {

        this.setState({tableData: data.tracks, panelExpanded: ((data.tracks && data.tracks.data && data.tracks.data.length > 0) ? false : panelExpanded)});
        console.log(data);

      } else {
        this.props.enqueueSnackbar("Stücke konnten nicht gesucht werden", { variant: 'error' });
      }

    }).catch(err => {
      this.context.setLoading(false);
      console.log(err);
      this.props.enqueueSnackbar("Stücke konnten nicht gesucht werden", { variant: 'error' });
    });

  }

  async search(uri: string, data: any) {
    return this.props.rest.post(uri, data);
  }

  concatDomCboIdToNames = (domEntries: any[], selItems: number[], tPrefix?: string): string => {
    const { t } = this.props;
    
    let names: string = "";
    let iNames: number = 0;
    domEntries.forEach((domEntry: any, i: number) => {
      if (selItems.indexOf(domEntry.id) > -1) {
        names = names + (tPrefix ? t(tPrefix + '.' + domEntry.name) : domEntry.name);
        if (iNames < (selItems.length - 1)) {
          names = names + ", ";
        }
        iNames++;
      }
    });
    return names;
  }

  concatDomCboNames = (domEntries: any[], tPrefix?: string): JSX.Element => {
    const { t } = this.props;

    return (
      <React.Fragment>
        {domEntries.map((domEntry: any, i: number) => {
          return (
            <React.Fragment key={i}>
              {tPrefix ? t(tPrefix + '.' + domEntry.name) : domEntry.name}
              {(i < (domEntries.length - 1)) ? (<>{", "}<br /></>) : (<></>)}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  isChecked = (id: number): boolean => {
    let res: boolean = false;
    const { choosenTracks } = this.props;

    if (choosenTracks) {
      choosenTracks.forEach((ct: any, i: number) => {
        if (ct && ct.id && (ct.id == id)) {
          res = true;
        }
      });
    }
    return res;
  }

  toggleChecked = (id: number, title: string) => {
    let choosenTracks = this.props.choosenTracks || [];
    const { chooseFunction } = this.props;

    if (this.isChecked(id)) {
      let checkedIndex: number = 0;
      choosenTracks.forEach((ct: any, i: number) => {
        if (ct && ct.id && (ct.id == id)) {
          checkedIndex = i;
        }
      });
      choosenTracks.splice(checkedIndex, 1);
    } else {
      choosenTracks.push({id: id, title: title});
    }
    if (chooseFunction) {
      chooseFunction(choosenTracks);
    }
  }

  searchTitle = debounce(async () => { 
    const { title } = this.state;
    if (title) {
      this.setState({searchTitle: 
        await this.search('track/searchTitle', {title: title})
      });
    }
  }, 450);

  searchComposer = debounce(async () => { 
    const { composer } = this.state;
    if (composer) {
      this.setState({searchComposer: 
        await this.search('track/searchComposer', {composer: composer})
      });
    }
  }, 450);

  searchArrangement = debounce(async () => { 
    const { arrangement } = this.state;
    if (arrangement) {
      this.setState({searchArrangement: 
        await this.search('track/searchArrangement', {arrangement: arrangement})
      });
    }
  }, 450);

  searchPublisher = debounce(async () => { 
    const { publisher } = this.state;
    if (publisher) {
      this.setState({searchPublisher: 
        await this.search('track/searchPublisher', {publisher: publisher})
      });
    }
  }, 450);

  render() {
    const { t } = this.props;
    const { config, title, searchTitle, archivNr, composer, searchComposer, arrangement, searchArrangement, publisher, searchPublisher, comment, akmId, folder, dir, folderNr, cat, orchSize, tableData, page, rowsPerPage, panelExpanded } = this.state;
    const { choose, choosenTracks, chooseFunction } = this.props;

    return (
        <Container maxWidth="lg" sx={{ pb: 1 }}>

          <Typography variant="h4" color="textSecondary" marginY={3}>Stücke</Typography>

          <form action="" method="POST" onSubmit={e => { this.searchTracks(true, e); }}>

            

            <Accordion expanded={panelExpanded} disableGutters>
              <AccordionSummary
                expandIcon={null}
                onClick={e => { e.preventDefault(); }}
              >

                <Paper
                  sx={{ p: 0, display: 'flex', alignItems: 'center', width: '100%' }}
                >
                  <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={() => { this.setState({panelExpanded: !panelExpanded}); }}>
                    <Menu />
                  </IconButton>

                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={searchTitle.map((option: any) => option.title)}
                    autoComplete
                    inputValue={title}
                    onInputChange={(event, value) => { 
                      this.setState({title: value, searchTitle: []}, this.searchTitle); 
                    }}
                    fullWidth
                    renderInput={(params) => {
                      const {InputLabelProps,InputProps,...rest} = params;
                      return (
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Titel suchen"
                          {...params.InputProps} {...rest}
                        />
                      );
                    }}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton color="primary" sx={{ p: '10px' }} aria-label="search" onClick={() => { this.searchTracks(true); }}>
                    <Search />
                  </IconButton>
                </Paper>

              </AccordionSummary>
              <AccordionDetails>
              
                

                  <Grid container spacing={2}>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={searchTitle.map((option: any) => option.title)}
                        autoComplete
                        inputValue={title}
                        onInputChange={(event, value) => { 
                          this.setState({title: value, searchTitle: []}, this.searchTitle); 
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField label="Titel" {...params} />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField label="Archiv-Nr." fullWidth value={archivNr} onChange={e => { this.setState({archivNr: e.target.value}); }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                          freeSolo
                          disableClearable
                          options={searchComposer.map((option: any) => option.composer)}
                          autoComplete
                          inputValue={composer}
                          onInputChange={(event, value) => { 
                            this.setState({composer: value, searchComposer: []}, this.searchComposer); 
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField label="Komponist" {...params} />
                          )}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={searchArrangement.map((option: any) => option.arrangement)}
                        autoComplete
                        inputValue={arrangement}
                        onInputChange={(event, value) => { 
                          this.setState({arrangement: value, searchArrangement: []}, this.searchArrangement); 
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField label="Arrangement" {...params} />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={searchPublisher.map((option: any) => option.publisher)}
                        autoComplete
                        inputValue={publisher}
                        onInputChange={(event, value) => { 
                          this.setState({publisher: value, searchPublisher: []}, this.searchPublisher); 
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField label="Verlag" {...params} />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField label="Anmerkung" fullWidth value={comment} onChange={e => { this.setState({comment: e.target.value}); }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <NumericFormat
                        fullWidth
                        label="AKM-ID"
                        value={akmId}
                        customInput={TextField}
                        onValueChange={value => { this.setState({akmId: value.value}); }}
                        thousandSeparator={false}
                        allowNegative={false}
                        decimalScale={0}
                        decimalSeparator=","
                      />
                    </Grid>

                    {/*<Grid item md={6} xs={12}>
                      <NumericFormat
                        fullWidth
                        label="Stücklänge"
                        value={length}
                        customInput={TextField}
                        format="##:##:##"
                        onValueChange={value => { this.setState({length: value.formattedValue}); }}
                      />
                    </Grid>*/}

                    {/*<Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Von-Digitalisierungs auswählen"
                          inputFormat="DD.MM.YYYY HH:mm"
                          mask="__.__.____ __:__"
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          value={from}
                          onChange={(date, keyboardInputValue) => { this.setState({from: date}); }}
                          ampm={false}
                          clearable
                          //error={this.state.fromMsg ? true : false}
                          //helperText={this.state.fromMsg}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Bis-Digitalisierungs auswählen"
                          inputFormat="DD.MM.YYYY HH:mm"
                          mask="__.__.____ __:__"
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          value={to}
                          onChange={(date, keyboardInputValue) => { this.setState({to: date}); }}
                          ampm={false}
                          clearable
                          //error={this.state.toMsg ? true : false}
                          //helperText={this.state.toMsg}
                        />
                      </LocalizationProvider>
                    </Grid>*/}

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Inhaltsverzeichnis</InputLabel>
                        <Select value={dir} onChange={e => { this.setState({dir: e.target.value}); }} >
                          <MenuItem value="">&nbsp;</MenuItem>
                          {config && config.directories && config.directories.map((idir: any, i: number) => {
                            return (<MenuItem value={idir.id} key={idir.id}>{idir.name}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Mappe</InputLabel>
                        <Select value={folder} onChange={e => { this.setState({folder: e.target.value}); }} >
                          <MenuItem value="">&nbsp;</MenuItem>
                          {config && config.folders && config.folders.map((ifolder: any, i: number) => {
                            return (<MenuItem value={ifolder.id} key={ifolder.id}>{ifolder.name}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <NumericFormat
                        fullWidth
                        label="Mappen-Nr."
                        value={folderNr}
                        customInput={TextField}
                        onValueChange={(value) => { this.setState({folderNr: value.value}); }}
                        thousandSeparator={false}
                        allowNegative={false}
                        decimalScale={0}
                        decimalSeparator=","
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Kategorie</InputLabel>
                        <Select 
                          multiple 
                          value={cat} 
                          onChange={e => { this.setState({cat: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                          renderValue={(selected) => this.concatDomCboIdToNames(config.categories, selected, 'category')} 
                          >
                          {config && config.categories && config.categories.map((icat: any, i: number) => {
                            return (
                              <MenuItem value={icat.id} key={icat.id}>
                                <Checkbox checked={cat.indexOf(icat.id) > -1} />
                                <ListItemText primary={t('category.' + icat.name)} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Orchestergröße</InputLabel>
                        <Select 
                          multiple 
                          value={orchSize} 
                          onChange={e => { this.setState({orchSize: (typeof e.target.value === 'string' ? e.target.value.split(', ').map(valStr => { return parseInt(valStr) }) : e.target.value)}); }} 
                          renderValue={(selected) => this.concatDomCboIdToNames(config.orchestraSizes, selected, 'orchestra_size')} 
                          >
                          {config && config.orchestraSizes && config.orchestraSizes.map((iorchSize: any, i: number) => {
                            return (
                              <MenuItem value={iorchSize.id} key={iorchSize.id}>
                                <Checkbox checked={orchSize.indexOf(iorchSize.id) > -1} />
                                <ListItemText primary={t('orchestra_size.' + iorchSize.name)} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} textAlign="center">
                      <Button type="submit" variant="contained" startIcon={<Search />} size="large">Suchen</Button>
                    </Grid>

                  </Grid>

                

              </AccordionDetails>
            </Accordion>

          </form>


          
          {tableData && tableData.data && tableData.data.length > 0 ? (

            <Paper sx={{
              width: '100%', 
              overflow: 'hidden',
              mt: 5,
              mb: 5,
            }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                        {choose && (<TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>&nbsp;</TableCell>)}
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Titel</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Archiv-Nr.</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Komponist</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Arrangement</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Verlag</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Kategorie</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold', zIndex: 0 }}>Orchestergröße</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                      {tableData && tableData.data && tableData.data.map((row: any, i: number) => {
                        return (
                          <TableRow hover tabIndex={-1} key={i} 
                            onClick={() => { 
                              if (choose) {
                                this.toggleChecked(row.id, row.title);
                              } else {
                                this.props.navigate("/archive/tracks/" + row.id);
                              } 
                            }} 
                             sx={{ textDecoration: 'none', cursor: 'pointer' }}
                          >
                            {choose && (
                              <TableCell align="center">
                                <Checkbox 
                                    checked={this.isChecked(row.id)}
                                    //onChange={evt => { this.setState({keep: evt.target.checked}) }}
                                    color="primary"
                                />
                              </TableCell>
                            )}
                            <TableCell align="center">{row.title}</TableCell>
                            <TableCell align="center">{row.track_unions && row.track_unions.length > 0 && row.track_unions[0].register_number}</TableCell>
                            <TableCell align="center">{row.composer}</TableCell>
                            <TableCell align="center">{row.arrangement}</TableCell>
                            <TableCell align="center">{row.publisher}</TableCell>
                            <TableCell align="center">{this.concatDomCboNames(row.dom_categories, 'category')}</TableCell>
                            <TableCell align="center">{this.concatDomCboNames(row.dom_orchestra_sizes, 'orchestra_size')}</TableCell>
                          </TableRow>
                        );
                      })}

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tableData && tableData.total ? tableData.total : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event: React.MouseEvent | null, page: number) => { this.setState({page: page}, () => this.searchTracks(false)); }}
                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => { if (event?.target?.value) { this.setState({rowsPerPage: parseInt(event.target.value)}, () => this.searchTracks(false)); } }}
              />
            </Paper>
          ) : (!this.context.loading && (
                <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 3, textAlign: 'center' }}>
                    Keine Stücke mit dem aktuellen Suchfilter gefunden
                </Typography>
              )
          )}









          {!choose && Role.isTrackEditor() && (
            <Fab color="secondary" component={NavLink} to="/archive/tracks/new" sx={{
              position: 'fixed',
              bottom: 30,
              right: 30,
            }}>
              <Add />
            </Fab>
          )}
        
        </Container>
    );
  }

}

export default withHocs(TrackOverview);