import React from 'react';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Close from '@mui/icons-material/Close';

import { AppContext } from '../../context';
import Role from '../../components/role';
import DialogTransition from '../../components/transition';
import { withHocs, WithHocs } from '../../components/hocs';
import Util from '../../components/util';


interface Props {
    delId?: any,
    delOpen: boolean,
    onClose: (success: boolean) => void,
}

interface State {
    delEvt: any,
}


class CalendarEventDelDialog extends React.Component<WithHocs & Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state = {
        delEvt: undefined as any,
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { 
        if (this.props.delOpen) {
            this.searchEvent();
        }
    }

    componentDidUpdate(prevProps: Props & WithHocs) {
        if (this.props && this.props.delOpen && ((prevProps && (this.props.delOpen !== prevProps.delOpen)) || !(prevProps && prevProps.delOpen))) {
            
            this.searchEvent();
        }
    }

    searchEvent = () => {
        const { delId, delOpen } = this.props;

        this.setState({ delEvt: undefined });

        if (delOpen && delId) {
        
            this.context.setLoading(true);
            let eventData: any = { id: delId };
        
            this.props.rest.post("calendar/get", eventData).then(data => {
        
            this.context.setLoading(false);
        
            if (data && data.event && data.status && data.status == "ok") {
        
                this.setState({delEvt: data.event});
                console.log(data);
        
            } else {
                this.props.enqueueSnackbar("Termin konnten nicht gesucht werden", { variant: 'error' });
            }
        
            }).catch(err => {
                this.context.setLoading(false);
                console.log(err);
                this.props.enqueueSnackbar("Termin konnten nicht gesucht werden", { variant: 'error' });
            });

        }
    }

    onDelete = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { delEvt } = this.state;
        if (delEvt && delEvt.id) {
            let title: string | undefined = delEvt.title;
            this.context.setLoading(true);
        
            let delData: any = { id: delEvt.id };
        
            this.props.rest.post('calendar/del', delData).then(data => {
        
                this.context.setLoading(false);
        
                if (data && data.status && (data.status == 'ok')) {
                    this.props.enqueueSnackbar('Termin "' + title + '" erfolgreich gelöscht', { variant: 'success' });
                    this.props.onClose(true);
                } else {
                    this.props.enqueueSnackbar('Termin konnte nicht gelöscht werden', { variant: 'error' });
                }
        
            }).catch(err => {
                this.context.setLoading(false);
                this.props.enqueueSnackbar('Termin konnte nicht gelöscht werden', { variant: 'error' });
                console.log(err);
            });
    
        }
    }

    render() {
        const { delId, delOpen } = this.props;
        const { delEvt } = this.state;

        return (

            <Dialog
                open={delOpen}
                onClose={() => { this.props.onClose(false); }}
                TransitionComponent={DialogTransition}
                fullWidth={true}
                maxWidth='sm'
            >
                <form onSubmit={this.onDelete}>
                    <DialogTitle>Termin löschen</DialogTitle>
                    <DialogContent>
                        {delEvt && (<Typography variant="subtitle1">Termin "{delEvt.title}" wirklich löschen?</Typography>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.props.onClose(false); }}>Abbrechen</Button>
                        <Button type="submit">Löschen</Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }

}

export default withHocs(CalendarEventDelDialog);