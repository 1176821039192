import React from 'react';

import List from '@mui/material/List';

import {useDroppable} from '@dnd-kit/core';

export default function DropList(props: any) {

  const { setNodeRef } = useDroppable({id: props.id});
  
  return (
    <List ref={setNodeRef}>
      {props.children}
    </List>
  );
}