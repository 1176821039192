import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsDE from './de.json';

const resources = {
  de: {
    translation: translationsDE
  }
};

const detectionOptions = {
  order: ['querystring', 'navigator', 'htmlTag']
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    detection: detectionOptions,
    fallbackLng: 'de', // default language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  
  export default i18n;